import React from 'react';
import Navbar from '../partials/navbar';
import Footer from '../partials/footer';
import { Link } from "react-router-dom";
import { useEffect,useState } from 'react';
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import logo from '../images/logo.png'
import { Form } from 'react-bootstrap';
const Registerevent = () => {
  const contactform={
    margin:'0 auto',
    width:'60%'
  }
  const [fullname, setFullname] = useState('');
  const [phonenumber, setPhonenumber] = useState('');
  const [profession, setProfession] = useState('');
  const [message, setmessage] = useState('');
  const [email, setEmail] = useState('');
  const [Events, setEvents] = useState('');
  const [userName, setuserName] = useState([]);
  const [eventregisterfor, seteventregisterfor] = useState('');
  const [eventid, seteventid] = useState('');
  const [privacyChecked, setprivacyChecked] = useState(false);
  const [apiResponse, setapiResponse] = useState([]);
  const [address, setAddress] = useState('');
  const [membershiptype, setMembershiptype] = useState('');
  const [selectionMade, setSelectionMade] = useState(false);
  const { username } = useParams();
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  // var handleChange = (event) =>{
  //   setusername(event.target.value);
  //   setfullname(event.target.value);
  //   setphonenumber(event.target.value);
  //   setemail(event.target.value);
  //   setpassword(event.target.value);
  //   setconfirmpassword(event.target.value);
  // }
  var authToken = localStorage.getItem("authToken");
  
  
  useEffect(() => {
    if (authToken == null) {
      // navigate('/login')
    } else {
      const fetchUserData = async () => {
        try {
    
          const profileResponse = await axios.post(`${backendUrl}/memberprofiledetails`, { userid: userName }, {
            headers: {
              'Content-Type': 'application/json',
            },
          });
    
          const userData = profileResponse.data.result[0];
          setFullname(userData.fullname);
          setPhonenumber(userData.phonenumber);
          setAddress(userData.address);
          setEmail(userData.email);
          setMembershiptype(userData.membershiptype);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      const fetchUsername = async () => {
        const jsonData = {
          username: username,
        };
        try {
          const response = await axios.post(
            `${backendUrl}/fetchusername`,
            jsonData,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          ); // Replace with your API endpoint
          const data = response.data;
          console.log(data.username);
          setuserName(data.username);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchUsername();
      fetchUserData();
    }
    setInterval(() => {
      axios.get(`${backendUrl}/getevents`)
      .then(response => {
        if (Array.isArray(response.data) && response.data.length > 0) {
          setEvents(response.data);
        } else {
          setEvents([]); // Set events to an empty array when no events are returned
        }
      })
      .catch(error => {
        console.error('Error fetching events:', error);
      // Set loading state to false in case of error
      });
    }, 1000);
  }, [userName]);
  const handlePrivacyCheck = (event) => {
    setprivacyChecked(event.target.checked);
  };
  // var formData=()
  const handleEventRegistersubmit = (event)=>{
    event.preventDefault();
   
    if (!selectionMade){
      if(eventregisterfor ==='No Events Yet'){
        setapiResponse("Select an Event")
      }
      else{
        setapiResponse("No Event To Select Check Back Later")

      }
    }
    else{
      if(eventregisterfor ==='No Events Yet'){
        setapiResponse("No Event To Select Check Back Later")
      }
      else{
        // alert(membershiptype)
        const jsonData = JSON.stringify({
          fullname:fullname,
          phonenumber:phonenumber,
          email: email,
          username:userName,
          membershiptype:membershiptype,
          eventregisterfor:eventregisterfor,
          profession:profession,
          address:address,
        });
        axios.post(`${backendUrl}/registerevent`,jsonData,{
          headers: {
            'Content-Type': 'application/json',
          },
        } )
        .then((response) => {
          // Handle success
          setapiResponse(response.data)
          if(response.data === 'You Just Registered for '+eventregisterfor){
            const message='You Just Registered for '+ eventregisterfor
            navigate(`/success/${message}`)
          }
          else{
            // navigate('/signup')
          }
        })
        .catch((error) => {
          // Handle error
          console.error('Form submission error:', error);
        });
      }
    }
    // Submit the form data using Axios
  }
  return (
    <div className="">
      <header>
        <Navbar />
      </header>
      <div className="container w-100 mt-5">
        <form className='contactForm' style={contactform}>
          <div className='text-center'>
            <img className='mt-4 mb-5' src={logo} alt="Logo" width='200' />
            <h2 className='text-left' style={{ fontWeight: 'bolder', textAlign: 'center' }}>Register For An Event</h2>
          </div>
          <br />
          <p style={{ color: apiResponse !== 'You are Logged In' ? 'red' : '' }}>
          {apiResponse}
        </p>
          <div className="row mb-3">
            <div className="col">
              <input
                type="text"
                className="form-control border-top-0 border-start-0 border-end-0 rounded-0"
                style={{ borderColor: 'black' }}
                id="name"
                placeholder="e.g Adaobi Mensah"
                value={fullname?fullname:fullname}
                onChange={(e) => setFullname(e.target.value)}
              />
              <label htmlFor="name" className="form-label">Name</label>
            </div>
            <div className="col">
              <input
                type="text"
                className="form-control border-top-0 border-start-0 border-end-0 rounded-0"
                style={{ borderColor: 'black' }}
                id="profession"
                placeholder="e.g Your Company Inc"
                value={profession}
                onChange={(e) => setProfession(e.target.value)}
              />
              <label htmlFor="profession" className="form-label">Occupation</label>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <input
                type="text"
                className="form-control border-top-0 border-start-0 border-end-0 rounded-0"
                style={{ borderColor: 'black' }}
                id="phonenumber"
                placeholder="e.g +44 903389403"
                value={phonenumber?phonenumber:phonenumber}
                onChange={(e) => setPhonenumber(e.target.value)}
              />
              <label htmlFor="phonenumber" className="form-label">Phone Number</label>
            </div>
            <div className="col">
              <input
                type="email"
                className="form-control border-top-0 border-start-0 border-end-0 rounded-0"
                style={{ borderColor: 'black' }}
                id="email"
                placeholder="e.g email@company.com"
                value={email?email:email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <label htmlFor="email" className="form-label">Your work email</label>
            </div>
          </div>
          <div className="col">
            <input
              type="text"
              className="form-control border-top-0 border-start-0 border-end-0 rounded-0"
              style={{ borderColor: 'black' }}
              id="address"
              placeholder="e.g No 2 Cook Street London, United Kingdom"
              value={address?address:address}
              onChange={(e) => setAddress(e.target.value)}
            />
            <label htmlFor="address" className="form-label">Address</label>
          </div>
          <div className="col">
            <select
              className="form-control border-top-0 border-start-0 border-end-0 rounded-0"
              style={{ borderColor: 'black' }}
              id="event"
              value={eventregisterfor} 
              onChange={(e) => {
                seteventregisterfor(e.target.value);
                seteventid(e.target.getAttribute('data-key'));
                setSelectionMade(true);
              }}
            >
              {Events.length === 0 ?(""):(<option value={'Select an Event'} >Select an Event</option>)}
               
              {Events.length === 0 ? (
                <option value={'No Events Yet'}>No Events Yet</option>
              ) : (
                
                Events.map((event) => (
                  
                  <option value={event.eventTitle} key={event.id} data-key={event.id}>
                    {event.eventTitle}
                  </option>
                ))
              )}
            </select>
            <label htmlFor="event" className="form-label">
              Select Event
            </label>
          </div>
          <div className="col">
              <input
                type="text"
                className="form-control border-top-0 border-start-0 border-end-0 rounded-0"
                style={{ borderColor: 'black' }}
                id="membershiptype"
                placeholder="e.g Your Company Inc"
                value={membershiptype}
                onChange={(e) => setMembershiptype(e.target.value)}
              />
              <label htmlFor="membershiptype" className="form-label">Membership Type</label>
            </div>
          
          {/* <div className="mb-3">
            <textarea
              className="form-control border-top-0 border-start-0 border-end-0 rounded-0"
              style={{ borderColor: 'black' }}
              id="message"
              placeholder="What will you like to tell us?"
              value={message}
              onChange={(e) => setmessage(e.target.value)}
            />
            <label htmlFor="message" className="form-label">Your Message</label>
          </div> */}
          <button
            type="submit"
            className="btn btn-primary w-100 rounded-0"
            onClick={handleEventRegistersubmit}
            style={{ background: '#7FA037', border: 'none', borderRadius: 'none' }}
          >
            Send
          </button>
        </form>
        <br/>
      </div>
    </div>
  );
};

export default Registerevent;
