import React from 'react';
import Navbar from '../partials/navbar';
import Footer from '../partials/footer';
import { Link } from "react-router-dom";
import { Form } from 'react-bootstrap';
import mail from '../images/svg/mail.png'
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
const Mail = () => {
  const navigate = useNavigate();
  const { message } = useParams();

  const goBack = () => {
    navigate(-1);
  };

  const contactform={
    margin:'0 auto',
    width:'60%'
  }
  const workGrpContentAnchorColor = {
        textDecoration:'none',
        color:'#000',
        background:'#E8EBE3',
        paddingTop:'6px',
        paddingLeft:'15px',
        paddingRight:'15px',
        paddingBottom:'6px',
        margin:'100px'
    };
  return (
    <div className="">
      <div className="container w-100 text-center" style={{marginTop:'130px'}}>
        <img src={mail} width='120'/>
        <br/>
        <h3 className='mt-3'>{message? message:'A link to reset your account has been sent to your email address, kindly check your mail to proceed'}</h3>
        <Link style={workGrpContentAnchorColor} onClick={goBack} className='mt-5'>Go Back</Link>
      </div>  
    </div>
  );
};

export default Mail;
