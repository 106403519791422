import React from 'react';
import ReactGA from 'react-ga';
import { BrowserRouter, Route, main, Routes } from "react-router-dom";
import './style/style.css';
import 'bootstrap/dist/css/bootstrap.css'; // Import Bootstrap CSS
import 'bootstrap/dist/js/bootstrap.bundle';
import Homepage from './components/home';
import { useNavigate } from 'react-router-dom';
import Event from './components/event';
import Workgroup from './components/workgroups';
import Permission from './components/partials/permission';
import Contact from './components/partials/contact';
import NotFound from './components/partials/errorpage';
import Signup from './components/partials/signup';
import Login from './components/partials/login';
import Resetpassword from './components/partials/resetpassword';
import Forgotpassword from './components/partials/forgotpassword';
import Successpage from './components/partials/success';
import Mail from './components/partials/mail';
import Constitution from './components/partials/constitution';
import Termsofservice from './components/partials/terms';
import Privacy from './components/partials/privacy';
import Registerevent from './components/partials/registerevent';
import Membershipfee from './components/clientdashboard/membershipfee';
import Adminlogin from './components/admin/adminlogin'
import SuperAdminlogin from './components/superadmin/superadminlogin'
import SuperAdmindashboard from './components/superadmin/superadmindashboard'

import PrivateRoute from './components/privateroute';
import SuperViewallusers from './components/superadmin/viewallusers'
import SuperUploadevent from './components/superadmin/uploadevent'
import SuperDeleteevent from './components/superadmin/superadminpartials/deleteevent'
import SuperAdminviewuser from './components/superadmin/viewuser'
import SuperAdminForum from './components/superadmin/adminforum'
import SuperEdituser from './components/superadmin/edituser'
import SuperAllforummemeber from './components/superadmin/forummembers'
import SuperAllevents from './components/superadmin/allevents'
import Admindashboard from './components/admin/admindashboard'
import ClientDashbord from './components/clientdashboard/dashboard';
import Clientevent from './components/clientdashboard/clientevent'
import Userregisteredeventdetails from './components/clientdashboard/registeredusereventsdetails'
import Userprofile from './components/clientdashboard/clientprofile'
import Selectworkgroup from './components/clientdashboard/workgroups'
import Forum from './components/clientdashboard/forum'
import Viewallusers from './components/admin/viewallusers'
import Uploadevent from './components/admin/uploadevent'
import Allregisteredevents from './components/admin/registeredevents'
import Deleteevent from './components/admin/adminpartials/deleteevent'
import Adminviewuser from './components/admin/viewuser'
import Sendmessage from './components/admin/sendmessage'
import Sendnotice from './components/admin/sendnotice'
import Sendmessagetoadmin from './components/clientdashboard/sendadminmessage'
import Notifications from './components/clientdashboard/notifications'
import AdminForum from './components/admin/adminforum'
import Edituser from './components/admin/edituser'
import Allforummemeber from './components/admin/forummembers'
import Allevents from './components/admin/allevents'
import { useHistory } from 'react-router-dom';
import Footer from './components/partials/footer';
import { useEffect,useState } from 'react';

function App() {
  useEffect(() => {
    // Initialize Google Analytics with your Tracking ID
    ReactGA.initialize('G-F2B3YZN1TN');
  }, []);
  return (
    <BrowserRouter>
    <main>
      <Routes>
        <Route path="/" element={<Homepage
            />} exact />
        <Route path="*" element={<NotFound />} />
        <Route
          path="event"
          element={
            <Event
          
            />
          }
        />
        <Route
          path="workgroup"
          element={
            <Workgroup
          
            />
          }
        />
        <Route
          path="contact"
          element={
            <Contact
          
            />
          }
        />
        <Route
          path="constitution"
          element={
            <Constitution
          
            />
          }
        />
        <Route
          path="/termsofservice"
          element={
            <Termsofservice
          
            />
          }
        />
        <Route
          path="/privacy"
          element={
            <Privacy
          
            />
          }
        />
        <Route
          path="/registerevent/:username/"
          element={
            <Registerevent
          
            />
          }
        />
        <Route
          path="/registerevent/"
          element={
            <Registerevent
          
            />
          }
        />
        <Route
          path="membershipfee"
          element={
            <Membershipfee
          
            />
          }
        />
        <Route
          path="/success/:message"
          element={
            <Successpage
          
            />
          }
        />
        <Route
          path="/mail/:message"
          element={
            <Mail
          
            />
          }
        />
        <Route
          path="permissions"
          element={
            <Permission
          
            />
          }
        />
        <Route
          path="signup"
          element={
            <Signup
          
            />
          }
        />
        <Route
          path="login"
          element={
            <Login
          
            />
          }
        />
        <Route
          path="/resetpassword/:useremail"
          element={
            <Resetpassword
          
            />
          }
        />
        <Route
          path="/forgotpassword"
          element={
            <Forgotpassword
          
            />
          }
        />
        <Route
          path="/adminlogin"
          element={
            <Adminlogin
          
            />
          }
        />
        <Route
          path="/admindashboard/:username"
          element={
            <Admindashboard
          
            />
          }
        />
        <Route
          path="/admindashboard/:username/:workgroup/forummember"
          element={
            <Allforummemeber
          
            />
          }
        />
        <Route
          path="/dashboard/:username"
          element={
            <ClientDashbord
          
            />
          }
        />
        
        <Route
          path="/dashboard/:username/event"
          element={
            <Clientevent
          
            />
          }
        />
        <Route
          path="/dashboard/:username/eventdetails/:eventTitle"
          element={
            <Userregisteredeventdetails
          
            />
          }
        />
        <Route
          path="/dashboard/:username/workgroups"
          element={
            <Selectworkgroup
          
            />
          }
        />
        <Route
          path="/dashboard/forum/:username/:workgroup"
          element={
            <Forum
          
            />
          }
        />
        
        <Route
          path="/dashboard/:username/profile"
          element={
            <Userprofile
          
            />
          }
        />
        <Route
          path="/admindashboard/:username/uploadevent"
          element={
            <Uploadevent
          
            />
          }
        />
        <Route
          path="/admindashboard/:username/registeredevents"
          element={
            <Allregisteredevents
          
            />
          }
        />
        <Route
          path="/superadmindashboard/:username/registeredevents"
          element={
            <Allregisteredevents
          
            />
          }
        />
        <Route
          path="/admindashboard/forum/:username/:workgroup"
          element={
            <AdminForum
          
            />
          }
        />
        <Route
          path="/admindashboard/:username/:id/delete"
          element={
            <Deleteevent
          
            />
          }
        />
        <Route
          path="/admindashboard/:username/viewuser/:userid/:userdetails"
          element={
            <Adminviewuser
          
            />
          }
        />
        <Route
          path="/admindashboard/:username/sendmessage/:userid/"
          element={
            <Sendmessage
          
            />
          }
        />
        <Route
          path="/admindashboard/:username/sendnotice/"
          element={
            <Sendnotice
          
            />
          }
        />
        <Route
          path="/dashboard/:username/sendmessagetoadmin/"
          element={
            <Sendmessagetoadmin
          
            />
          }
        />
        <Route
          path="/dashboard/:username/notifications"
          element={
            <Notifications
          
            />
          }
        />
        <Route
          path="/admindashboard/:username/allevents"
          element={
            <Allevents
          
            />
          }
        />
        <Route
          path="/admindashboard/:username/edit/:userid"
          element={
            <Edituser
          
            />
          }
        />
        <Route
          path="/admindashboard/:username/viewalluser/"
          element={
            <Viewallusers
          
            />
          }
        />




        <Route
          path="/superadmindashboard/:username/:workgroup/forummember"
          element={
            <SuperAllforummemeber
          
            />
          }
        />
       
       
        <Route
          path="/superadmindashboard/forum/:username/:workgroup"
          element={
            <SuperAdminForum
          
            />
          }
        />
        <Route
          path="/superadmindashboard/:username/:id/delete"
          element={
            <SuperDeleteevent
          
            />
          }
        />
        <Route
          path="/superadmindashboard/:username/viewuser/:userid/:userdetails"
          element={
            <SuperAdminviewuser
          
            />
          }
        />
        <Route
          path="/superadmindashboard/:username/allevents"
          element={
            <SuperAllevents
          
            />
          }
        />
        <Route
          path="/superadmindashboard/:username/edit/:userid"
          element={
            <SuperEdituser
          
            />
          }
        />
         <Route
          path="/superadmindashboard/:username/uploadevent"
          element={
            <SuperUploadevent
          
            />
          }
        />
        <Route
          path="/superadmindashboard/:username/viewalluser/"
          element={
            <SuperViewallusers
          
            />
          }
        />
        <Route
          path="/superadminlogin"
          element={
            <SuperAdminlogin
          
            />
          }
        />
        <Route
          path="/superadmindashboard/:username"
          element={
            <SuperAdmindashboard
          
            />
          }
        />
      </Routes>
    </main>
  </BrowserRouter> 
  );
}

export default App;
