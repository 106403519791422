import React from "react";
import Navbar from './partials/navbar';
import Slider from './partials/sliders'
import Dash from './images/svg/Vector3.png'
import percentage from './images/svg/percentage.png'
import Asterics from './images/svg/asterics.png'
import videoPoster from './images/videoposter.png'
import arrowdown from './images/svg/arrowdown.png'
import Footer from './partials/footer';
import ReactGA from 'react-ga';
import NewsLetter from './partials/newsletter';
import { useEffect,useState } from "react";
function Homepage(props) {
  const missionright = {
    width:'60px',
    wordWrap: 'break-word',
    fontSize:'20px'
  };
  
  const Percentage = {
    float:'right',
    height:'60px'
  };
  const asterics = {
    float:'right',
    width:'100px',
    height:'100px',    
    marginTop:'0px',
    position:'relative',
    top:'80px'

  };
  
  const executives = {
    fontSize:'20px'
  };
  const videoStyle = {
    width:"100%",
    height:"50%",
    margin:'0 auto',
    marginTop:'30px'
  };
  const trainingProg = {
    color:'#fff',
    fontWeight:'400',
    fontSize:'20px'
  };
  const training = {
    fontFamily:'Space Grotesk',
    fontWeight:600,
  };
  const services={
    margin:'0 auto',
  }
  const serviceSubsection={
    margin:'0 auto',
  }
  useEffect(() => {
    // Track page view for the home page
    ReactGA.pageview(window.location.pathname);
  }, []);
  return (
    <div className="">
      <header>
        <Navbar/>
        <Slider/>
      </header>
      <div className="container mt-5">
        <div className="mission">
          <div className="text-left custom-font-class">
            <h3>
              <span className="custom-font-class-thin">Our Mission 
              {/* <img className="dash" src={Dash}
            alt="Slide 1" /> */}
             </span> 
            </h3>
            <span style={missionright}>To support the professional development of Black Psychiatrists through education, training, mentoring and networking.</span>
            {/* <img className="Percentage text-right" src={percentage} style={Percentage} /> */}
          </div>
          <br/>
          {/* <h3><span className="custom-font-class-thin mt-5">Our Goals <img className="dash" src={Dash}
              alt="Slide 1" /> </span> </h3> */}
        </div>
      </div>
      <div className="mt-5">
        <div className="ourgoals">
          <div className="container mt-5">
            {/* <h3><span className="custom-font-class-thin mt-5">Our Goals <img className="dash" src={Dash}
              alt="Slide 1" /> </span> </h3> */}
            <div className="row">
            <video style={videoStyle} poster={videoPoster}>
              {/* <source src={videoSource} type="video/mp4" /> */}
              Your browser does not support the video tag.
            </video>
            </div>
            <div className="row container mt-5">
              <div className="text-left custom-font-class mb-5">
                <h3>
                  <span className="custom-font-class-thin">Training Programs
                {/* <img className="dash" src={Dash}
                alt="Slide 1" />  */}
                </span> 
                </h3>
                <span style={trainingProg}>We provide opportunities for members to get regular updates on research, academics and other aspects of development in psychiatry and health.</span>
                {/* <img className="Percentage text-right"  src={Asterics} style={asterics} /> */}
            </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-5">
        <div className="container services" style={services}>
          <div className="text-left custom-font-class">
            <h3><span className="custom-font-class-thin">Work-Groups
            {/* <img className="dash" src={Dash}
            alt="Slide 1" /> */}
             </span>
            </h3>
          </div>
        </div>
      </div>
      <div className="container-fluid yelowBox">
        <div className="container row" style={serviceSubsection}>
          <div className="col mt-5">
            <div className="row">
              <h3 className="text-light training" style={training}>Welfare</h3>
            </div>
            <div className="row">
              <p>Welfare supports you with issues that affect your wellbeing and your ability to work or connect with others. We also provide training in mentoring and link members to mentors.</p>
            </div>
          </div>
          <div className="col mt-5">
            <div className="row">
              <h3 className="text-light training" style={training}>Research</h3>
            </div>
            <div className="row">
              <p>The team is interested in and supports research by members on a variety of topics in psychiatry and the experience of people of black heritage. </p>
            </div>
          </div>
          <div className="col mt-5">
            <div className="row">
              <h3 className="text-light training" style={training}>Academics & CASC</h3>
            </div>
            <div className="row">
              <p>The workgroups identify and supports members with their academic needs such as the CESR pathway for SAS and the CASC tutorial group for trainees.</p>
            </div>
          </div>
          <div className="col mt-5">
            <div className="row">
                <h3 className="text-light training" style={training}>Social Media</h3>
              </div>
              <div className="row">
                <p>The group manages our connection to the world by sourcing contents and promoting ABP-UK via our relevant SM handles such as X, facebook, tikttok and linkedin. </p>
              </div>
            </div>
        </div>
      </div>
      <div className="container-fluid yelowBox">
        <div className="container row" style={serviceSubsection}>
          <div className="col mt-3 mb-4">
            <div className="row">
              <h3 className="text-light training" style={training}>Culture Club</h3>
            </div>
            <div className="row">
              <p>TAn exciting place to explore the relationship between popular culture and black mental health as well as the impact of racism and racial trauma through movies, poetry and art.</p>
            </div>
          </div>
          <div className="col mt-3 mb-4">
            <div className="row">
                <h3 className="text-light training" style={training}>Editorial</h3>
              </div>
              <div className="row">
                <p>Tasked with the responsibility of sourcing and editing contents of our media spaces such as the website, newsletters and other correspondence of the association.  </p>
              </div>
            </div>
        </div>
      </div>
      <div className="mt-5">
        <div className="container services" style={services}>
          <div className="text-left custom-font-class">
            <h3><span className="custom-font-class-thin">Executive Team
            {/* <img className="dash" src={Dash}
            alt="Slide 1" /> */}
             </span>
            </h3>
          </div>
          <div className="" style={executives}>
            <p>• President- <b>Dr Chinwe Obinwa</b>
                <br/> 
                • Vice President- <b>Dr Hal Abdullahi</b>
                <br/>
                • Secretary- <b>Dr  Oluwaseun Oluwaranti</b>
                <br/>
                • Treasurer- <b>Dr Blessing Alele</b>
                <br/>
                • Welfare Secretary- <b>Dr Mosum Fapohunda</b>
                <br/>
                • Welfare Assistant Secretary- <b>Dr. Ola Orimoloye </b>
                <br/>
                • Social Secretary – <b>Dr Kemi Akanle</b>
                <br/>
                • Public Relations Officer- <b>Dr Lisa Kwentoh</b>
                <br/>
                • International Link Officer- <b>Dr Vincent Udenze</b>
                <br/>
              </p>
          </div>
        </div>
      </div>
      <NewsLetter/>
      <Footer/>
    </div>
  );
}

export default Homepage;
