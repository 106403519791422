import React, { useState ,useEffect} from 'react';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import '../../../../adminstyle/assets/vendor/bootstrap/css/bootstrap.min.css';
import '../../../../adminstyle/assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../../../../adminstyle/assets/vendor/boxicons/css/boxicons.min.css';
import '../../../../adminstyle/assets/vendor/quill/quill.snow.css';
import '../../../../adminstyle/assets/vendor/quill/quill.bubble.css';
import '../../../../adminstyle/assets/vendor/remixicon/remixicon.css';
import '../../../../adminstyle/assets/vendor/simple-datatables/style.css';
import '../../../../adminstyle/assets/css/style.css';

const Newmemberstable = (props) => {
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState(props.data);

  useEffect(() => {
    setFilteredData(props.data);
  }, [props.data]);

  const handleSearch = (value) => {
    setSearchText(value);
    if (value === '') {
      setFilteredData(props.data);
    } else {
      const filteredResults = props.data.filter((item) =>
        Object.values(item)
          .join(' ')
          .toLowerCase()
          .includes(value.toLowerCase())
      );
      setFilteredData(filteredResults);
    }
  };
  const workGrpContentAnchor = {
    textDecoration: 'none',
    color: '#fff',
    background: '#0d9e6b',
    padding: '6px 15px',
  };
  
  const workGrpContentAnchorColor = {
    textDecoration: 'none',
    color: '#000',
    background: '#E8EBE3',
    padding: '6px 15px',
    margin: '10px',
  };
  const workGrpContentAnchorColor2 = {
    textDecoration: 'none',
    color: '#fff',
    background: '#3EBE76',
    padding: '6px 15px',
    margin: '10px',
  };

  const workGrpContentAnchor2 = {
    textDecoration: 'none',
    color: '#fff',
    background: '#dc3545',
    padding: '6px 15px',
  };

  const columns = [
    {
      name: 'Fullname',
      selector: 'fullname',
      sortable: true,
    },
    {
      name: 'Email',
      selector: 'email',
      sortable: true,
    },
    {
      name: 'PhoneNumber',
      selector: 'phonenumber',
      sortable: true,
    },
    {
      name: 'Joined Date',
      selector: 'createdDate',
      sortable: true,
    },
    {
      name: 'Edit',
      cell: (row) => (
          row.approved === 1 ? (
              <span>Approved</span>
          ) : (
            <Link to={`/admindashboard/${props.username}/edit/${row.userid}`} style={workGrpContentAnchor}>
                Edit
            </Link>
          )
      ),
      ignoreRowClick: true,
      allowOverflow: false,
      button: true,
    },
    {
      name: 'View',
      cell: (row) => (
        <Link to={`/admindashboard/${props.username}/viewuser/${row.userid}/${'User Details'}`} style={workGrpContentAnchorColor} data={props.data}>
          View
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: false,
      button: true,
    },
    {
      name: 'Send Message',
      cell: (row) => (
        <Link to={`/admindashboard/${props.username}/sendmessage/${row.userid}`} style={workGrpContentAnchorColor2}>
          Send Message
        </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: 'Diactivate',
      cell: (row) => (
        row.approved === 1 ? (
          <span><Link to={`/admindashboard/${props.username}/viewuser/${row.userid}/${'User Details'}`} style={workGrpContentAnchor2} data={props.data}>Pause
        </Link></span>
        ) : (
          <Link to={`/admindashboard/${props.username}/edit/${row.userid}`} style={workGrpContentAnchor}>
            Activate
          </Link>
        )
      ),
      ignoreRowClick: true,
      allowOverflow: false,
      button: true,
    },
  ];
 
  return (
    <div className="">
      <div className="col-12">
        <div className="card recent-sales overflow-auto">

          <div className="card-body">
            <h5 className="card-title">All Users</h5>

            <input
              type="text"
              placeholder="Search..."
              value={searchText}
              onChange={(e) => handleSearch(e.target.value)}
              style={{ marginBottom: '10px' }}
            />

            <DataTable
              columns={columns}
              data={filteredData}
              pagination
              highlightOnHover
              responsive
              defaultSortField="fullname"
              noHeader
              customStyles={{
                rows: {
                  style: {
                    fontSize: '14px',
                    borderBottom: '1px solid #ddd',
                    padding: '10px 0',
                  },
                },
              }}
            />
            <Link to={`/admindashboard/${props.username}/viewalluser/`} style={workGrpContentAnchorColor}>
              View All
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newmemberstable;
