import React, { useState, useEffect } from 'react';
import '../../adminstyle/assets/vendor/bootstrap/css/bootstrap.min.css';
import '../../adminstyle/assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../../adminstyle/assets/vendor/boxicons/css/boxicons.min.css';
import '../../adminstyle/assets/vendor/quill/quill.snow.css';
import '../../adminstyle/assets/vendor/quill/quill.bubble.css';
import '../../adminstyle/assets/vendor/remixicon/remixicon.css';
import '../../adminstyle/assets/vendor/simple-datatables/style.css';
import '../../adminstyle/assets/css/style.css';
import Adminnav from './adminpartials/adminnav';
import AdminSidebar from './adminpartials/adminsidebar';
import { Link, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';

const Adminviewuser = () => {
  const { username,userid } = useParams();
  const [fullname, setFullname] = useState('');
  const [phonenumber, setPhonenumber] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [membershiptype, setMembershiptype] = useState('');
  const [membershipfee, setMembershipfee] = useState('');
  const [isapproved, setisapproved] = useState('');
  const [avatar, setavatar] = useState('');
  const [allworkgroup, setallworkgroup] = useState([]);
  const [apiResponse, setapiResponse] = useState([]);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();
  const disapproveUser = async (event) => {
    event.preventDefault()
    try {
    
      const approvemember = await axios.post(`${backendUrl}/dismemberapproval`, {
          userid: userid,
          }, 
          {
          headers: {
          'Content-Type': 'application/json',
          },
      });

      const userData = approvemember.data;
      setapiResponse(userData.message)
      console.log(userData.message)
      if(userData.message==="User Disapproved"){
          const message= 'User Disactivated Successfully'
          navigate(`/success/${message}`)
      }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    var AdminauthToken = localStorage.getItem('authTokenAdmin')
    if(AdminauthToken==null){
      navigate('/adminlogin')
    }
    const fetcfUserwork = async () => {
      const jsonData = {
        username: username
      };

      try {
        const response = await axios.post(`${backendUrl}/fetchusersworklist`, jsonData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = response.data;
        setallworkgroup(data.message);
        // console.log(data.message)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    const checkapproved = async () => {
      try {

          const checkifapproved = await axios.post(`${backendUrl}/checkapproved`, { userid: userid }, {
              headers: {
              'Content-Type': 'application/json',
              },
          });

          const userData = checkifapproved.data;
          setisapproved(Number(userData.message))
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
    const fetchUserData = async () => {
      try {

        const profileResponse = await axios.post(`${backendUrl}/memberprofiledetails`, { userid: userid }, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const userData = profileResponse.data.result[0];
        setFullname(userData.fullname);
        setPhonenumber(userData.phonenumber);
        setAddress(userData.address);
        setEmail(userData.email);
        setMembershiptype(userData.membershiptype);
        setMembershipfee(userData.membershipfee);
        setavatar(userData.avatar);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    checkapproved()
    fetchUserData();
    const interval = setInterval(() => {
      fetcfUserwork();
    }, 1000);
  }, []);

  return (
    <div>
      <Adminnav username={username} />
      <AdminSidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>User Details</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
              <li className="breadcrumb-item active">Dashboard</li>
            </ol>
          </nav>
        </div>

        <section className="section">
          <div className="row w-100">
          <form>
                <div className='row'>
                    <div className='col-3' style={{textAlign:'left',marginBottom:'20px' }}>
                      <a href={`${backendUrl}`+avatar}>
                        <img src={`${backendUrl}`+avatar } style={{ borderRadius: '50%', width: '100px', height: '100px',textAlign:'center' }} />
                      </a>
                    </div>
                    <div className='col-8' style={{textAlign:'left',marginBottom:'20px' }}>
                     
                      <div className='row'>
                        <div className='col-2'> <span className=''>Workgroups:</span></div>
                        <div className='col-10 text-center'>
                          {allworkgroup.map((element, index)=>{
                            {return (
                              <span style={{background:'#EFEFEF',borderRadius:'10px',padding:'5px'}} key={index}>{element.workgroup}</span>
                            )}
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label htmlFor="fullName" className="col-md-4 col-lg-3 col-form-label">Full Name</label>
                    <div className="col-md-8 col-lg-9">
                      <input disabled name="fullName" type="text" className="form-control" id="fullName" value={fullname} onChange={(e) => setFullname(e.target.value)} />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <label htmlFor="Job" className="col-md-4 col-lg-3 col-form-label">Membership Type</label>
                    <div className="col-md-8 col-lg-9">
                      <input disabled name="address" type="text" className="form-control" id="Address" value={membershiptype} onChange={(e) => setMembershiptype(e.target.value)} />
                    </div>
                  </div>

                  {/* Rest of the input fields */}
                  <div className="row mb-3">
                    <label htmlFor="Address" className="col-md-4 col-lg-3 col-form-label">Address</label>
                    <div className="col-md-8 col-lg-9">
                      <input disabled name="address" type="text" className="form-control" id="Address" value={address} onChange={(e) => setAddress(e.target.value)} />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <label htmlFor="Phone" className="col-md-4 col-lg-3 col-form-label">Phone</label>
                    <div className="col-md-8 col-lg-9">
                      <input disabled name="phone" type="text" className="form-control" id="Phone" value={phonenumber} onChange={(e) => setPhonenumber(e.target.value)} />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <label htmlFor="Email" className="col-md-4 col-lg-3 col-form-label">Email</label>
                    <div className="col-md-8 col-lg-9">
                      <input disabled name="email" type="email" className="form-control" id="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                  </div>
                  {isapproved ===1?<div className="row mb-3">
                    <label htmlFor="Email" className="col-md-4 col-lg-3 col-form-label">Membership Fee (Paid)</label>
                    <div className="col-md-8 col-lg-9">
                      <input disabled name="email" type="email" className="form-control" id="Email" value={membershipfee} />
                    </div>
                  </div>:''}
                  

                  {/* <div className="text-center">
                    <button type="button" onClick={approveUser} className="btn btn-primary">
                      Approve User
                    </button>
                  </div> */}
                  <div className="text-center">
                    {isapproved ===1?<button type="button" onClick={disapproveUser} className="btn btn-danger">
                      Diactivate User
                    </button>:<button type="button" className="btn btn-primary">
                      <Link to={`/admindashboard/${username}/edit/${userid}`}style={{color:'#fff'}} >Activate User</Link>
                    </button>}
                    
                  </div>
                  
                </form>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Adminviewuser;
