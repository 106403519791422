import React from 'react';
import '../../adminstyle/assets/vendor/bootstrap/css/bootstrap.min.css';
import '../../adminstyle/assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../../adminstyle/assets/vendor/boxicons/css/boxicons.min.css';
import '../../adminstyle/assets/vendor/quill/quill.snow.css';
import '../../adminstyle/assets/vendor/quill/quill.bubble.css';
import '../../adminstyle/assets/vendor/remixicon/remixicon.css';
import '../../adminstyle/assets/vendor/simple-datatables/style.css';
import '../../adminstyle/assets/css/style.css';
import Clientnav from './clientpartials/clientnav';
import Clientsidebar from './clientpartials/clientsidebar';
import Userregisteredeventtable from './clientpartials/tables/eventstable';
import {useState, useEffect } from 'react';
import { Link,useLocation } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'

const Userregisteredeventdetails = () => {
  const { username,viewallusers,event } = useParams();
  const navigate = useNavigate();
  const [newusers, setnewusers] = useState([]);
  const [eventbanner, seteventbanner] = useState([]);
  const [eventtitle, seteventtitle] = useState([]);
  const [aboutevent, setaboutevent] = useState([]);
  const [startdate, setstartdate] = useState([]);
  const [enddate, setenddate] = useState([]);
  const [totalregisteredevents, settotalregisteredevents] = useState([]);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  useEffect(() => {
    var AdminauthToken = localStorage.getItem('authTokenAdmin')
      if(AdminauthToken==null){
        navigate('/login')
      }
    const fullURL = window.location.href;
    // Function to fetch data from the backend API

    const fetchEventBanner = async () => {
      const jsonData={
        event:'New conference'
      }
      try {
        const response = await axios.post(`${backendUrl}/fetchRegisteredEventBanner`,jsonData,{
          headers: {
            'Content-Type': 'application/json',
          },
        }); // Replace with your API endpoint
        const data = response.data;
        // console.log(data.totalnewusers)
        seteventbanner(data.message[0].eventbanner);
        seteventtitle(data.message[0].eventTitle);
        setaboutevent(data.message[0].aboutEvent);
        setstartdate(data.message[0].eventstartdate);
        setenddate(data.message[0].eventenddate);
        console.log(data.message);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchEventBanner()
    // Clear the interval when the component unmounts to avoid memory leaks
  }, []);
  return (
    <div>
      <Clientnav username={username}/>
      <Clientsidebar />
      <main id="main" className="main">

        <div className="pagetitle">
          <h1>Events Details</h1>
          {/* <h1>{viewallusers?'All Users':'Dashboard'}</h1> */}
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
              <li className="breadcrumb-item active">Events Details</li>
            </ol>
          </nav>
        </div>

        <section className="section dashboard w-100">
          <div className="row w-100">
            <div className="col-lg-12">
              <div className="col-lg-6">
                <h5>{eventtitle}</h5>
              </div>
              <div className="col-lg-6">
                <img src={eventbanner} alt={eventtitle} style={{width:'100%',height:'60%'}}/>
              </div>
              <div className="col-lg-6">
                <p>{aboutevent}</p>
                <p><strong>Starts</strong>: {startdate} - <strong>Ends:</strong> {enddate}</p>
              </div>
            </div>
          </div>
        </section>
        
      </main>
    </div>
  );
};

export default Userregisteredeventdetails;