import React from 'react';
import Navbar from '../partials/navbar';
import Footer from '../partials/footer';
import { Link, useParams } from "react-router-dom";
import { Form } from 'react-bootstrap';
import Success from '../images/svg/success.png'
import { useNavigate } from 'react-router-dom';
const Successpage = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const { message }= useParams()
    const contactform={
        margin:'0 auto',
        width:'60%'
    }
    const workGrpContentAnchorColor = {
        textDecoration:'none',
        color:'#000',
        background:'#E8EBE3',
        paddingTop:'6px',
        paddingLeft:'15px',
        paddingRight:'15px',
        paddingBottom:'6px',
        marginTop:'100px'
    };
  return (
    <div className="">
      <div className="container w-100 text-center" style={{marginTop:'100px'}}>
        <img src={Success} width='120'/>
        <br/>
        <h3 className='mt-5'>{message?message:'Sign Up Successful. Kindly check your mail.'}</h3>
        <br/>
        <br/>
        <Link style={workGrpContentAnchorColor} className='mt-5' onClick={goBack}>Go Back</Link>
      </div>  
    </div>
  );
};

export default Successpage;
