import React from 'react';
import Navbar from '../partials/navbar';
import Footer from '../partials/footer';
import { Link } from "react-router-dom";
import { Form } from 'react-bootstrap';
import { useEffect,useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import logo from '../images/logo.png'
const Login = () => {
  const [username, setusername] = useState('');
  const [password, setpassword] = useState('');
  const [apiResponse, setapiResponse] = useState([]);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();
  const contactform={
    margin:'0 auto',
    width:'60%'
  }
  const handleLogin = (event)=>{
    event.preventDefault();
    
    // Submit the form data using Axios
    const jsonData = JSON.stringify({
      username:username,
      password:password,

    });
    axios.post(`${backendUrl}/memberlogin`,jsonData,{
      headers: {
        'Content-Type': 'application/json',
      },
    } )
    .then((response) => {
      // Handle success
      console.log('Form submitted successfully!', response);
      setapiResponse(response.data)
      if(response.data === 'You are Logged In'){
        navigate(`/dashboard/${username}`)
        localStorage.setItem('authToken',username)
      }
      else{
        navigate('/login')
      }
    })
    .catch((error) => {
      // Handle error
      console.error('Form submission error:', error);
    });
  }
  return (
    <div className="">
       {/* <header>
        <Navbar/>
      </header> */}
      <div className="container w-100 mt-5">
        <form className='contactForm' style={contactform}>
        <div className='text-center'>
          <img className='mt-4 mb-5' src={logo} width='200'/>
          <h2 style={{fontWeight:'bolder',textAlign:'left'}}>Log In</h2>
          <p style={{textAlign:'left'}}>Welcome Back</p>
        </div>
        <p style={{ color: apiResponse !== 'You are Logged In' ? 'red' : '' }}>
          {apiResponse}
        </p>
        <div className="mb-3">
            <input type="text" className="form-control border-top-0 border-start-0 border-end-0 rounded-0" style={{ borderColor: 'black' }} id="username" placeholder="e.g adaobimensah or adaobimensah@gmail.com" value={username} onChange={(e)=>{
                setusername(e.target.value)
              }} />
            <label htmlFor="password" className="form-label">Enter Your  Username Or Email</label>
          </div>
        <div className="mb-3">
            <input type="password" className="form-control border-top-0 border-start-0 border-end-0 rounded-0" style={{ borderColor: 'black' }} id="password" placeholder="e.g 1234AbcD@" value={password} onChange={(e)=>{
                setpassword(e.target.value)
              }} />
            <label htmlFor="password" className="form-label">Enter Password</label>
          </div>

          <button type="submit" className="btn btn-primary w-100 rounded-0" style={{background:'#7FA037',border:'none',borderRadius:'none'}}onClick={handleLogin} >Login</button>
          <br/>
          <div className='mt-5 text-center'>
            <span>Don’t have an account ? <Link to='/signup' style={{color:'#7FA037'}}>Sign Up</Link></span>
           </div>
          <div className='mt-5 text-center'>
            <span><Link style={{textDecoration:'none',color:'#7FA037'}} to='/forgotpassword'>Forgot Password ?</Link></span>
           </div>
          <br/>
        </form>
      </div>
      {/* <Footer/> */}
    </div>
  );
};

export default Login;
