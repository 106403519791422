import React from 'react';
import Navbar from '../partials/navbar';
import Footer from '../partials/footer';
import { Link } from "react-router-dom";
import { useEffect,useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import logo from '../images/logo.png'
import { Form } from 'react-bootstrap';
const Signup = () => {
  const contactform={
    margin:'0 auto',
    width:'60%'
  }
  const [username, setusername] = useState('');
  const [password, setpassword] = useState('');
  const [fullname, setfullname] = useState('');
  const [phonenumber, setphonenumber] = useState('');
  const [email, setemail] = useState('');
  const [memebershiptype, setmemebershiptype] = useState('');
  const [confirmpassword, setconfirmpassword] = useState('');
  const [privacyChecked, setprivacyChecked] = useState(false);
  const [apiResponse, setapiResponse] = useState([]);
  const [selectionMade, setSelectionMade] = useState(false);
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  // var handleChange = (event) =>{
  //   setusername(event.target.value);
  //   setfullname(event.target.value);
  //   setphonenumber(event.target.value);
  //   setemail(event.target.value);
  //   setpassword(event.target.value);
  //   setconfirmpassword(event.target.value);
  // }
  const handlePrivacyCheck = (event) => {
    setprivacyChecked(event.target.checked);
  };
  // var formData=()
  const handleSubmit = (event)=>{
    event.preventDefault();
    if (!selectionMade){
      setapiResponse("Select Memebership Type")
    }
    else{

      const jsonData = JSON.stringify({
        username:username,
        fullname:fullname,
        phonenumber:phonenumber,
        password:password,
        confirmpassword:confirmpassword,
        email: email,
        memebershiptype:memebershiptype
      });
      axios.post(`${backendUrl}/register`,jsonData,{
        headers: {
          'Content-Type': 'application/json',
        },
      } )
      .then((response) => {
        // Handle success
        console.log('Form submitted successfully!', response);
        setapiResponse(response.data)
        if(response.data === 'Registration Successful'){
          const message="Sign Up Successful. Kindly check your mail."
          navigate(`/success/${message}`)
        }
        else{
          navigate('/signup')
        }
      })
      .catch((error) => {
        // Handle error
        console.error('Form submission error:', error);
      });
    }
    // Submit the form data using Axios
  }
  return (
    <div className="">
       {/* <header>
        <Navbar/>
      </header> */}
      <div className="container w-100 mt-5">
        <form className='contactForm' style={contactform}>
        <div className='text-center'>
          <img className='mt-1 mb-5' src={logo} width='200'/>
          <h2 style={{fontWeight:'bolder',textAlign:'left'}}>Sign Up</h2>
        </div>
        <p style={{ color: apiResponse !== 'Registration Successful' ? 'red' : '' }}>
          {apiResponse}
        </p>

          <div className="row mb-3">
            <div className="col">
              <input type="text" value={username} onChange={(e)=>{
                setusername(e.target.value)
              }} className="form-control border-top-0 border-start-0 border-end-0 rounded-0" style={{ borderColor: 'black' }} id="name" placeholder="e.g adaobimensah" />
              <label htmlFor="name" className="form-label">Enter Your  Username</label>
            </div>
            <div className="col">
              <input type="text" value={fullname} onChange={(e)=>{
                setfullname(e.target.value)
              }} className="form-control border-top-0 border-start-0 border-end-0 rounded-0" style={{ borderColor: 'black' }} id="fullname" placeholder="e.g Adaobi Mensah" />
              <label htmlFor="fullname" className="form-label">Enter your Full Name</label>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <input type="text" value={phonenumber} onChange={(e)=>{
                setphonenumber(e.target.value)
              }} className="form-control border-top-0 border-start-0 border-end-0 rounded-0" style={{ borderColor: 'black' }} id="name" placeholder="e.g +44 903389403" />
              <label htmlFor="name" className="form-label">Phone Number</label>
            </div>
            <div className="col">
              <input type="email" value={email} onChange={(e)=>{
                setemail(e.target.value)
              }}  className="form-control border-top-0 border-start-0 border-end-0 rounded-0" style={{ borderColor: 'black' }} id="email" placeholder="e.g email@company.com" />
              <label htmlFor="email" className="form-label">Your work email</label>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <select className="form-control border-top-0 border-start-0 border-end-0 rounded-0" style={{ borderColor: 'black' }} id='membershiptype' value={memebershiptype} onChange={(e)=>{
                setmemebershiptype(e.target.value)
                setSelectionMade(true);
              }}>
                <option value={'Select Memebership Type'} >Select Memebership Type</option>
                <option>Consultant</option>
                <option>SAS doctor</option>
                <option>Trainee</option>
                <option>Retired Member</option>
                <option>Affiliate Member</option>
                <option>FYI/FY2</option>
                <option>Medical Student</option>
              </select>
              <label htmlFor="email" className="form-label">Membership Type</label>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <input type="password" value={password} onChange={(e)=>{
                setpassword(e.target.value)
              }} className="form-control border-top-0 border-start-0 border-end-0 rounded-0" style={{ borderColor: 'black' }} id="password" placeholder="e.g 1234AbcD@" />
              <label htmlFor="name" className="form-label">Enter Password</label>
            </div>
            <div className="col">
              <input type="password" value={confirmpassword} onChange={(e)=>{
                setconfirmpassword(e.target.value)
              }} className="form-control border-top-0 border-start-0 border-end-0 rounded-0" style={{ borderColor: 'black' }} id="confirmpassword" placeholder="e.g 1234AbcD@" />
              <label htmlFor="email" className="form-label">Confirm Password</label>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-6">
              {/* <div className='row'>
                <div className='col-1'>
                    <Form>
                        <Form.Check className='' type="checkbox"/>
                    </Form>
                </div>
                <div className='col-10'>
                    <p>Send me emails with tips on how to find vendor that fits my needs</p>
                </div>
              </div> */}
            </div>
            <div className='row'>
                    <div className='col-1'>
                    <Form>
                      <Form.Check
                        className=''
                        type="checkbox"
                        checked={privacyChecked}
                        onChange={handlePrivacyCheck}
                      />
                    </Form>
                    </div>
                    <div className='col-11'>
                        <p>Yes, I understand and agree to the <Link to={'/termsofservice'} style={{textDecoration:'none'}}><span style={{color:'#7FA037'}}>Association of Black Psychiatrists United Kingdom Terms of Service</span></Link>, including the <Link style={{textDecoration:'none'}}><span style={{color:'#7FA037'}}>User Agreement</span></Link> and <Link to={'/privacy'} style={{textDecoration:'none'}}><span style={{color:'#7FA037'}}>Privacy Policy</span></Link></p>
                    </div>
                </div>
          </div>
          {/* <div className="mb-3">
            <input type="password" className="form-control border-top-0 border-start-0 border-end-0 rounded-0" style={{ borderColor: 'black' }} id="password" placeholder="Tell us about your project" />
            <label htmlFor="password" className="form-label">Your Message</label>
          </div> */}
          <button type="submit" className="btn btn-primary w-100 rounded-0" style={{background:'#7FA037',border:'none',borderRadius:'none'}} onClick={handleSubmit} disabled={!privacyChecked}>Create Account</button>
          <br/>
          <div className='mt-5 text-center'>
            <span>Already have an account ? <Link to='/login' style={{color:'#7FA037'}}>Log In</Link></span>
           </div>
          <br/>
        </form>
      </div>
      {/* <Footer/> */}
    </div>
  );
};

export default Signup;
