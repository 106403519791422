import React from 'react';
import '../../adminstyle/assets/vendor/bootstrap/css/bootstrap.min.css';
import '../../adminstyle/assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../../adminstyle/assets/vendor/boxicons/css/boxicons.min.css';
import '../../adminstyle/assets/vendor/quill/quill.snow.css';
import '../../adminstyle/assets/vendor/quill/quill.bubble.css';
import '../../adminstyle/assets/vendor/remixicon/remixicon.css';
import '../../adminstyle/assets/vendor/simple-datatables/style.css';
import '../../adminstyle/assets/css/style.css';
import Adminnav from './adminpartials/adminnav';
import AdminSidebar from './adminpartials/adminsidebar';
import Forummemberstable from './adminpartials/tables/wokgroupmembertable'
import {useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'

const Allforummemeber = () => {
    const { workgroup,username } = useParams();
    const navigate = useNavigate();
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const [newusers, setnewusers] = useState([]);
    const [forumusers, setforumusers] = useState([]);
  
    useEffect(() => {
      var AdminauthToken = localStorage.getItem('authTokenAdmin')
      if(AdminauthToken==null){
        navigate('/adminlogin')
      }
      const fetchNoOfUser = async () => {
        try {
          const response = await axios.get(`${backendUrl}/allusers`);
          const jsonData = response.data;
          setnewusers(jsonData.totalusers);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      const fetchNewfUser = async () => {
        const jsonData = {
          workgroup: workgroup
        };

        try {
          const response = await axios.post(`${backendUrl}/forumuserslist`, jsonData, {
            headers: {
              'Content-Type': 'application/json',
            },
          });
          const data = response.data;
          setforumusers(data.message);
          console.log(data)
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      const interval = setInterval(() => {
        fetchNoOfUser();
        fetchNewfUser();
      }, 1000);
  
      return () => clearInterval(interval);
    }, [backendUrl, workgroup]);
  
    return (
      <div>
        <Adminnav username={username} />
        <AdminSidebar />
        <main id="main" className="main">
          <div className="pagetitle">
            <h1>{workgroup} Forum Members</h1>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                <li className="breadcrumb-item active">{workgroup} Forum Members</li>
              </ol>
            </nav>
          </div>
          <section className="section dashboard w-100">
            <div className="row w-100">
              <div className="col-lg-12"></div>
            </div>
            <Forummemberstable data={forumusers} username={username} />
          </section>
        </main>
      </div>
    );
  };

export default Allforummemeber;