import React from 'react';
import '../../../../adminstyle/assets/vendor/bootstrap/css/bootstrap.min.css';
import '../../../../adminstyle/assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../../../../adminstyle/assets/vendor/boxicons/css/boxicons.min.css';
import '../../../../adminstyle/assets/vendor/quill/quill.snow.css';
import '../../../../adminstyle/assets/vendor/quill/quill.bubble.css';
import '../../../../adminstyle/assets/vendor/remixicon/remixicon.css';
import '../../../../adminstyle/assets/vendor/simple-datatables/style.css';
import '../../../../adminstyle/assets/css/style.css';
import { Link } from "react-router-dom";
import { useEffect,useState } from 'react';
import DataTable from 'react-data-table-component';

const Registeredeventtable = (props) => {
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState(props.data);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  useEffect(() => {
    if (props.data && props.data.length > 0) {
      setFilteredData(props.data);
    }
  }, [props.data]);
  

  const handleSearch = (value) => {
    setSearchText(value);
    if (value === '') {
      setFilteredData(props.data);
    } else {
      const filteredResults = props.data.filter((item) =>
        Object.values(item)
          .join(' ')
          .toLowerCase()
          .includes(value.toLowerCase())
      );
      setFilteredData(filteredResults);
    }
  };
  const workGrpContentAnchor = {
    textDecoration: 'none',
    color: '#fff',
    background: '#0d9e6b',
    padding: '6px 15px',
  };
  
  const workGrpContentAnchorColor = {
    textDecoration: 'none',
    color: '#000',
    background: '#E8EBE3',
    padding: '6px 15px',
    margin: '10px',
  };

  const workGrpContentAnchor2 = {
    textDecoration: 'none',
    color: '#fff',
    background: '#dc3545',
    padding: '6px 15px',
  };

  const columns = [
    
    {
      name: 'Full Name',
      selector: 'fullname',
      sortable: true,
    },
    {
        name: 'Event Registred For',
        selector: 'event',
        sortable: true, 
    },
    {
        name: 'Email',
        selector: 'email',
        sortable: true, 
    },
    {
        name: 'Phonenumber',
        selector: 'phonenumber',
        sortable: true, 
    },
    {
        name: 'Membership Type',
        selector: 'membershiptype',
        sortable: true, 
    },
    {
        name: 'Occupation',
        selector: 'occupation',
        sortable: true, 
    },
    {
        name: 'Address',
        selector: 'address',
        sortable: true, 
    },
    {
      name: 'Registered Date',
      selector: 'date',
      sortable: true,
    },
    // {
    //   name: 'Created Date',
    //   selector: 'createdDate',
    //   sortable: true,
    // },
    // {
    //   name: 'Edit',
    //   cell: (row) => (
    //       row.approved === 1 ? (
    //           <span>Approved</span>
    //       ) : (
    //         <Link to={`/admindashboard/${props.username}/edit/${row.userid}`} style={workGrpContentAnchor}>
    //             Edit
    //         </Link>
    //       )
    //   ),
    //   ignoreRowClick: true,
    //   allowOverflow: false,
    //   button: true,
    // },
    // {
    //   name: 'View',
    //   cell: (row) => (
    //     <Link to={`/admindashboard/${props.username}/viewuser/${row.userid}/${'User Details'}`} style={workGrpContentAnchorColor} data={props.data}>
    //       View
    //     </Link>
    //   ),
    //   ignoreRowClick: true,
    //   allowOverflow: false,
    //   button: true,
    // },
    // {
    //   name: 'Delete',
    //   cell: (row) => (
    //     <span><Link to={`/admindashboard/${props.username}/${row.id}/delete`} style={workGrpContentAnchor2} data={props.data}>Delete
    //     </Link></span> 
    //   ),
    //   ignoreRowClick: true,
    //   allowOverflow: false,
    //   button: true,
    // },
  ];
 
  return (
    <div className="">
      <div className="col-12">
        <div className="card recent-sales overflow-auto">

          <div className="card-body">
            <h5 className="card-title">All Events</h5>

            <input
              type="text"
              placeholder="Search..."
              value={searchText}
              onChange={(e) => handleSearch(e.target.value)}
              style={{ marginBottom: '10px' }}
            />

            <DataTable
              columns={columns}
              data={filteredData}
              pagination
              highlightOnHover
              responsive
              defaultSortField="fullname"
              noHeader
              customStyles={{
                rows: {
                  style: {
                    fontSize: '14px',
                    borderBottom: '1px solid #ddd',
                    padding: '10px 0',
                  },
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registeredeventtable;
