import React from 'react';
import Navbar from '../partials/navbar';
import Footer from '../partials/footer';
import { Link } from "react-router-dom";
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useEffect,useState } from 'react';
import axios from 'axios';
import logo from '../images/logo.png'

const Forgotpassword = () => {
  const [email, setemail] = useState('');
  const [apiResponse, setapiResponse] = useState([]);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();
  const contactform={
    margin:'0 auto',
    width:'60%'
  }
  const handleforgetpassword = async (event) =>{
    event.preventDefault()
    const jsonData=({
      email:email
    })
    try {
      const response = await axios.post(`${backendUrl}/fetchemail`,jsonData,{
        headers: {
          'Content-Type': 'application/json',
        },
      }); // Replace with your API endpoint
      const data = response.data;
      setapiResponse(data.message)
      console.log(data.message)
      if(data.message === 'Email Confirmed'){
        const message='A link have been sent to your email to reset your password.'
        navigate(`/mail/${message}`)
      }
      else{
        navigate('/forgotpassword')
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  return (
    <div className="">
       {/* <header>
        <Navbar/>
      </header> */}
      <div className="container w-100 mt-5">
        <form className='contactForm' style={contactform}>
        <div className='text-center'>
          <img className='mt-4 mb-5' src={logo} width='200'/>
          <h2 style={{fontWeight:'bolder',textAlign:'left'}}>Forgot Password</h2>
          <p style={{textAlign:'left'}}>Welcome Back</p>
        </div>
        <p style={{ color: apiResponse !== 'Email Confirmed' ? 'red' : '' }}>
          {apiResponse}
        </p>
        <div className="mb-3">
            <input type="text" className="form-control border-top-0 border-start-0 border-end-0 rounded-0" style={{ borderColor: 'black' }} id="text" placeholder="e.g adaobimensah or adaobimensah@gmail.com" value={email} onChange={(e)=>{
                setemail(e.target.value)
              }} />
            <label htmlFor="password" className="form-label">Enter Your  email address / Username</label>
          </div>

          <button type="button" className="btn btn-primary w-100 rounded-0" style={{background:'#7FA037',border:'none',borderRadius:'none'}}onClick={handleforgetpassword}>Submit</button>
          <br/>
          <div className='mt-5 text-center'>
            <span>Don’t have an account ? <Link to={'/signup'} style={{color:'#7FA037'}}>Sign Up</Link></span>
           </div>
          <br/>
        </form>
      </div>
      {/* <Footer/> */}
    </div>
  );
};

export default Forgotpassword;
