import React from 'react';
import '../../../adminstyle/assets/vendor/bootstrap/css/bootstrap.min.css';
import '../../../adminstyle/assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../../../adminstyle/assets/vendor/boxicons/css/boxicons.min.css';
import '../../../adminstyle/assets/vendor/quill/quill.snow.css';
import '../../../adminstyle/assets/vendor/quill/quill.bubble.css';
import '../../../adminstyle/assets/vendor/remixicon/remixicon.css';
import '../../../adminstyle/assets/vendor/simple-datatables/style.css';
import '../../../adminstyle/assets/css/style.css';
import {useState, useEffect } from 'react';
import logo from '../../images/logo.png'
import { Navigate, useParams } from 'react-router-dom';
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import axios from 'axios'



const Clientnav = (props) => {
  const { username } = useParams();
  const [userName, setuserName] = useState([]);
  const [unreadnotification, setunreadnotification] = useState([]);
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const contactform = {
    margin: '0 auto',
    width: '60%'
  };
  const logout = ()=>{
    localStorage.removeItem('authToken')
    navigate('/login')
  }
  useEffect(() => {
    // Function to fetch data from the backend API
    
    const fetchUsername = async () => {
      const jsonData=({
        username:username
      })
      try {
        const response = await axios.post(`${backendUrl}/fetchusername`,jsonData,{
          headers: {
            'Content-Type': 'application/json',
          },
        }); // Replace with your API endpoint
        const data = response.data;
        console.log(data.username)
        setuserName(data.username);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // const readnotification = async () => {
    //   const jsonData=({
    //     readnotification:1
    //   })
    //   try {
    //     const response = await axios.post(`${backendUrl}/readnotification`,jsonData,{
    //       headers: {
    //         'Content-Type': 'application/json',
    //       },
    //     }); // Replace with your API endpoint
    //     const data = response.data;
    //     // setreadnotification(data.message);
    //   } catch (error) {
    //     console.error('Error fetching data:', error);
    //   }
    // };
    const unreadnotification = async () => {
      const jsonData=({
        username:userName
      })
      try {
        const response = await axios.post(`${backendUrl}/unreadnotification`,jsonData,{
          headers: {
            'Content-Type': 'application/json',
          },
        }); // Replace with your API endpoint
        const data = response.data;
        setunreadnotification(data.message);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    

   
    // readnotification()
    fetchUsername()
    const intervalId = setInterval(() => {
      unreadnotification()
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);
  return (
    <div className="">
      <header id="header" className="header fixed-top d-flex align-items-center">

        <div className="d-flex align-items-center justify-content-between p-3">
      
          <Link to={"/"} className="logo d-flex align-items-center">
            <img className='h-50' src={logo} alt="" width='100' height='600'  style={{height:'40%'}}/>
          </Link>
          <i className="bi bi-list toggle-sidebar-btn"></i>
        </div>

        {/* <div className="search-bar">
          <form className="search-form d-flex align-items-center" method="POST" action="#">
            <input type="text" name="query" placeholder="Search" title="Enter search keyword" />
            <button type="submit" title="Search"><i className="bi bi-search"></i></button>
          </form>
        </div> */}

        <nav className="header-nav ms-auto">
          <ul className="d-flex align-items-center">

            <li className="nav-item dropdown">

              <Link to={`/dashboard/${username}/notifications`} className="nav-link nav-icon" >
                <i className="bi bi-bell"></i>
                <span className="badge badge-number">{props.unreadnotification===1 || unreadnotification===1?(""):(<i className="bi bi-circle-fill" style={{color:'red'}}></i>)}
                </span>
              </Link>

            </li>

            <li className="nav-item dropdown pe-3">
              <span className="nav-link nav-profile d-flex align-items-center pe-0" data-bs-toggle="dropdown">
                {/* <img src="adminassets/img/profile-img.jpg" alt="Profile" className="rounded-circle" /> */}
                <h6 style={{margin:'5px'}}>{userName}</h6>
                <i class="bi bi-person-circle" style={{fontSize:'20px'}}></i>
                <span className="d-none d-md-block dropdown-toggle ps-2"></span>
              </span>

              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                <li className="dropdown-header">
                  <h6></h6>
                  <span>{userName}</span>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
     
                  <a onClick={logout} className="dropdown-item d-flex align-items-center">
                    <i className="bi bi-person"></i>
                    <span>Logout</span>
                    </a>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
              </ul>
            </li>

          </ul>
        </nav>

      </header>
    </div>
  );
};

export default Clientnav;
