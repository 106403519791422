import React from 'react';
import Navbar from '../partials/navbar';
import Footer from '../partials/footer';
import { Link } from "react-router-dom";
import LongDash from '../images/svg/longdash.png'
const Membershipfee = () => {
  const contactform={
    margin:'0 auto',
    width:'60%'
  }
  return (
    <div className="">
       <header>
        <Navbar/>
      </header>
      <div className="container mt-5 memebershipfee" style={{width:'80%'}}>
        <div className='row'>
          <h3 style={{fontWeight:'bolder'}}>Membership Fees:</h3>
          <p>The following fees will apply</p>
        </div>
        <div className='row'>
          <div className='col-3' >
            <h2 style={{fontWeight:'300',fontSize:'30px'}}>Consultants</h2>
          </div>
          <div className='col-5' style={{}}>
            <img src={LongDash} width='350'/>
          </div>
          <div className='col-4' style={{}}>
            <h3 style={{color:'#7FA037'}}>£100</h3>
          </div>
        </div>
        <div className='row'>
          <div className='col-3' >
            <h2 style={{fontWeight:'300',fontSize:'30px'}}>SAS doctors</h2>
          </div>
          <div className='col-5' style={{}}>
            <img src={LongDash} width='350'/>
          </div>
          <div className='col-4' style={{}}>
            <h3 style={{color:'#7FA037'}}>£70</h3>
          </div>
        </div>
        <div className='row'>
          <div className='col-3' >
            <h2 style={{fontWeight:'300',fontSize:'30px'}}>Trainees</h2>
          </div>
          <div className='col-5' style={{}}>
            <img src={LongDash} width='350'/>
          </div>
          <div className='col-4' style={{}}>
            <h3 style={{color:'#7FA037'}}>£50</h3>
          </div>
        </div>
        <div className='row'>
          <div className='col-3' >
            <h2 style={{fontWeight:'300',fontSize:'30px'}}>Retired Members</h2>
          </div>
          <div className='col-5' style={{}}>
            <img src={LongDash} width='350'/>
          </div>
          <div className='col-4' style={{}}>
            <h3 style={{color:'#7FA037'}}>£50</h3>
          </div>
        </div>
        <div className='row'>
          <div className='col-3' >
            <h2 style={{fontWeight:'300',fontSize:'30px'}}>Affiliate Members</h2>
          </div>
          <div className='col-5' style={{}}>
            <img src={LongDash} width='350'/>
          </div>
          <div className='col-4' style={{}}>
            <h3 style={{color:'#7FA037'}}>£50</h3>
          </div>
        </div>
        <div className='row'>
          <div className='col-3' >
            <h2 style={{fontWeight:'300',fontSize:'30px'}}>FYI/FY2</h2>
          </div>
          <div className='col-5' style={{}}>
            <img src={LongDash} width='350'/>
          </div>
          <div className='col-4' style={{}}>
            <h3 style={{color:'#7FA037'}}>£20</h3>
          </div>
        </div>
        <div className='row'>
          <div className='col-3' >
            <h2 style={{fontWeight:'300',fontSize:'30px'}}>Medical Students</h2>
          </div>
          <div className='col-5' style={{}}>
            <img src={LongDash} width='350'/>
          </div>
          <div className='col-4' style={{}}>
            <h3 style={{color:'#7FA037'}}>Free</h3>
          </div>
        </div>
        <br/>
        <br/>
        <div className='row'>
          <h4 style={{fontWeight:'bolder'}}>Make Payment to the following account details:</h4>
          <br/>
          <div className='row'>
            <div className='col-3' >
              <h5 style={{fontWeight:'300',fontSize:'25px'}}>Bank Name</h5>
            </div>
            <div className='col-5' style={{}}>
              <img src={LongDash} width='350'/>
            </div>
            <div className='col-4' style={{}}>
              <h3 style={{color:'#7FA037',fontSize:'25px'}}>Swift Bank</h3>
            </div>
          </div>
          <div className='row'>
            <div className='col-3' >
              <h5 style={{fontWeight:'300',fontSize:'25px'}}>Account Name</h5>
            </div>
            <div className='col-5' style={{}}>
              <img src={LongDash} width='350'/>
            </div>
            <div className='col-4' style={{}}>
              <h3 style={{color:'#7FA037',fontSize:'25px'}}>58568003883</h3>
            </div>
          </div>
          <br/>
          <br/>
          <br/>
          <br/>
          <div className='row'>
            <h4 style={{fontSize:'17px',fontWeight:'bolder'}}>Please Note</h4>
            <br/>
            <p>Kindly add your membership category to the payment narration and ensured that the amount paid is the one for your category</p>
            <br/>
            <br/>
            <br/>
            <br/>
            <button type="submit" className="btn btn-primary w-25 rounded-0" style={{background:'#7FA037',border:'none',borderRadius:'none'}}> <Link to='/constitution' style={{textDecoration:'none',color:'#FFFFFF'}}>Read the constitution</Link> </button> <button type="submit" className="btn btn-primary w-25 rounded-0" style={{background:'none',border:'#7FA037 solid 1px',borderRadius:'none',color:'#7FA037',marginLeft:'5px'}}> <Link to='/signup' style={{textDecoration:'none',color:'#7FA037',fontWeight:'bolder'}}>Become a Member</Link> </button>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default Membershipfee;
