import React from 'react';
import '../../adminstyle/assets/vendor/bootstrap/css/bootstrap.min.css';
import '../../adminstyle/assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../../adminstyle/assets/vendor/boxicons/css/boxicons.min.css';
import '../../adminstyle/assets/vendor/quill/quill.snow.css';
import '../../adminstyle/assets/vendor/quill/quill.bubble.css';
import '../../adminstyle/assets/vendor/remixicon/remixicon.css';
import '../../adminstyle/assets/vendor/simple-datatables/style.css';
import '../../adminstyle/assets/css/style.css';
import Adminnav from './adminpartials/adminnav';
import AdminSidebar from './adminpartials/adminsidebar';
import Newmemberstable from './adminpartials/tables/newmembers'
import {useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'

const Uploadevent = () => {
  const { username } = useParams();
  const navigate = useNavigate();
  const [eventtitle, seteventtitle] = useState("");
  const [phonenumber, setphonenumber] = useState("");
  const [previewUrl, setPreviewUrl] = useState('');
const [eventstartdate, seteventstartdate] = useState('');
const [eventenddate, seteventenddate] = useState('');// You missed this state
const [apiResponse, setapiResponse] = useState([]);
const [eventimages, seteventimages] = useState(null);
const backendUrl = process.env.REACT_APP_BACKEND_URL;
useEffect(()=>{
  var AdminauthToken = localStorage.getItem('authTokenAdmin')
  if(AdminauthToken==null){
    navigate('/adminlogin')
  }
},[])
const handleImageChange = (e) => {
  const selectedImage = e.target.files[0];

  if (selectedImage) {
    seteventimages(selectedImage);
    const imageUrl = URL.createObjectURL(selectedImage);
    setPreviewUrl(imageUrl);
    console.log(previewUrl);
  }
};
const uploadevent = async (event) => {
  event.preventDefault();
  const formData = new FormData();
  formData.append('image', eventimages);

  if (!eventimages) {
    setapiResponse("Please select a banner");
    return;
  } 
  if (!eventstartdate || !eventtitle || !eventenddate) {
    setapiResponse("Please fill all fields");
    return;
  } 
  else {
    await handleUploadEventPhotos(formData); // Pass formData here
    handleInsertEvent(); // You can await this too if needed
  }
};

const handleUploadEventPhotos = async (formData) => {
  try {
    await axios.post(`${backendUrl}/uploadeventphotos`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    console.log('Image uploaded successfully');
  } catch (error) {
    console.error('Error uploading image:', error);
  }
};

const handleInsertEvent = async () => {
  const jsonData = {
    eventstartdate: eventstartdate,
    eventtitle: eventtitle, // Make sure this field is defined
    eventenddate: eventenddate,
    eventImage: eventimages==="" ? null : eventimages.name,
  };

  try {
    const response = await axios.post(`${backendUrl}/uploadevent`, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const data = response.data;
    console.log(data);

    if (data.message === "Event Uploaded Successfully") {
      const message = 'Event Uploaded Successfully';
      navigate(`/success/${message}`);
      setapiResponse(data.message);
    }
    else{
      setapiResponse(data.message);
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}


  return (
    <div>
      <Adminnav username={username} />
      <AdminSidebar />
      <main id="main" className="main">

        <div className="pagetitle">
          <h1>Dashboard</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
              <li className="breadcrumb-item active">Dashboard</li>
            </ol>
          </nav>
        </div>

        <section className="section">
          <div className="row w-100">
          <p style={{ color: apiResponse !== 'Event Uploaded Successfully' ? 'red' : '' }}>
          {apiResponse}
        </p>
            <form>
                <div class="row mb-3">
                <label for="profileImage" class="col-md-4 col-lg-3 col-form-label">Event Image</label>
                <div class="col-md-8 col-lg-9">
                    <label for='uploadImage'>
                        {previewUrl===''?<i class="bi bi-image" style={{fontSize:'110px'}}></i>:<img src={previewUrl} alt="Profile Photo" style={{width:'250px',height:'250px'}} />}
                        </label>
                    <div class="pt-2">
                    <input id='uploadImage' hidden type="file" accept=".jpg,.png,.jpeg" name='image' onChange={handleImageChange} />
                    <label class="btn btn-primary btn-sm" for='uploadImage'><i class="bi bi-upload" style={{color:'#fff'}}></i></label>
                    {/* <a href="#" class="btn btn-danger btn-sm" title="Remove my profile image"><i class="bi bi-trash"></i></a> */}
                    </div>
                </div>
                </div>

                <div class="row mb-3">
                <label for="fullName" class="col-md-4 col-lg-3 col-form-label">Event Title</label>
                <div class="col-md-8 col-lg-9">
                    <input name="eventtitle" type="text" class="form-control" id="eventtitle" value={eventtitle} onChange={(e)=>{
                        seteventtitle(e.target.value)
                    }} />
                </div>
                </div>

                <div class="row mb-3">
                <label for="Address" class="col-md-4 col-lg-3 col-form-label">From</label>
                <div class="col-md-8 col-lg-9">
                    <input name="date" type="date" class="form-control" id="eventstartdate" value={eventstartdate}  onChange={(e)=>{
                        seteventstartdate(e.target.value)
                    }}/>
                </div>
                </div>
                <div class="row mb-3">
                <label for="Address" class="col-md-4 col-lg-3 col-form-label">To</label>
                <div class="col-md-8 col-lg-9">
                    <input name="date" type="date" class="form-control" id="eventenddate" value={eventenddate}  onChange={(e)=>{
                        seteventenddate(e.target.value)
                    }}/>
                </div>
                </div>
                <div class="text-center">
                <button type="submit" onClick={uploadevent} class="btn btn-primary">Upload Event</button>
                </div>
            </form>
            </div>
        </section>
        
      </main>
    </div>
  );
};

export default Uploadevent;
