import React from 'react';
import '../../../adminstyle/assets/vendor/bootstrap/css/bootstrap.min.css';
import '../../../adminstyle/assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../../../adminstyle/assets/vendor/boxicons/css/boxicons.min.css';
import '../../../adminstyle/assets/vendor/quill/quill.snow.css';
import '../../../adminstyle/assets/vendor/quill/quill.bubble.css';
import '../../../adminstyle/assets/vendor/remixicon/remixicon.css';
import '../../../adminstyle/assets/vendor/simple-datatables/style.css';
import '../../../adminstyle/assets/css/style.css';
import logo from '../../images/logo.png'
import { useEffect,useState } from "react";
import { Link } from "react-router-dom";
import axios from 'axios'
import { Navigate, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


const SuperAdminnav = (props) => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [togglesidenav, settogglesidenav] = useState(false);
  const { username } = useParams();
  const navigate = useNavigate();
  const contactform = {
    margin: '0 auto',
    width: '60%'
  };
  const logout = ()=>{
    localStorage.removeItem('authTokenAdmin')
    navigate('/superadminlogin')
  }
  return (
    <div className="">
      <header id="header" className="header fixed-top d-flex align-items-center">

        <div className="d-flex align-items-center justify-content-between p-3">
          <a href="/" className="logo d-flex align-items-center">
            <img src={logo} alt="" width='100' height='600'  />
          </a>
          <i className="bi bi-list toggle-sidebar-btn toggleBtn" ></i>
        </div>

        {/* <div className="search-bar">
          <form className="search-form d-flex align-items-center" method="POST" action="#">
            <input type="text" name="query" placeholder="Search" title="Enter search keyword" />
            <button type="submit" title="Search"><i className="bi bi-search"></i></button>
          </form>
        </div> */}

        <nav className="header-nav ms-auto">
          <ul className="d-flex align-items-center">

            <li className="nav-item d-block d-lg-none">
              <a className="nav-link nav-icon search-bar-toggle " href="#">
                <i className="bi bi-search"></i>
              </a>
            </li>

            {/* <li className="nav-item dropdown">

              <a className="nav-link nav-icon" href="#" data-bs-toggle="dropdown">
                <i className="bi bi-chat-left-text"></i>
                <span className="badge bg-success badge-number">
                </span>
              </a>

              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow messages">
                <li className="dropdown-header">

                  <a href="#"><span className="badge rounded-pill bg-primary p-2 ms-2">View all</span></a>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li className="message-item">
                  <a href="#">
                  </a>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li className="dropdown-footer">
                  <a href="#">Show all messages</a>
                </li>

              </ul>

            </li> */}

            <li className="nav-item dropdown pe-3">

              <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                {'Superadmin'}<img src={`${backendUrl}/images/superadmin.png`} alt="Profile" className="rounded-circle" />
                <span className="d-none d-md-block dropdown-toggle ps-2"></span>
              </a>

              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                <li className="dropdown-header">
                  <h6></h6>
                  <span>{props.username}</span>
                </li>
                {/* <li>
                  <hr className="dropdown-divider" />
                </li> */}

                {/* <li>
                  <a className="dropdown-item d-flex align-items-center" href="users-profile.html">
                    <i className="bi bi-person"></i>
                    <span>My Profile</span>
                  </a>
                </li> */}
                {/* <li>
                  <hr className="dropdown-divider" />
                </li> */}

                {/* <li>
                  <a className="dropdown-item d-flex align-items-center" href="users-profile.html">
                    <i className="bi bi-gear"></i>
                    <span>Account Settings</span>
                  </a>
                </li> */}
                <li>
                  <hr className="dropdown-divider" />
                </li>

                {/* <li>
                  <a className="dropdown-item d-flex align-items-center" href="pages-faq.html">
                    <i className="bi bi-question-circle"></i>
                    <span>Need Help?</span>
                  </a>
                </li> */}
                <li>
                  <hr className="dropdown-divider" />
                </li>

                {/* <li>
                  <a className="dropdown-item d-flex align-items-center" href="https://premium80.web-hosting.com:2096/cpsess6357192216/3rdparty/roundcube/?_task=mail&_mbox=INBOX">
                    <i className="bi bi-box-arrow-right"></i>
                    <span>View Mails</span>
                  </a>
                </li> */}
                <li>
                  <a onClick={logout} className="dropdown-item d-flex align-items-center" href="#">
                    <i className="bi bi-box-arrow-right"></i>
                    <span>Logout Out</span>
                  </a>
                </li>

              </ul>
            </li>

          </ul>
        </nav>

      </header>
    </div>
  );
};

export default SuperAdminnav;
