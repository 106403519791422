import React from 'react';
import Navbar from '../partials/navbar';
import Footer from '../partials/footer';
import { Link } from "react-router-dom";
import { Form } from 'react-bootstrap';
import { useEffect,useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import logo from '../images/logo.png'
const Resetpassword = () => {
    const [password, setpassword] = useState('');
    const { useremail } = useParams();
    const [confirmpassword, setconfirmpassword] = useState('');
  const [apiResponse, setapiResponse] = useState([]);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();
  const contactform={
    margin:'0 auto',
    width:'60%'
  }// Add this line for debugging

  useEffect(() => {
    // console.log('Checking useremail:', useremail);
    if (!useremail) {
      // console.log('No useremail, redirecting to /login');
      navigate('/login');
    } else {
      const handleCheckEmail = async () => {
        const jsonData = {
          useremail: useremail
        };
        try {
          const response = await axios.post(`${backendUrl}/checkemail`, jsonData, {
            headers: {
              'Content-Type': 'application/json',
            },
          });
          const data = response.data;
          console.log('Response from server:', data);
          // setapiResponse(data.message);
          console.log('Message from server:', data.message);
          if (data.message === 'Email Confirmed') {
            // console.log('Email is confirmed, navigating to /resetpassword');
            navigate(`/resetpassword/${useremail}`);
          } else {
            console.log('Email is not confirmed, redirecting to /login');
            navigate('/login');
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      handleCheckEmail();
    }
  }, [backendUrl, navigate, useremail, setapiResponse]);
  
  
  const handleLogin = (event)=>{
    event.preventDefault();
    
    // Submit the form data using Axios
    const jsonData = {
      useremail:useremail,
      password:password,
      confirmpassword:confirmpassword,

    };
    axios.post(`${backendUrl}/resetpassword`,jsonData,{
      headers: {
        'Content-Type': 'application/json',
      },
    } )
    .then((response) => {
      // Handle success
    //   console.log('Form submitted successfully!', response);
      setapiResponse(response.data.message)
      if(response.data.message === 'Password Reset successfully'){
        const message = 'Password Reset successfully';
        navigate(`/success/${message}`);
      }
      else{
        navigate('/login')
      }
    })
    .catch((error) => {
      // Handle error
      console.error('Form submission error:', error);
    });
  }
  return (
    <div className="">
       {/* <header>
        <Navbar/>
      </header> */}
      <div className="container w-100 mt-5">
        <form className='contactForm' style={contactform}>
        <div className='text-center'>
          <img className='mt-4 mb-5' src={logo} width='200'/>
          <h2 style={{fontWeight:'bolder',textAlign:'left'}}>Reset Password</h2>
          <p style={{textAlign:'left'}}>Welcome Back</p>
        </div>
        <p style={{ color: apiResponse !== 'Password Reset successfully' ? 'red' : '' }}>
          {apiResponse}
        </p>
        <div className="mb-3">
            <input type="password" className="form-control border-top-0 border-start-0 border-end-0 rounded-0" style={{ borderColor: 'black' }} id="password" placeholder="e.g 1234AbcD@" value={password} onChange={(e)=>{
                setpassword(e.target.value)
              }} />
            <label htmlFor="password" className="form-label">Enter A  Password</label>
          </div>
        <div className="mb-3">
            <input type="password" className="form-control border-top-0 border-start-0 border-end-0 rounded-0" style={{ borderColor: 'black' }} id="confirmpassword" placeholder="e.g 1234AbcD@" value={confirmpassword} onChange={(e)=>{
                setconfirmpassword(e.target.value)
              }} />
            <label htmlFor="password" className="form-label">Re-Enter Password</label>
          </div>

          <button type="button" className="btn btn-primary w-100 rounded-0" style={{background:'#7FA037',border:'none',borderRadius:'none'}}onClick={handleLogin} >Save</button>
          <br/>
          {/* <div className='mt-5 text-center'>
            <span>Don’t have an account ? <Link to='/signup' style={{color:'#7FA037'}}>Update Password</Link></span>
           </div> */}
          {/* <div className='mt-5 text-center'>
            <span><Link style={{textDecoration:'none',color:'#7FA037'}} to='/forgotpassword'>Forgot Password ?</Link></span>
           </div> */}
          <br/>
        </form>
      </div>
      {/* <Footer/> */}
    </div>
  );
};

export default Resetpassword;
