import React from 'react';
import 'bootstrap/dist/css/bootstrap.css'; // Import Bootstrap CSS
import 'bootstrap/dist/js/bootstrap.bundle'; // Import Bootstrap JavaScript
import instagram from '../images/svg/instagram.png'
import twitter from '../images/svg/twitter.png'
import facebook from '../images/svg/facebook.png'
import { Link } from "react-router-dom";
import youtube from '../images/svg/youtube.png'
import linkedin from '../images/svg/linkedin.png'
const Footer = () => {
    const footerHeading = {
        fontSize:'20px',
        fontWeight:'700',
    };
    const footer = {
        listStyleType: 'none',
        listStyle: 'none'
    };
    var date = new Date()
  return (
    <div>
        <div className="mt-5">
        <div className="container footer" style={footer}>
            <div className="row">
                <div className="col-4 text-right">
                <h3 style={footerHeading}>
                    Association of
                    Black Psychiatrists
                    United Kingdom
                </h3>
                </div>
                {/* <div className="col-3 text-right">
                    <li><a href="">Welfare</a></li>
                    <li><a href="">Social Media</a></li>
                    <li><a href="">CASC</a></li>
                    <li><a href="">Research</a></li>
                </div> */}
                <div className="col-3 text-right">
                    <li><Link to="/event">Event</Link></li>
                    <li><Link to='/contact'>Contact</Link></li>
                    <li><Link to='/signup'>Sign Up</Link></li>
                    <li><Link to='/login'>Log in</Link></li>
                    <li><Link to='/workgroup'>Work-group</Link></li>
                </div>
                <div className="col-4 text-left">
                    <li><a href="">+44 7785 446 112</a></li>
                    {/* <li><a href="">+234 8137 789 433</a></li> */}
                    <li><a href="">contact@blackpsych.co.uk</a></li>
                </div>
            </div>
            <div className="row">
                <div className="col-7 socialMedia">
                    <p>Follow Us</p>
                    <li><a href=""><img src={instagram}/></a></li>
                    <li><a href=""><img src={linkedin}/></a></li>
                    <li><a href=""><img src={facebook}/></a></li>
                    <li><a href=""><img src={twitter}/></a></li>
                    <li><a href=""><img src={youtube}/></a></li>
                </div>
                
            </div>
            <div className="row bottomFooter">
                <div className="col-3">
                    <p>@ {date.getFullYear()}  Association of Black Psychiatrists</p>
                </div>
                <div className="col-3">
                    <li><a href="">Terms of Service</a></li>
                </div>
                <div className="col-3">
                    <li><a href="">Privacy Policy</a></li>
                </div>
                {/* <div className="col-3">
                    <li><a href="">Cookie Policy</a></li>
                </div>
                <div className="col-3">
                    <li><a href="">Accessibility</a></li>
                </div> */}
                
            </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
