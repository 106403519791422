import React from 'react';
import '../../adminstyle/assets/vendor/bootstrap/css/bootstrap.min.css';
import '../../adminstyle/assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../../adminstyle/assets/vendor/boxicons/css/boxicons.min.css';
import '../../adminstyle/assets/vendor/quill/quill.snow.css';
import '../../adminstyle/assets/vendor/quill/quill.bubble.css';
import '../../adminstyle/assets/vendor/remixicon/remixicon.css';
import '../../adminstyle/assets/vendor/simple-datatables/style.css';
import '../../adminstyle/assets/css/style.css';
import Clientnav from './clientpartials/clientnav';
import Clientsidebar from './clientpartials/clientsidebar';
import Userregisteredeventtable from './clientpartials/tables/eventstable';
import ClientEventSlider from './clientpartials/clienteventslider'
import {useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'

const Clientevent = () => {
  const { username } = useParams();
  const [userName, setuserName] = useState([]);
  const [totalregisteredevents, settotalregisteredevents] = useState([]);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
//   const [allnewusers, setallnewusers] = useState([]);
  const navigate = useNavigate();
    
  useEffect(() => {
    // Function to fetch data from the backend API
    var authToken = localStorage.getItem('authToken')
    if(authToken==null){
      navigate('/login')
    }
    const fetchUsername = async () => {
      const jsonData=({
        username:username
      })
      try {
        const response = await axios.post('/fetchusername',jsonData,{
          headers: {
            'Content-Type': 'application/json',
          },
        }); // Replace with your API endpoint
        const data = response.data;
        console.log(data.username)
        setuserName(data.username);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    const fetchRegisteredevents = async () => {
      const jsonData={
        username:username
      }
      try {
        const response = await axios.post(`${backendUrl}/fetchalluserevents`,jsonData,{
          headers: {
            'Content-Type': 'application/json',
          },
        }); // Replace with your API endpoint
        const data = response.data;
        // console.log(data.totalnewusers)
        settotalregisteredevents(data.totalregisteredevents);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

  
    fetchRegisteredevents()
   
    fetchUsername()
  }, []);
  return (
    <div>
      <Clientnav username={username} />
      <Clientsidebar />
      <main id="main" className="main">

        <div className="pagetitle">
          <h1>Event</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
              <li className="breadcrumb-item active">Event</li>
            </ol>
          </nav>
        </div>

        <section className="section dashboard w-100">
          <div className="row w-100">
            <h2 className='eventRegisterText' style={{color:'#F4B907'}}>Hello {userName}</h2>
            <div className="col-lg-12">
              <div className="row">
                <ClientEventSlider/>
              </div>
            </div>
          </div>
        </section>
        <section className="section dashboard w-100">
          <div className="row w-100">
            <div className="col-lg-12">
              
            </div>
          </div>
          <Userregisteredeventtable data={totalregisteredevents} username={username}/>
        </section>
      </main>
    </div>
  );
};

export default Clientevent;
