import React from 'react';
import '../../../adminstyle/assets/vendor/bootstrap/css/bootstrap.min.css';
import '../../../adminstyle/assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../../../adminstyle/assets/vendor/boxicons/css/boxicons.min.css';
import '../../../adminstyle/assets/vendor/quill/quill.snow.css';
import '../../../adminstyle/assets/vendor/quill/quill.bubble.css';
import '../../../adminstyle/assets/vendor/remixicon/remixicon.css';
import '../../../adminstyle/assets/vendor/simple-datatables/style.css';
import '../../../adminstyle/assets/css/style.css';
import { useParams } from 'react-router-dom';
import { Link } from "react-router-dom";
import { useEffect,useState } from "react";
import $ from 'jquery';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';

const Clientsidebar = (props) => {
  const { username } = useParams();
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [sidebarHidden, setSidebarHidden] = useState(false);
  const [userName, setuserName] = useState([]);
  const [unreadmessage, setunreadmessages] = useState(0);
  const contactform = {
    margin: '0 auto',
    width: '60%'
  };
  useEffect(() => {
    // const readmessages = async () => {
    //   const jsonData=({
    //     readmessages:1
    //   })
    //   try {
    //     const response = await axios.post(`${backendUrl}/readmessages`,jsonData,{
    //       headers: {
    //         'Content-Type': 'application/json',
    //       },
    //     }); // Replace with your API endpoint
    //     const data = response.data;
    //     // setreadmessages(data.message);
    //   } catch (error) {
    //     console.error('Error fetching data:', error);
    //   }
    // };
    const fetchUsername = async () => {
      const jsonData=({
        username:username
      })
      try {
        const response = await axios.post(`${backendUrl}/fetchusername`,jsonData,{
          headers: {
            'Content-Type': 'application/json',
          },
        }); // Replace with your API endpoint
        const data = response.data;
        console.log(data.username)
        setuserName(data.username);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    const unreadmessages = async () => {
      const jsonData=({
        username:userName
      })
      try {
        const response = await axios.post(`${backendUrl}/unreadmessages`,jsonData,{
          headers: {
            'Content-Type': 'application/json',
          },
        }); // Replace with your API endpoint
        const data = response.data;
        setunreadmessages(data.message);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
   
    // readmessages()

    fetchUsername()
    // Use jQuery to attach a click event handler to the button
    $('.toggle-sidebar-btn').on('click', function () {
      if (sidebarHidden) {
        $('.sidebar').css('left', '0');
        $('#main').css('margin-left', '300px');
      } else {
        $('.sidebar').css('left', '-300px');
        $('#main').css('margin-left', '0');
        
      }
      // Toggle the sidebar state
      setSidebarHidden(!sidebarHidden);
    });
    const interval = setInterval(() => {
      unreadmessages()
    }, 1000);
    // Make sure to return a cleanup function
    return () => {
      clearInterval(interval);
      // Clean up any resources or event listeners if needed
      $('.toggle-sidebar-btn').off('click');
    };
  }, [sidebarHidden,unreadmessage,userName]);
  const handleLogout = () => {
    // Clear session data (token, user info) from local storage or cookies
    localStorage.removeItem('token'); // Replace 'token' with your actual session data key
  
    // Navigate to the login page (or any other page)
    navigate('/login');
  };
  return (
    <div>
      <aside id="sidebar" className="sidebar" style={{borderRight:'#7FA037 solid 1px'}}>
        <ul className="sidebar-nav" id="sidebar-nav">
          <li className="nav-item">
            <Link className="nav-link" to={`/dashboard/${username}`}><i className="bi bi-grid"></i>
              <span>Dashboard</span></Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link collapsed" to={`/dashboard/${username}/event`}><i className="bi bi-calendar4-event"></i>
              <span>Event</span></Link>
          </li>
          <Link className="nav-link collapsed" to={`/dashboard/${username}/sendmessagetoadmin/`}>
            <i className="bi bi-envelope"></i>
            <span>Messages <span className="badge badge-number">
              {(props.unreadmessage > 0 || unreadmessage > 0) ? (
                <i className="bi bi-circle-fill" style={{ color: 'red' }}></i>
              ) : null}
            </span></span>
          </Link>

          <li className="nav-item">
            <Link className="nav-link collapsed" to={`/dashboard/${username}/profile`}><i className="bi bi-person"></i>
              <span>Profile</span></Link>
          </li>
          
          <li className="nav-item">
            <a className="nav-link collapsed" data-bs-target="#tables-nav" data-bs-toggle="collapse" href="#">
            <i className="bi bi-person-workspace"></i><span>Workgroup</span>
              
            </a>
            <ul id="tables-nav" className="nav-content collapse" data-bs-parent="#sidebar-nav">
              <li>
                <Link  to={`/dashboard/${username}/workgroups`}>
                <span>My Workgroups</span>
                </Link>
                
              </li>
              
              {/* <li>
                <Link  to={`/dashboard/${username}/yourworkgroups`}>
                <span>Workgroups</span>
                </Link>
              </li> */}
       
            </ul>
          </li>
          {/* <li className="nav-item">
            <a className="nav-link collapsed" href="pages-faq.html">
              <i className="bi bi-question-circle"></i>
              <span>F.A.Q</span>
            </a>
          </li> */}
          <li className="nav-item">
            <Link className="nav-link collapsed" onClick={handleLogout} to={`/dashboard/${username}/event`}><i className="bi bi-box-arrow-right"></i>
              <span>Logout</span></Link>
          </li>
        </ul>
      </aside>

          
    </div>
  );
};

export default Clientsidebar;
