import React from 'react';
import '../../adminstyle/assets/vendor/bootstrap/css/bootstrap.min.css';
import '../../adminstyle/assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../../adminstyle/assets/vendor/boxicons/css/boxicons.min.css';
import '../../adminstyle/assets/vendor/quill/quill.snow.css';
import '../../adminstyle/assets/vendor/quill/quill.bubble.css';
import '../../adminstyle/assets/vendor/remixicon/remixicon.css';
import '../../adminstyle/assets/vendor/simple-datatables/style.css';
import { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../adminstyle/assets/css/style.css';
import logo from '../images/logo.png'
import axios from 'axios'
import { Link } from "react-router-dom";
import ReactGA from 'react-ga';
const Adminlogin = () => {
  const [adminUsername, setadminUsername] = useState('');
  const [adminPassword, setadminPassword] = useState('');
  const [apiResponse, setapiResponse] = useState([]);
  
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  useEffect(()=>{
    ReactGA.pageview(window.location.pathname);
  })
  const handleLogin = (event)=>{
    event.preventDefault();
    
    // Submit the form data using Axios
    const jsonData = JSON.stringify({
      adminPassword:adminPassword,
      adminUsername: adminUsername,
    });
    axios.post(`${backendUrl}/adminlogin`,jsonData,{
      headers: {
        'Content-Type': 'application/json',
      },
    } )
    .then((response) => {
      // Handle success
      console.log('Form submitted successfully!', response);
      setapiResponse(response.data.message)
      if(response.data.message === 'You are Logged In'){
        navigate(`/admindashboard/${response.data.username}`)
        localStorage.setItem('authTokenAdmin',response.data.username)
      }
      else{
        navigate('/adminlogin')
      }
    })
    .catch((error) => {
      // Handle error
      console.error('Form submission error:', error);
    });
  }
  return (
    <div className="">
      <div className="container">
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
              <img className='mt-4 mb-5' src={logo} width='200'/>
                <div className="d-flex justify-content-center py-4">
                  <a href="index.html" className="logo d-flex align-items-center w-auto">
                    <span className="d-none d-lg-block">Admin</span>
                  </a>
                </div>
                  
                <div className="card mb-3">
                  <div className="card-body">
                    <div className="pt-4 pb-2">
                      <h5 className="card-title text-center pb-0 fs-4">Login</h5>
                      <p className="text-center small">Enter your username & password to login</p>
                    </div>
                    <p style={{ color: apiResponse !== 'You are Logged In' ? 'red' : '' }}>
                    {apiResponse}
                  </p>
                    <div className="invalid-feedback"></div>
                    <div className="error-message"></div>
                    <div className="col-12">
                      <label htmlFor="adminUsername" className="form-label">Username</label>
                      <div className="input-group has-validation" >
                        <span className="input-group-text" id="inputGroupPrepend">@</span>
                        <input type="text" name="username" className="form-control" id="adminUsername" required value={adminUsername} onChange={(e)=>{
                          setadminUsername(e.target.value)
                        }} />
                      </div>
                    </div>
                    <br/>
                    <div className="col-12">
                      <label htmlFor="adminPassword" className="form-label">Password</label>
                      <input type="password" name="password" className="form-control" id="adminPassword" required value={adminPassword} onChange={(e)=>{
                        setadminPassword(e.target.value)
                      }} />
                    </div>

                    {/* <div className="col-12">
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" name="remember" value="true" id="rememberMe" />
                        <label className="form-check-label" htmlFor="rememberMe">Remember me</label>
                      </div>
                    </div> */}
                    <br/>
                    <div className="col-12">
                      <button id="adLoginButton" className="btn btn-primary w-100" type="button" onClick={handleLogin}>Login</button>
                    </div>

                    {/* <div className="col-12">
                      <p className="small mb-0">Don't have an account? <a href="pages-register.html">Create an account</a></p>
                    </div> */}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Adminlogin;
