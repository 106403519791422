import React from 'react';
import '../../adminstyle/assets/vendor/bootstrap/css/bootstrap.min.css';
import '../../adminstyle/assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../../adminstyle/assets/vendor/boxicons/css/boxicons.min.css';
import '../../adminstyle/assets/vendor/quill/quill.snow.css';
import '../../adminstyle/assets/vendor/quill/quill.bubble.css';
import '../../adminstyle/assets/vendor/remixicon/remixicon.css';
import '../../adminstyle/assets/vendor/simple-datatables/style.css';
import '../../adminstyle/assets/css/style.css';
import Clientnav from './clientpartials/clientnav';
import Clientsidebar from './clientpartials/clientsidebar';
import {useState, useEffect } from 'react';
import { Document, Page } from 'react-pdf';
import { Link,useLocation } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import videoPoster from '../images/videoposter.png'
import axios from 'axios'

const Forum = () => {
  const { username,workgroup } = useParams();
  const [message, setmessage] = useState('');
  const [attachment, setattachment] = useState(null);
  const [allnewusers, setallnewusers] = useState([]);
  const [apiResponse, setapiResponse] = useState([]);
  const [userName, setuserName] = useState([]);
  const [forummessage, setforummessage] = useState([]);
  const [displaynone, setdisplaynone] = useState('none');
  const [PreviewUrl, setPreviewUrl] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  var chatBlock = {
    maxHeight: '280px',
    paddingBottom:'500px',
    background: '#EBF9FF',
    border:'#CDF1FF solid 1px',
    overflow:'auto'
    // background: '#FCFCFC'
  }
  var chatDropsUser = {
    padding:'5px',
    maxWidth:'500px',
    borderRadius:'6px',
    background: '#FFFFFF',
    border:'none',
    color:'#3D3C3E',
    textAlign:'left',
    wordWrap: 'break-word'
   
  }
  var chatDropsUserAdmin = {
    padding:'5px',
    maxWidth:'500px',
    borderRadius:'6px',
    background: '#7FA037',
    border:'none',
    color:'#ffffff',
    textAlign:'left',
    wordWrap: 'break-word'
  }
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if(file){
      setattachment(file);
      // const fileUrl = URL.createObjectURL(file);
      // setPreviewUrl(fileUrl);
      // const fileName = file.name;
      // console.log(fileUrl);
    }

  };
  const handleFocus = () => {
    setIsFocused(true);
  };
  const handleBlur = () => {
    setIsFocused(false);
  };
  useEffect(() => {
    var authToken = localStorage.getItem('authToken')
    if(authToken==null){
      navigate('/login')
    }
    const fetchUsername = async () => {
        const jsonData=({
          username:username
        })
        try {
          const response = await axios.post(`${backendUrl}/fetchusername`,jsonData,{
            headers: {
              'Content-Type': 'application/json',
            },
          }); // Replace with your API endpoint
          const data = response.data;
          console.log(data.username)
          setuserName(data.username);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    };
    const fetchMessages = async () => {
        const jsonData=({
          workgroup:workgroup
        })
        try {
          const response = await axios.post(`${backendUrl}/fetchForumMessages`,jsonData,{
            headers: {
              'Content-Type': 'application/json',
            },
          }); // Replace with your API endpoint
          const data = response.data;
          if (data && data.message) {
            console.log(`${backendUrl}`+data.message[0].useravatar)
            if (Array.isArray(data.message)) {
              setforummessage(data.message);
            } else {
              console.error('Data.message is not an array:', data.message);
            }
          } else {
            console.error('Response data does not contain a message field:', data);
          }
          
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    };
    const intervalId = setInterval(() => {
      fetchMessages();
    }, 100);
  
    fetchUsername()
    // Don't forget to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [workgroup]);
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmitMessage(e);
    }
  };
  const handleSubmitMessage = async ()=>{
    
    if(message===""){
          if (!attachment) {
            // setMessage('Please select a file.');
            return;
          }
        
          var status='client'
          const formData = new FormData();
          formData.append('file', attachment);
          formData.append('username', userName);
          formData.append('workgroup', workgroup);
          formData.append('status', status);
          console.log(formData)
          try {
            const response = await axios.post(`${backendUrl}/forumattachment`,formData,{
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }); // Replace with your API endpoint

            const data = response.data;
            if(data){
              setattachment("");
            }
            console.log(data)
            
          } catch (error) {
            console.error('Error fetching data:', error);
          }
    }
    else{
      const jsonData={
        username:userName,
        workgroup:workgroup,
        message:message,
        status:'client'
      }
      try {
        const response = await axios.post(`${backendUrl}/forum`,jsonData,{
          headers: {
            'Content-Type': 'application/json',
          },
        }); // Replace with your API endpoint
        const data = response.data;
        console.log(data)
        if (data && data.message) {
          // Update the state with the message or messages you want to render.
          setapiResponse(data.message); // Assuming data.message is a string or array of React elements.
          setdisplaynone('block');
          setmessage("")
          setTimeout(() => {
            setdisplaynone('none');
          }, 5000);
        } else {
          console.error('Response data does not contain a message field:', data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  }
  return (
    <div>
      <Clientnav username={username} />
      <Clientsidebar />
      <main id="main" className="main">

        <div className="pagetitle">
          <h1>Workgroup Forum</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
              <li className="breadcrumb-item active">Forum</li>
            </ol>
          </nav>
        </div>

        <section className="section dashboard w-100">
          <div className="row" style={{}}>
             <h4 >{workgroup}</h4>
          </div>
          {/* <div style={{}}>  
            <p id='alert' style={{ color: apiResponse.message !== `Message Sent` ? 'white' : '',position:'fixed',zIndex: 1,background:apiResponse.message !==`Message Sent`?'red':'none',padding:apiResponse.message !==`Message Sent`?'5px':'none',borderRadius:apiResponse.message !==`Message Sent`?'4px':'0px',display:displaynone }}>
                    {apiResponse.message}
                </p>
          </div> */}
          <div className="row chatBlock" style={chatBlock}>
            {forummessage.length === 0 ?(<div className="text-center" style={{marginTop:'150px'}}>
              <h3>No discussion in this forum</h3>
              </div>):(forummessage.map((element, index) => (
              <div key={index}>
                {element.length ===0?(<div className="container d-flex justify-content-center align-items-center" style={{ minHeight: "80vh" }}>
                  <div className="text-center">
                    <h3>No chats in this forum</h3>
                  </div>
                </div>):(element.status === 'Admin' ? (
                  <div className='chatDropsAdminCover' style={{ padding: '20px' }}>
                    <div className='row'>
                      
                      <div className='col-md-11'>
                        <div className='w-100'>
                          <h6 className='w-100 text-right' style={{ textAlign: 'right', }}>
                            {username}, <span style={{ fontSize: '10px' }}>{element.time} {element.date}</span>
                          </h6>
                        </div>
                        {element.filename ? (
                          <a href={`${backendUrl}`+element.chatmessage}
                          download={`${backendUrl}`+element.chatmessage} style={{}}>
                            {element.filename.includes('.png') || element.filename.includes('.jpeg') || element.filename.includes('.jpg') || element.filename.includes('.webp') || element.filename.includes('.gif') ? (
                              <span>
                              <div className="card w-25" style={{border:'solid 1px #7A7979',borderRadius:'10px',background:'#F7F7F7',float:'right' }}>
                                <img style={{width:'100%',borderRadius:'10px'}} src={`${backendUrl}`+element.chatmessage}/>
                                <div className="card-body">
                                  <h5 className="card-title"><i className="bi bi-images"></i> {element.filename}</h5>
                                </div>
                              </div>
                            </span>
                              
                            ) : (
                              
                              <span>
                                <div className="card w-25" style={{background:'#F7F7F7',border:'solid 1px #7A7979',borderRadius:'10px',float:'right' }}>
                                    <div className='' >
                                    <i style={{fontSize:'40px',}} className="bi bi-paperclip text-center"></i> 
                                    </div>
                                    <div className="card-body">
                                    <h5 className="card-title"><i className="bi bi-file-earmark-pdf"></i> {element.filename}</h5>
                                    </div>
                                </div>
                                
                              </span>
                            )}
                          </a>
                        ) : (
                          <div style={{float:'right'}}>
                            <p style={chatDropsUserAdmin}>{String(element.chatmessage)}</p>
                          </div>
                        )}
                      </div>
                      <div className='col-md-1' style={{float:'right'}}>
                        <img src={`${backendUrl}` + element.useravatar} style={{ borderRadius: '50%', width: '50px', height: '50px',float:'right' }} />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className='chatDropsUserCover' style={{ padding: '20px' }}>
                    <div className='row'>
                      <div className='col-md-1'>
                        <img src={`${backendUrl}` + element.useravatar} style={{ borderRadius: '50%', width: '50px', height: '50px' }} />
                      </div>
                      <div className='col-md-11 text-left'>
                        <h6>{element.username} , <span style={{ fontSize: '10px' }}>{element.time} {element.date}</span></h6>

                        {element.filename ? (
                          <a href={`${backendUrl}`+element.chatmessage}
                          download={`${backendUrl}`+element.chatmessage} style={{}}>
                            {element.filename.includes('.png') || element.filename.includes('.jpeg') || element.filename.includes('.jpg') || element.filename.includes('.webp') || element.filename.includes('.gif') ? (
                              <span>
                              <div className="card w-25" style={{border:'solid 1px #7A7979',borderRadius:'10px',background:'#F7F7F7',float:'left',textAlign:'right'}}>
                                <img style={{width:'100%',borderRadius:'10px'}} src={`${backendUrl}`+element.chatmessage}/>
                                <div className="card-body">
                                  <h5 className="card-title"><i className="bi bi-images"></i> {element.filename}</h5>
                                </div>
                              </div>
                            </span>
                              
                            ) : (
                              
                              <span>
                                <div className="card w-25" style={{background:'#F7F7F7',border:'solid 1px #7A7979',borderRadius:'10px',float:'left'}}>
                                    <div className='' >
                                    <i style={{fontSize:'40px',}} className="bi bi-paperclip text-center"></i> 
                                    </div>
                                    <div className="card-body">
                                    <h5 className="card-title"><i className="bi bi-file-earmark-pdf"></i> {element.filename}</h5>
                                    </div>
                                </div>
                                
                              </span>
                            )}
                          </a>
                        ) : (
                          <p style={chatDropsUser}>{String(element.chatmessage)}</p>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
                
              </div>
            )))}
            
       
          </div>
          <div className="row attachment" style={{border: '1px solid #7FA037',maxHeight:'40px',paddingBottom:'40px'}}>
                <div className='col-md-2 inputBox'>
                  <label htmlFor='attachment'><i className='bi bi-paperclip text-center' style={{color:'gray',fontSize:'20px',}}></i></label>
                    <input className='form-control col-md-12 rounded-0 border-0' accept="*" id='attachment' style={{display:'none'}} type={'file'} onChange={handleFileChange} />
                </div>
                <div className='col-md-9 inputBox'>
                    {attachment?(<a className='w-100 text-center' style={{color:'gray',fontSize:'20px',background:'#F6F6F6',}} href={URL.createObjectURL(attachment)}>
                    {attachment.name.includes('.pdf') ? (
                      <span>
                        <i style={{color:'gray',fontSize:'20px'}} className="bi bi-file-earmark-pdf"></i>
                        {attachment.name}
                      </span>
                    ) : (
                      <span>
                        <i style={{color:'gray',fontSize:'20px'}} className="bi bi-images"></i>
                        {attachment.name}
                      </span>
                    )}

                    </a>):(<input className='form-control col-md-12 rounded-0 border-0' type={'text'} placeholder={'Type your message here...'} style={{background:'none',wordWrap: 'break-word',width:'100%',outline: isFocused ? 'none' : '',overflow:'break'}}  value={message} onChange={(e)=>{
                      
                      setmessage(e.target.value)
                    }} onKeyDown={handleKeyPress} onFocus={handleFocus} onBlur={handleBlur} />)}
                </div>
                <div className='col-md-1'>
                    <button onClick={handleSubmitMessage} className='rounded-0 w-100' style={{background:'none',border:'none',color:'#7FA037',fontSize:'20px',padding:'5px',borderLeft:'#7FA037 solid 1px'}}><i className="bi bi-send"></i></button>
                </div>
          </div>
         
        </section>
        {/* <div className="modal fade" id="basicModal" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Create Events</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <p id="eventAlert" style={{ color: "red", fontSize: "18px", display: "none" }}>Hello</p>

              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary" id="saveEvent">Save</button>
              </div>
            </div>
          </div>
        </div> */}
      </main>
    </div>
  );
};

export default Forum;
