import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Slide1 from "../images/Eventsimages/Group 258.png";
import Slide2 from "../images/Eventsimages/event1.png";
import Slide3 from "../images/Eventsimages/event2.png";
import Slide4 from "../images/Eventsimages/event3.png";
import arrow2 from "../images/svg/arrow2.png";

const EventSlider = () => {
  const [loading, setLoading] = useState(true); // Add loading state
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();
  const { username } = useParams();
  const [userName, setuserName] = useState([]);
  const [events, setEvents] = useState([]);
  useEffect(() => {
    setInterval(() => {
      axios
        .get(`${backendUrl}/getevents`)
        .then((response) => {
          if (Array.isArray(response.data) && response.data.length > 0) {
            setEvents(response.data);
          } else {
            setEvents([]); // Set events to an empty array when no events are returned
          }
          setLoading(false); // Set loading state to false
        })
        .catch((error) => {
          console.error("Error fetching events:", error);
          setLoading(false); // Set loading state to false in case of error
        });
    }, 1000);
  }, []);
  var authToken = localStorage.getItem("authToken");
  if (authToken == null) {
    // navigate('/login')
  } else {
    const fetchUsername = async () => {
      const jsonData = {
        username: username,
      };
      try {
        const response = await axios.post(
          `${backendUrl}/fetchusername`,
          jsonData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        ); // Replace with your API endpoint
        const data = response.data;
        console.log(data.username);
        setuserName(data.username);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchUsername();
  }

  const newsLetterButtonStyle = {
    color: "#000",
    border: "none",
    marginTop: "10px",
    background: "#F4B907",
    padding: "4px",
    width: "70px",
  };
  return (
    <div>
      <div className="eventSliders">
        {events.length === 0 ? (
          <Carousel style={{ width: "100%" }}>
            <Carousel.Item>
              <img
                src={Slide2}
                alt={""}
                style={{ width: "100%", height: "60%" }}
              />
              <Carousel.Caption>
                {/* Uncomment these lines if you want to display event title and dates */}
                {/* <h3>{event.eventtitle}</h3>
                <p>{event.eventstartdate} - {event.eventenddate}</p> */}
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                src={Slide3}
                alt={""}
                style={{ width: "100%", height: "60%" }}
              />
              <Carousel.Caption>
                {/* Uncomment these lines if you want to display event title and dates */}
                {/* <h3>{event.eventtitle}</h3>
                <p>{event.eventstartdate} - {event.eventenddate}</p> */}
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                src={Slide4}
                alt={""}
                style={{ width: "100%", height: "60%" }}
              />
              <Carousel.Caption>
                {/* Uncomment these lines if you want to display event title and dates */}
                {/* <h3>{event.eventtitle}</h3>
                <p>{event.eventstartdate} - {event.eventenddate}</p> */}
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        ) : (
          <Carousel style={{ width: "100%", height: "600px" }}>
            {events.map((event) => (
              <Carousel.Item
                key={event.id}
                style={{ width: "100%", height: "600px" }}
              >
                <img
                  src={`${backendUrl}` + event.eventbanner}
                  style={{ width: "100%", height: "600px" }}
                  alt={`Event ${event.id}`}
                />
                <Carousel.Caption>
                  {/* Uncomment these lines if you want to display event title and dates */}
                  {/* <h3>{event.eventtitle}</h3>
                  <p>{event.eventstartdate} - {event.eventenddate}</p> */}
                </Carousel.Caption>
              </Carousel.Item>
            ))}
          </Carousel>
        )}

        <div className="row eventRegisterText" style={{ width: "100%" }}>
          <div className="col-11">
            <p>Register Now</p>
          </div>
          <div className="col-1 text-right button">
            <button style={newsLetterButtonStyle}>
              <Link
                style={{ textDecoration: "none" }}
                to={
                  authToken == null
                    ? `/registerevent`
                    : `/registerevent/${userName}`
                }
              >
                <img src={arrow2} width="30" alt="Arrow" />
              </Link>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventSlider;
