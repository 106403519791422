import React from "react";
import arrowdown from '../images/svg/arrowdown.png'
import { useEffect,useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
function NewsLetter(props) {
  const navigate = useNavigate();
  const newLetterHeading = {
    fontSize:'30px',
    fontWeight:'700',
  };
  const newLetterHeadingParagraph = {
    color:'#fff',
    marginTop:'-40px'
  };
  const newsLetterInput = {
    color:'#fff',
    borderTop:'none',
    borderLeft:'none',
    borderRight:'none',
    borderBottom:'solid 1px #F4B907',
    marginTop:'10px',
    background:'none',
    width:'100%',
    padding:'6px'
  };
  const newsLetterButton = {
    color:'#000',
    border:'none',
    marginTop:'10px',
    background:'#F4B907',
    padding:'6px'
  };
  const arrowDownStyle = {
    float:'right',
    textAlign:'right'
  };
  const arrowImage = {
    position:'relative',
    top:'0',
    marginTop:'-60px'
  };
  const [email, setemail] = useState('');
  const [apiResponse, setapiResponse] = useState([]);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const handleNewsLetter = (event)=>{
    event.preventDefault();
    
    // Submit the form data using Axios
    const jsonData = JSON.stringify({
      email: email,
    });
    axios.post(`${backendUrl}/newsletter`,jsonData,{
      headers: {
        'Content-Type': 'application/json',
      },
    } )
    .then((response) => {
      // Handle success
      // console.log('Thanks for subscribing to our news letter', response);
      setapiResponse(response.data)
      console.log(response.data)

      if(response.data === 'Thanks for subscribing to our news letter'){
        const message = 'Thanks for subscribing to our news letter'
        navigate(`/mail/${message}`)
      }
      else{
        navigate(`/`)
      }
    })
    .catch((error) => {
      // Handle error
      console.error('Form submission error:', error);
    });
  }
  return(
    <div className="mt-5">
        <div className="ourgoals newsLetter">
            <div className="container mt-5">
                <div className="text-left custom-font-class mb-5">
                <h3 style={newLetterHeading}>Our NewsLetter</h3>
                </div>
            <div className="row container mt-5">
                <div className="col-8" style={newLetterHeadingParagraph}>
                <p>Get new updates from us on regarding the development of Black Psychiatrists through education, training, mentoring and networking.</p>
                </div>
                <div className="col-4" style={arrowDownStyle}>
                <img className="dash" width='30' style={arrowImage} src={arrowdown}
                alt="Slide 1" />
                </div>
                <div className="row">
                  <p style={{ color: apiResponse !== 'Thanks for subscribing to our news letter' ? 'red' : '' }}>
                    {apiResponse}
                  </p>
                <div className="col-8">
                    <input type='email' style={newsLetterInput} value={email} onChange={(e)=>{
                      setemail(e.target.value)
                    }} placeholder="Input your email address"/>
                </div>
                <div className="col-4">
                    <button onClick={handleNewsLetter} style={newsLetterButton}>Subscribe</button>
                </div>
                </div>
            </div>
            </div>
        </div>
    </div>
    );
}

export default NewsLetter;