import React from 'react';
import '../../adminstyle/assets/vendor/bootstrap/css/bootstrap.min.css';
import '../../adminstyle/assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../../adminstyle/assets/vendor/boxicons/css/boxicons.min.css';
import '../../adminstyle/assets/vendor/quill/quill.snow.css';
import '../../adminstyle/assets/vendor/quill/quill.bubble.css';
import '../../adminstyle/assets/vendor/remixicon/remixicon.css';
import '../../adminstyle/assets/vendor/simple-datatables/style.css';
import '../../adminstyle/assets/css/style.css';
import Clientnav from './clientpartials/clientnav';
import Clientsidebar from './clientpartials/clientsidebar';
import {useState, useEffect } from 'react';
import { Link,useLocation } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'

const ClientDashbord = () => {
  const { username } = useParams();
  const [newusers, setnewusers] = useState([]);
  const [allnewusers, setallnewusers] = useState([]);
  const [registerevents, setregisterevents] = useState([]);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();
  useEffect(() => {
    var authToken = localStorage.getItem('authToken')
    if(authToken==null){
      navigate('/login')
    }
    const fullURL = window.location.href;
    // Function to fetch data from the backend API
    const fetchNoOfUser = async () => {
      try {
        const response = await axios.get(`${backendUrl}/allusers`); // Replace with your API endpoint
        const jsonData = response.data;
        console.log(jsonData.totalusers)
        setnewusers(jsonData.totalusers);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    const fetchNewfUser = async () => {
      const jsonData={
        viewAllusers:1
      }
      try {
        const response = await axios.post(`${backendUrl}/alluserslist`,jsonData,{
          headers: {
            'Content-Type': 'application/json',
          },
        }); // Replace with your API endpoint
        const data = response.data;
        // console.log(data.totalnewusers)
        setallnewusers(data.totalnewusers);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    const userEvents = async () => {
      const jsonData={
        username:username
      }
      try {
        const response = await axios.post(`${backendUrl}/userevents`,jsonData,{
          headers: {
            'Content-Type': 'application/json',
          },
        }); // Replace with your API endpoint
        const data = response.data;
        // console.log(data.totalnewusers)
        setregisterevents(data.totalregisteredevents);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const interval = setInterval(() => {
      fetchNoOfUser();
      fetchNewfUser()
      userEvents()
    }, 1000);

    // Clear the interval when the component unmounts to avoid memory leaks
    return () => clearInterval(interval);
  }, []);
  return (
    <div>
      <Clientnav username={username} />
      <Clientsidebar />
      <main id="main" className="main">

        <div className="pagetitle">
          <h1>Dashboard</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
              <li className="breadcrumb-item active">Dashboard</li>
            </ol>
          </nav>
        </div>

        <section className="section dashboard w-100">
          <div className="row w-100">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-md-4">
                  <div className="card info-card sales-card">
                    {/* <div className="filter">
                      <a className="icon" href="#" data-bs-toggle="dropdown"><i className="bi bi-three-dots"></i></a>
                      <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                        <li className="dropdown-header text-start">
                          <h6>Filter</h6>
                        </li>

                        <li><a className="dropdown-item" href="#">Today</a></li>
                        <li><a className="dropdown-item" href="#">This Month</a></li>
                        <li><a className="dropdown-item" href="#">This Year</a></li>
                      </ul>
                    </div> */}

                    <div className="card-body">
                      <h5 className="card-title">Total Registered Members </h5>

                      <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i className="bi bi-people"></i>
                        </div>
                        <div className="ps-3">
                          <h6>{newusers}</h6>
                          {/* <span className="text-success small pt-1 fw-bold">12%</span> <span className="text-muted small pt-2 ps-1">increase</span> */}

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card info-card sales-card">

                    {/* <div className="filter">
                      <a className="icon" href="#" data-bs-toggle="dropdown"><i className="bi bi-three-dots"></i></a>
                      <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                        <li className="dropdown-header text-start">
                          <h6>Filter</h6>
                        </li>

                        <li><a className="dropdown-item" href="#">Today</a></li>
                        <li><a className="dropdown-item" href="#">This Month</a></li>
                        <li><a className="dropdown-item" href="#">This Year</a></li>
                      </ul>
                    </div> */}

                    <div className="card-body">
                      <h5 className="card-title"> No of Registered events </h5>

                      <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i className="bi bi-people"></i>
                        </div>
                        <div className="ps-3">
                          <h6>{registerevents?registerevents: 0}</h6>
                          {/* <span className="text-success small pt-1 fw-bold">12%</span> <span className="text-muted small pt-2 ps-1">increase</span> */}

                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card info-card sales-card">

                    {/* <div className="filter">
                      <a className="icon" href="#" data-bs-toggle="dropdown"><i className="bi bi-three-dots"></i></a>
                      <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                        <li className="dropdown-header text-start">
                          <h6>Filter</h6>
                        </li>

                        <li><a className="dropdown-item" href="#">Today</a></li>
                        <li><a className="dropdown-item" href="#">This Month</a></li>
                        <li><a className="dropdown-item" href="#">This Year</a></li>
                      </ul>
                    </div> */}

                    <div className="card-body">
                      <h5 className="card-title">No of tutorial </h5>

                      <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i className="bi bi-people"></i>
                        </div>
                        <div className="ps-3">
                          <h6>0</h6>
                          {/* <span className="text-success small pt-1 fw-bold">12%</span> <span className="text-muted small pt-2 ps-1">increase</span> */}

                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card info-card sales-card">

                    {/* <div className="filter">
                      <a className="icon" href="#" data-bs-toggle="dropdown"><i className="bi bi-three-dots"></i></a>
                      <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                        <li className="dropdown-header text-start">
                          <h6>Filter</h6>
                        </li>

                        <li><a className="dropdown-item" href="#">Today</a></li>
                        <li><a className="dropdown-item" href="#">This Month</a></li>
                        <li><a className="dropdown-item" href="#">This Year</a></li>
                      </ul>
                    </div> */}

                    <div className="card-body">
                      <h5 className="card-title">No of mentors </h5>

                      <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i className="bi bi-people"></i>
                        </div>
                        <div className="ps-3">
                          <h6>0</h6>
                          {/* <span className="text-success small pt-1 fw-bold">12%</span> <span className="text-muted small pt-2 ps-1">increase</span> */}

                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
         
        </section>
        {/* <div className="modal fade" id="basicModal" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Create Events</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <p id="eventAlert" style={{ color: "red", fontSize: "18px", display: "none" }}>Hello</p>

              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary" id="saveEvent">Save</button>
              </div>
            </div>
          </div>
        </div> */}
      </main>
    </div>
  );
};

export default ClientDashbord;
