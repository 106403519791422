import React, { useState, useEffect } from 'react';
import '../../adminstyle/assets/vendor/bootstrap/css/bootstrap.min.css';
import '../../adminstyle/assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../../adminstyle/assets/vendor/boxicons/css/boxicons.min.css';
import '../../adminstyle/assets/vendor/quill/quill.snow.css';
import '../../adminstyle/assets/vendor/quill/quill.bubble.css';
import '../../adminstyle/assets/vendor/remixicon/remixicon.css';
import '../../adminstyle/assets/vendor/simple-datatables/style.css';
import '../../adminstyle/assets/css/style.css';
import SuperAdminnav from './superadminpartials/superadminnav'
import SuperAdminSidebar from './superadminpartials/superadminsidebar';
import { Link, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const SuperEdituser = () => {
  const { username,userid,edit } = useParams();
  const [fullname, setFullname] = useState('');
  const [phonenumber, setPhonenumber] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [membershiptype, setMembershiptype] = useState('');
  const [membershipfee, setmembershipfee] = useState('');
  const [apiResponse, setapiResponse] = useState([]);
  const [selectionMade, setSelectionMade] = useState(false);
  const [isapproved, setisapproved] = useState('');
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();
  const approveUser = async (event) => {
    event.preventDefault()
    if (!selectionMade){
        setapiResponse("Select Membership Fee")
    }
    else{

        try {
    
            const approvemember = await axios.post(`${backendUrl}/memberapproval`, {
                 userid: userid,
                 membershipfee:membershipfee
                }, 
                {
                headers: {
                'Content-Type': 'application/json',
                },
            });
    
            const userData = approvemember.data;
            setapiResponse(userData.message)
            console.log(userData.message)
            if(userData.message==="User Approved Successfully"){
                const message= 'User Activated Successfully'
                navigate(`/success/${message}`)
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
  };
  

  useEffect(() => {
    var AdminauthToken = localStorage.getItem('authTokenAdmin')
      if(AdminauthToken==null){
        navigate('/superadminlogin')
      }
    const checkapproved = async () => {
        try {

            const checkifapproved = await axios.post(`${backendUrl}/checkapproved`, { userid: userid }, {
                headers: {
                'Content-Type': 'application/json',
                },
            });

            const userData = checkifapproved.data;
            setisapproved(Number(userData.message))
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    const fetchUserData = async () => {
        try {

            const profileResponse = await axios.post(`${backendUrl}/memberprofiledetails`, { userid: userid }, {
                headers: {
                'Content-Type': 'application/json',
                },
            });

            const userData = profileResponse.data.result[0];
            setFullname(userData.fullname);
            setPhonenumber(userData.phonenumber);
            setAddress(userData.address);
            setEmail(userData.email);
            setMembershiptype(userData.membershiptype);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchUserData();
    checkapproved()
  }, [username]);

  return (
    <div>
      <SuperAdminnav username={username} />
      <SuperAdminSidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>{userid?'Activate User':'Dasboard'}</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
              <li className="breadcrumb-item active">Dashboard</li>
            </ol>
          </nav>
        </div>

        <section className="section">
          <div className="row w-100">
          <p style={{ color: apiResponse !== 'User Approved Successfully' ? 'red' : '' }}>
          {apiResponse}
        </p>
          <form>
                  <div className="row mb-3">
                    <label htmlFor="fullName" className="col-md-4 col-lg-3 col-form-label">Full Name</label>
                    <div className="col-md-8 col-lg-9">
                      <input disabled name="fullName" type="text" className="form-control" id="fullName" value={fullname} onChange={(e) => setFullname(e.target.value)} />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <label htmlFor="Job" className="col-md-4 col-lg-3 col-form-label">Membership Type</label>
                    <div className="col-md-8 col-lg-9">
                      <input disabled name="address" type="text" className="form-control" id="Address" value={membershiptype} onChange={(e) => setMembershiptype(e.target.value)} />
                    </div>
                  </div>

                  {/* Rest of the input fields */}
                  <div className="row mb-3">
                    <label htmlFor="Address" className="col-md-4 col-lg-3 col-form-label">Address</label>
                    <div className="col-md-8 col-lg-9">
                      <input disabled name="address" type="text" className="form-control" id="Address" value={address} onChange={(e) => setAddress(e.target.value)} />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <label htmlFor="Phone" className="col-md-4 col-lg-3 col-form-label">Phone</label>
                    <div className="col-md-8 col-lg-9">
                      <input disabled name="phone" type="text" className="form-control" id="Phone" value={phonenumber} onChange={(e) => setPhonenumber(e.target.value)} />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <label htmlFor="Email" className="col-md-4 col-lg-3 col-form-label">Email</label>
                    <div className="col-md-8 col-lg-9">
                      <input disabled name="email" type="email" className="form-control" id="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                  </div>
                  
                  <div className="row mb-3">
                    <label htmlFor="Email" className="col-md-4 col-lg-3 col-form-label">Select Membership Fee</label>
                    {isapproved ===1?<div className="col-md-8 col-lg-9">Activated</div>:<div className="col-md-8 col-lg-9">
                    <select className="form-control" id='membershiptype' value={membershipfee} onChange={(e)=>{
                        
                        setmembershipfee(e.target.value)
                        setSelectionMade(true);
                    }}>
                        <option value={'Select Fee'} >Select Fee</option>
                        <option value={'£100'}>Consultant - £100</option>
                        <option value={'£70'}>SAS doctor - £70</option>
                        <option value={'£50'}>Trainee - £50</option>
                        <option value={'£50'}>Retired Member - £50</option>
                        <option value={'£50'}>Affiliate Member - £50</option>
                        <option value={'£20'}>FYI/FY2 - £20</option>
                        <option value={'£0'}>Medical Student - Free</option>
                    </select>
                    </div>}
                    
                  </div>

                  <div className="text-center">
                    {isapproved ===1?'':<button type="button" onClick={approveUser} className="btn btn-primary">
                      Activate User
                    </button>}
                    
                  </div>
                </form>
          </div>
        </section>
      </main>
    </div>
  );
};

export default SuperEdituser;
