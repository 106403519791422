import React from 'react';
import '../../adminstyle/assets/vendor/bootstrap/css/bootstrap.min.css';
import '../../adminstyle/assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../../adminstyle/assets/vendor/boxicons/css/boxicons.min.css';
import '../../adminstyle/assets/vendor/quill/quill.snow.css';
import '../../adminstyle/assets/vendor/quill/quill.bubble.css';
import '../../adminstyle/assets/vendor/remixicon/remixicon.css';
import '../../adminstyle/assets/vendor/simple-datatables/style.css';
import '../../adminstyle/assets/css/style.css';
import Academicswork from '../images/academicswork.png'
import Mentorship from '../images/mentorship.png'
import Research from '../images/research.png'
import Editorial from '../images/editorial.png'
import Arts from '../images/arts.png'
import Clientnav from './clientpartials/clientnav';
import Clientsidebar from './clientpartials/clientsidebar';
// import ClientEventSlider from './clientpartials/clienteventslider'
import arrow2 from '../images/svg/arrow2.png'
import {useState, useEffect } from 'react';
import { Link, Navigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'

const Selectworkgroup = () => {
  const { username } = useParams();
  const [userName, setuserName] = useState([]);
  const [setjoinedworkgroup, setsetjoinedworkgroup] = useState([]);
  const [castutorial, setcastutorial] = useState([]);
  const [artculture, setartculture] = useState([]);
  const [mentorship, setmentorship] = useState([]);
  const [research, setresearch] = useState([]);
  const [editorial, seteditorial] = useState([]);
  const [apiResponse, setapiResponse] = useState([]);
  const [displaynone, setdisplaynone] = useState('none');
  const [show, setshow] = useState('none');
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
    //   const [allnewusers, setallnewusers] = useState([]);
    const workGrpContent = {
        position:'relative',
        top:'150px',
        marginLeft:'40px'
    };
    const workGrpContent2= {
        position:'relative',
        top:'150px',
        marginLeft:'40px',
        color:'#fff',
    };

    const workGrpContentAnchor = {
        textDecoration: 'none',
        color: 'blue',
        fontWeight: 'bold',
        fontSize: '16px',
        color:'#fff',
        background:'#7FA037',
        padding:'10px'
    };

    var defaultValue='';
    useEffect(() => {
      // Function to fetch data from the backend API
      var authToken = localStorage.getItem('authToken')
      if(authToken==null){
        navigate('/login')
      }
      setdisplaynone('none')
      const fetchUsername = async () => {
        try {
          const response = await axios.post(`${backendUrl}/fetchusername`, {
            username: username,
          }, {
            headers: {
              'Content-Type': 'application/json',
            },
          });
  
          const data = response.data;
          console.log(data.username);
          setuserName(data.username || ''); // Ensure username is not null or undefined
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      const checkjoinedgroup = async () => {
        const jsonData=({
          username:userName
        })
        try {
          const response = await axios.post(`${backendUrl}/checkjoinedgroup`,jsonData,{
            headers: {
              'Content-Type': 'application/json',
            },
          }); // Replace with your API endpoint
          const data = response.data;
         // eslint-disable-next-line array-callback-return
         data.message.map((item)=>{
            console.log(item.workgroup)
            if(item.workgroup ==="CASC Tutorial"){
              setcastutorial(item.workgroup)
            }
            if(item.workgroup ==="Arts & Culture"){
              setartculture(item.workgroup)
            }
            if(item.workgroup ==="Mentorship & Welfare"){
              setmentorship(item.workgroup)
            }
            if(item.workgroup ==="Research"){
              setresearch(item.workgroup)
            }
            if(item.workgroup ==="Editorial"){
              seteditorial(item.workgroup)
            }
            // if(item.workgroup ==="Mentorship & Welfare"){
            //   setallworkgroup(item.workgroup)
            // }
            // if(item.workgroup ==="Research"){
            //   setallworkgroup(item.workgroup)
            // }
            // if(item.workgroup ==="Editorial"){
            //   setallworkgroup(item.workgroup)
            // }
            // else{
            //   setallworkgroup('Enter Forum')
            // }
          })
          // setallworkgroup(data.message);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      checkjoinedgroup()
      fetchUsername()
      
    }, [userName, username]);
    var handleWorkgroupSelect = async (defaultValue) =>{
        var jsonData=({
            username:userName,
            workgroup:defaultValue
          })
          try {
            const response = await axios.post(`${backendUrl}/selectworkgroup`,jsonData,{
              headers: {
                'Content-Type':'application/json',
              },
            }); // Replace with your API endpoint
            const data = response.data;
            console.log(data.message)
            // eslint-disable-next-line no-global-assign
            
            // localStorage.setItem('workgroup',defaultValue)
            // setjoinedworkgroup(data.username);
            if(data.message === `You Just Joined The ${defaultValue}`){
                const message = `You Just Joined The ${defaultValue}`
                navigate(`/success/${message}`)
            }
            else{
                setapiResponse(data.message)
                setdisplaynone('block')
                setTimeout(() => {
                  setdisplaynone('none')
                }, 5000);
              
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }

    }
  
    
  return (
    <div>
      <Clientnav username={username} />
      <Clientsidebar />
      <main id="main" className="main">

        <div className="pagetitle">
          <h1>Event</h1>
          <nav className='w-100'>
            <ol className="breadcrumb w-100">
              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
              <li className="breadcrumb-item active">Join Workgroup</li>
              <div style={{float:'right'}}>  
                <p id='alert' style={{ color: apiResponse !== `You Just Joined The ${defaultValue}` ? 'white' : '',position:'fixed',zIndex: 1,background:apiResponse !==`You Just Joined The ${defaultValue}`?'red':'none',padding:apiResponse !==`You Just Joined The ${defaultValue}`?'5px':'none',borderRadius:apiResponse !==`You Just Joined The ${defaultValue}`?'4px':'0px',display:displaynone }}>
                        {apiResponse}
                    </p>
              </div>
            </ol>
          </nav>
        </div>

        <section className="section dashboard w-100">
          <div className="row w-100">
            <h2 className='eventRegisterText' style={{color:'#F4B907'}}>Hello {userName}</h2>
            <div className="col-lg-12">
                <div className="container">
                    <h2 className='' >Workgroups</h2>
                    
                   
                    <div className="row academics">
                        <div className="col-9" style={workGrpContent}>
                          {castutorial===('CASC Tutorial')?(<span className='' style={{background:'#8346D4',color:'#FCFCFC',textAlign:'center'}}>Already In Group</span>):(<span className='' style={{background:'#891A1A',color:'#FCFCFC',textAlign:'center'}}>Not In Group</span>)}
                            <h3>CASC Tutorial</h3>
                            <br/>
                            {castutorial===('CASC Tutorial')?(<Link to={`/dashboard/forum/${userName}/${castutorial}`} className={'btn btn-primary workgroup'} style={{background:'#E0D45A',color:'#363A3A',border:'none'}}>Continue to Forum <img src={arrow2} width='30' alt='Arrow' /></Link>):(<Link className={'btn btn-primary workgroup'} onClick={() => handleWorkgroupSelect('CASC Tutorial')} >Join Now</Link>)}
                            
                        </div>
                        <img src={Academicswork} />
                        
                    </div>
                    <br/>
                    <br/>
                    <div className="row arts">
                        <div className="col-9" style={workGrpContent2}>
                          {artculture===('Arts & Culture')?(<span className='' style={{background:'#8346D4',color:'#FCFCFC',textAlign:'center'}}>Already In Group</span>):(<span className='' style={{background:'#891A1A',color:'#FCFCFC',textAlign:'center'}}>Not In Group</span>)}
                            <h3>Arts & Culture</h3>
                            <br/>
                            {artculture==='Arts & Culture'?(<Link to={`/dashboard/forum/${userName}/${artculture}`} className={'btn btn-primary workgroup'} style={{background:'#E0D45A',color:'#363A3A',border:'none'}}>Continue to Forum <img src={arrow2} width='30' alt='Arrow' /></Link>):(<Link className={'btn btn-primary workgroup'} onClick={() => handleWorkgroupSelect('Arts & Culture')} >Join Now</Link>)}
                            
                        </div>
                        <img src={Arts} />
                    </div>
                    <br/>
                    <br/>
                    <div className="row academics">
                        <div className="col-9" style={workGrpContent}>
                            {mentorship===('Mentorship & Welfare')?(<span className='' style={{background:'#8346D4',color:'#FCFCFC',textAlign:'center'}}>Already In Group</span>):(<span className='' style={{background:'#891A1A',color:'#FCFCFC',textAlign:'center'}}>Not In Group</span>)}
                            <h3>Mentorship & Welfare</h3>
                            <br/>
                            {mentorship==='Mentorship & Welfare'?(<Link to={`/dashboard/forum/${userName}/${mentorship}`} className={'btn btn-primary workgroup'} style={{background:'#E0D45A',color:'#363A3A',border:'none'}}>Continue to Forum <img src={arrow2} width='30' alt='Arrow' /></Link>):(<Link className={'btn btn-primary workgroup'} onClick={() => handleWorkgroupSelect('Mentorship & Welfare')} >Join Now</Link>)}
                            
                        </div>
                        <img src={Mentorship} />
                    </div>
                    <br/>
                    <br/>
                    <div className="row academics">
                        <div className="col-9" style={workGrpContent2}>
                           
                            {research===('Research')?(<span className='' style={{background:'#8346D4',color:'#FCFCFC',textAlign:'center'}}>Already In Group</span>):(<span className='' style={{background:'#891A1A',color:'#FCFCFC',textAlign:'center'}}>Not In Group</span>)}
                            <h3>Research</h3>
                            <br/>
                            {research==='Research'?(<Link to={`/dashboard/forum/${userName}/${research}`} className={'btn btn-primary workgroup'} style={{background:'#E0D45A',color:'#363A3A',border:'none'}}>Continue to Forum <img src={arrow2} width='30' alt='Arrow' /></Link>):(<Link className={'btn btn-primary workgroup'} onClick={() => handleWorkgroupSelect('Research')} >Join Now</Link>)}
                        </div>
                        <img src={Research} />
                    </div>
                    <br/>
                    <br/>
                    <div className="row academics">
                        <div className="col-9" style={workGrpContent}>
                       
                            {editorial===('Editorial')?(<span className='' style={{background:'#8346D4',color:'#FCFCFC',textAlign:'center'}}>Already In Group</span>):(<span className='' style={{background:'#891A1A',color:'#FCFCFC',textAlign:'center'}}>Not In Group</span>)}
                            <h3>Editorial</h3>
                            <br/>
                            {editorial==='Editorial'?(<Link to={`/dashboard/forum/${userName}/${editorial}`} className={'btn btn-primary workgroup'} style={{background:'#E0D45A',color:'#363A3A',border:'none'}}>Continue to Forum <img src={arrow2} width='30' alt='Arrow' /></Link>):(<Link className={'btn btn-primary workgroup'} onClick={() => handleWorkgroupSelect('Editorial')} >Join Now</Link>)}
                        </div>
                        <img src={Editorial} />
                    </div>
                    <br/>
                </div>
            </div>
          </div>
        </section>
        {/* <div className="modal fade" id="basicModal" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Create Events</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <p id="eventAlert" style={{ color: "red", fontSize: "18px", display: "none" }}>Hello</p>

              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary" id="saveEvent">Save</button>
              </div>
            </div>
          </div>
        </div> */}
      </main>
    </div>
  );
};

export default Selectworkgroup;
