import React from "react";
import arrowdown from '../images/svg/arrowdown.png'
import { useEffect,usState } from "react";
import Navbar from '../partials/navbar';
import { Link } from "react-router-dom";
function Permission(props) {
    const workGrpContentAnchor = {
        textDecoration:'none',
        color:'#fff',
        background:'#7FA037',
        paddingTop:'6px',
        paddingLeft:'15px',
        paddingRight:'15px',
        paddingBottom:'6px',
    };
    const workGrpContentAnchorColor = {
        textDecoration:'none',
        color:'#000',
        background:'#E8EBE3',
        paddingTop:'6px',
        paddingLeft:'15px',
        paddingRight:'15px',
        paddingBottom:'6px',
        margin:'10px'
    };
  return(
    
    <div className="">
        <header>
            <Navbar/>
        </header>
        <div className="container permission text-center" style={{width:'50%',marginTop:'100px'}}>
            <h3>You need to be a member to access the Work-group page</h3>
            <br/>
            <h3>Click to see membership Information</h3>
            <br/>
            <a style={workGrpContentAnchor}><Link to='/membershipfee' style={workGrpContentAnchor}>Join</Link></a>
            <a style={workGrpContentAnchorColor}><Link to='/login' style={workGrpContentAnchorColor} >Login</Link></a>
        </div>
    </div>
    );
}

export default Permission;