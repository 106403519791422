import React from 'react';
import '../../../adminstyle/assets/vendor/bootstrap/css/bootstrap.min.css';
import '../../../adminstyle/assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../../../adminstyle/assets/vendor/boxicons/css/boxicons.min.css';
import '../../../adminstyle/assets/vendor/quill/quill.snow.css';
import '../../../adminstyle/assets/vendor/quill/quill.bubble.css';
import '../../../adminstyle/assets/vendor/remixicon/remixicon.css';
import '../../../adminstyle/assets/vendor/simple-datatables/style.css';
import '../../../adminstyle/assets/css/style.css';
import { Link } from "react-router-dom";
import DataTable from 'react-data-table-component';
import SuperAdminnav from '../superadminpartials/superadminnav';
import SuperAdminSidebar from '../superadminpartials/superadminsidebar';
import {useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import { Button } from 'bootstrap/dist/js/bootstrap.bundle';
const SuperDeleteevent = (props) => {
    const { username,id } = useParams();
  const navigate = useNavigate();
  const [newusers, setnewusers] = useState([]);
  const [eventbanner, seteventbanner] = useState([]);
  const [eventtitle, seteventtitle] = useState([]);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  useEffect(() => {
    var AdminauthToken = localStorage.getItem('authTokenAdmin')
    if(AdminauthToken==null){
      navigate('/superadminlogin')
    }
    const fullURL = window.location.href;
    // Function to fetch data from the backend API
    if(!id){
      navigate(`/adminlogin`);
    }
    else{

      const fetchEventBanner = async () => {
        const jsonData={
          id:id
        }
        try {
          const response = await axios.post(`${backendUrl}/fetchEventBanner`,jsonData,{
            headers: {
              'Content-Type': 'application/json',
            },
          }); // Replace with your API endpoint
          const data = response.data;
          // console.log(data.totalnewusers)
          seteventbanner(data.message[0].eventbanner);
          seteventtitle(data.message[0].eventTitle);
          console.log(data.message);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      const interval = setInterval(() => {
        fetchEventBanner()
      }, 1000);
  
      // Clear the interval when the component unmounts to avoid memory leaks
      return () => clearInterval(interval);
    }
  }, []);
  const handelYesdelete = async(event) =>{
    event.preventDefault()
    // navigate(`/success/${message}`);
    const jsonData={
      id:id
    }
    try {
      const response = await axios.post(`${backendUrl}/SuperBanner`,jsonData,{
        headers: {
          'Content-Type': 'application/json',
        },
      }); // Replace with your API endpoint
      const data = response.data;
      // console.log(data.message)
      if(data.message ==="Event Deleted Successfully"){
        const message = "Event Deleted Successfully"
        navigate(`/success/${message}`);
      }
      else{

      }
      
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    
  }
  const handelNodelete = (event) =>{
    navigate(`/admindashboard/${username}/allevents`);
    // navigate(-1);
  }
  const workGrpContentAnchor = {
    textDecoration: 'none',
    color: '#fff',
    background: '#0d9e6b',
    padding: '6px 15px',
    border:'none',
  };
  
  const workGrpContentAnchorColor = {
    textDecoration: 'none',
    color: '#000',
    background: '#E8EBE3',
    padding: '6px 15px',
    margin: '10px',
  };

  const workGrpContentAnchor2 = {
    textDecoration: 'none',
    color: '#fff',
    background: '#dc3545',
    border:'none',
    padding: '6px 15px',
  };
  return (
    <div>
      <SuperAdminnav username={username} />
      <SuperAdminSidebar />
      <main id="main" className="main">

        <div className="pagetitle">
          <h1>All Events</h1>
          {/* <h1>{viewallusers?'All Users':'Dashboard'}</h1> */}
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
              <li className="breadcrumb-item active">All Events</li>
            </ol>
          </nav>
        </div>

        <section className="section dashboard w-100">
          <div className="row w-100">
            <div className="col-lg-12">
              <div className="col-lg-6">
                <img src={eventbanner} alt={eventtitle} style={{width:'100%',height:'60%'}}/>
              </div>
              <div className="col-lg-5">
                <h5>Are you sure you want to delete this event?</h5>
                <button style={workGrpContentAnchor} className='yesDelete' onClick={handelYesdelete}>Yes</button> <button style={workGrpContentAnchor2} className='noDelete' onClick={handelNodelete}>No</button>
              </div>
            </div>
          </div>
        </section>
        
      </main>
    </div>
  );
};

export default SuperDeleteevent;
