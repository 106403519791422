import React from "react";
import Navbar from "./partials/navbar";
import EventSlider from "./partials/eventslider";
import PastEventSlider from "../components/pastevents";
import Footer from "./partials/footer";
import Dash from "./images/svg/Vector3.png";
import lightbulb from "./images/svg/lightbulb.png";
import globe from "./images/svg/globe.png";
import arrow2 from "./images/svg/arrow2.png";
import books from "./images/svg/books.png";
import NewsLetter from "./partials/newsletter";
import ReactGA from "react-ga";
import { Link } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
function Event(props) {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [userName, setuserName] = useState([]);
  const [events, setEvents] = useState([]);
  const { username } = useParams();
  var authToken = localStorage.getItem("authToken");
  useEffect(() => {
    const fetchUsername = async () => {
      const jsonData = {
        username: username,
      };
      try {
        const response = await axios.post(
          `${backendUrl}/fetchusername`,
          jsonData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        ); // Replace with your API endpoint
        const data = response.data;
        console.log(data.username);
        setuserName(data.username);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchUsername();
    setInterval(() => {
      axios
        .get(`${backendUrl}/getsmallevents`)
        .then((response) => {
          if (Array.isArray(response.data) && response.data.length > 0) {
            setEvents(response.data);
          } else {
            setEvents([]); // Set events to an empty array when no events are returned
          }
          // Set loading state to false
        })
        .catch((error) => {
          console.error("Error fetching events:", error);
          // Set loading state to false in case of error
        });
    }, 1000);
  }, []);
  const topicsCards = {
    paddingTop: "250px",
    maxWidth: "80%",
    margin: "0 auto",
    background: "#16AC68",
  };
  const topicsCardsPink = {
    paddingTop: "250px",
    maxWidth: "80%",
    margin: "0 auto",
    background: "#7FA037",
  };
  const topicsCardsYellow = {
    paddingTop: "250px",
    maxWidth: "80%",
    margin: "0 auto",
    background: "#F4B907",
  };
  const cardBottomTitle = {
    color: "#fff",
  };
  const topicsCardsButton = {
    padding: "8px 20px 8px 20px",
    border: "none",
  };
  useEffect(() => {
    // Track page view for the home page
    ReactGA.pageview(window.location.pathname);
  }, []);
  return (
    <div className="">
      <header>
        <Navbar />
      </header>
      <EventSlider />
      {/* <div className="mt-5">
        <div className="container topics">
          <div className="text-left custom-font-class">
            <h3><span className="custom-font-class-thin">Topics<img className="dash" src={Dash}
            alt="" /> </span>
            </h3>
            <div className="row mt-5">
              <div className="col-4">
                <div className="row">
                  <div className="col">
                    <img src={lightbulb} width='60' />
                  </div>
                  <div className="col">
                    <p className="text">Incididunt </p>
                    <h4>Consectetur </h4>
                  </div>
                </div>
                <div className="row">
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt </p>
                </div>
              </div>
              <div className="col-4">
                <div className="row">
                  <div className="col">
                    <img src={globe} width='60' />
                  </div>
                  <div className="col">
                    <p className="text">Incididunt </p>
                    <h4>Consectetur </h4>
                  </div>
                </div>
                <div className="row">
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt </p>
                </div>
              </div>
              <div className="col-4">
                <div className="row">
                  <div className="col">
                    <img src={books} width='60' />
                  </div>
                  <div className="col">
                    <p className="text">Incididunt </p>
                    <h4>Consectetur </h4>
                  </div>
                </div>
                <div className="row">
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="container-fluid mt-5 row">
        {events.length === 0 ? (
          <div className="container-fluid mt-5 row">
            <div
              className="col-3 topicsCards purple rounded"
              style={topicsCardsPink}
            >
              <div className="card-title max-height">
                <h2
                  style={{ color: "#fff", position: "relative", top: "-200px" }}
                >
                  Summer Gala and Seminar
                </h2>
              </div>
              <div className="row mb-5">
                <div
                  className=""
                  style={{ color: "#fff", position: "relative", top: "-50px" }}
                >
                  <p>
                    The Summer Gala is a social event for members to network and
                    relax. We get to know ourselves and build better association
                    and support for members.
                  </p>
                </div>
                <div className="col-8" style={cardBottomTitle}>
                  <p>Register Now</p>
                </div>
                <div className="col-3 text-center" style={{ maxWidth: "80%" }}>
                  <button style={topicsCardsButton}>
                    <img src={arrow2} width="10" />
                  </button>
                </div>
              </div>
            </div>
            <div
              className="col-3 topicsCards purple rounded"
              style={topicsCards}
            >
              <div className="card-title max-height">
                <h2
                  style={{ color: "#fff", position: "relative", top: "-200px" }}
                >
                  Black History Month
                </h2>
              </div>
              <div className="row mb-5">
                <div
                  className=""
                  style={{ color: "#fff", position: "relative", top: "-50px" }}
                >
                  <p>
                    We celebrate the Black History Month in October. It is a
                    time to reflect on the challenges past and present, and to
                    celebrate the champions of our heritage.{" "}
                  </p>
                </div>
                <div className="col-8" style={cardBottomTitle}>
                  <p>Register Now</p>
                </div>
                <div className="col-3 text-center" style={{ maxWidth: "80%" }}>
                  <button style={topicsCardsButton}>
                    <img src={arrow2} width="10" />
                  </button>
                </div>
              </div>
            </div>
            <div
              className="col-3 topicsCards purple rounded"
              style={topicsCardsYellow}
            >
              <div className="card-title max-height">
                <h2
                  style={{ color: "#fff", position: "relative", top: "-200px" }}
                >
                  ABP-UK Anniversary & Election
                </h2>
              </div>
              <div className="row mb-5">
                <div
                  className=""
                  style={{ color: "#fff", position: "relative", top: "-50px" }}
                >
                  <p>
                    ABP-UK will be 3 years old this October! It is time to
                    review the achievements of the past year(s) and to new elect
                    leaders to steer the course of the association.
                  </p>
                </div>
                <div className="col-8" style={cardBottomTitle}>
                  <p>Register Now</p>
                </div>
                <div className="col-3 text-center" style={{ maxWidth: "80%" }}>
                  <button style={topicsCardsButton}>
                    <img src={arrow2} width="10" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          events.map((event) => (
            <div
              className="col-3 topicsCards purple rounded"
              style={topicsCardsPink}
            >
              <div className="card-title max-height">
                <h2
                  style={{ color: "#fff", position: "relative", top: "-200px" }}
                >
                  ABP-UK {event.eventTitle}
                </h2>
              </div>
              <div className="row mb-5">
                <div
                  className=""
                  style={{ color: "#fff", position: "relative", top: "-50px" }}
                >
                  <p>{event.aboutEvent}</p>
                </div>
                <div className="col-8" style={cardBottomTitle}>
                  <p>Register Now</p>
                </div>
                <div className="col-3 text-center" style={{ maxWidth: "80%" }}>
                  <button style={topicsCardsButton}>
                    <Link
                      style={{ textDecoration: "none" }}
                      to={
                        authToken == null
                          ? `/registerevent`
                          : `/registerevent/${userName}`
                      }
                    >
                      <img src={arrow2} width="30" alt="Arrow" />
                    </Link>
                  </button>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
      <div className="mt-5">
        <div className="container pastevents">
          <div className="text-left custom-font-class">
            <h3>
              <span className="custom-font-class-thin">
                Past Events
                <img className="dash" src={Dash} alt="" />{" "}
              </span>
            </h3>
          </div>
        </div>
      </div>
      <br />
      <PastEventSlider />
      <NewsLetter />
      <Footer />
    </div>
  );
}

export default Event;
