import React from 'react';
import '../../adminstyle/assets/vendor/bootstrap/css/bootstrap.min.css';
import '../../adminstyle/assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../../adminstyle/assets/vendor/boxicons/css/boxicons.min.css';
import '../../adminstyle/assets/vendor/quill/quill.snow.css';
import '../../adminstyle/assets/vendor/quill/quill.bubble.css';
import '../../adminstyle/assets/vendor/remixicon/remixicon.css';
import '../../adminstyle/assets/vendor/simple-datatables/style.css';
import '../../adminstyle/assets/css/style.css';
import Adminnav from './adminpartials/adminnav';
import AdminSidebar from './adminpartials/adminsidebar';
import Eventsbanners from './adminpartials/tables/eventstable'
import {useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'

const Allevents = () => {
  const { username,viewallusers,userid } = useParams();
  const navigate = useNavigate();
  const [newusers, setnewusers] = useState([]);
  const [alleventlist, setalleventlist] = useState([]);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  useEffect(() => {
    var AdminauthToken = localStorage.getItem('authTokenAdmin')
      if(AdminauthToken==null){
        navigate('/adminlogin')
      }
    const fullURL = window.location.href;
    // Function to fetch data from the backend API
    
    const fetchNewfUser = async () => {
      const jsonData={
        viewAllusers:1
      }
      try {
        const response = await axios.post(`${backendUrl}/alleventslist`,jsonData,{
          headers: {
            'Content-Type': 'application/json',
          },
        }); // Replace with your API endpoint
        const data = response.data;
        // console.log(data.totalnewusers)
        setalleventlist(data.totalevents);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

  
    fetchNewfUser()

    // Clear the interval when the component unmounts to avoid memory leaks
  }, []);
  return (
    <div>
      <Adminnav username={username} />
      <AdminSidebar />
      <main id="main" className="main">

        <div className="pagetitle">
          <h1>All Events</h1>
          {/* <h1>{viewallusers?'All Users':'Dashboard'}</h1> */}
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
              <li className="breadcrumb-item active">All Events</li>
            </ol>
          </nav>
        </div>

        <section className="section dashboard w-100">
          <div className="row w-100">
            <div className="col-lg-12">
              
            </div>
          </div>
          <Eventsbanners data={alleventlist} username={username}/>
        </section>
        {/* <div className="modal fade" id="basicModal" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Create Events</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <p id="eventAlert" style={{ color: "red", fontSize: "18px", display: "none" }}>Hello</p>

              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary" id="saveEvent">Save</button>
              </div>
            </div>
          </div>
        </div> */}
      </main>
    </div>
  );
};

export default Allevents;