/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import '../../adminstyle/assets/vendor/bootstrap/css/bootstrap.min.css';
import '../../adminstyle/assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../../adminstyle/assets/vendor/boxicons/css/boxicons.min.css';
import '../../adminstyle/assets/vendor/quill/quill.snow.css';
import '../../adminstyle/assets/vendor/quill/quill.bubble.css';
import '../../adminstyle/assets/vendor/remixicon/remixicon.css';
import '../../adminstyle/assets/vendor/simple-datatables/style.css';
import '../../adminstyle/assets/css/style.css';
import Clientnav from './clientpartials/clientnav';
import Clientsidebar from './clientpartials/clientsidebar';
import {useState, useEffect } from 'react';
import { Link,useLocation } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'

const Userprofile = () => {
  const { username } = useParams();
  const location = useLocation();
  const currentUrl = location.pathname; 
  const [userName, setuserName] = useState([]);
  const [fullname, setfullname] = useState("");
  const [fullnamefromdatabase, setfullnamefromdatabase] = useState("");
  const [phonenumber, setphonenumber] = useState("");
  const [email, setemail] = useState("");
  const [membershiptype, setmembershiptype] = useState("");
  const [address, setaddress] = useState("");
  const [previewUrl, setPreviewUrl] = useState('');
  const [password, setpassword] = useState('');
  const [confirmpassword, setconfirmpassword] = useState('');
  const [userphoto, setuserphoto] = useState(null);
  const [profileimage, setprofileimage] = useState(null);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
//   const [image, setImage] = useState(null);
  const navigate = useNavigate();
//   const { message } = useParams();
  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    if (selectedImage) {
        setprofileimage(selectedImage);
      const imageUrl = URL.createObjectURL(selectedImage);
      setPreviewUrl(imageUrl);
      const imageName = selectedImage.name;
      setprofileimage(selectedImage);
      console.log(selectedImage);
    //   console.log(previewUrl)
    }
    // if (selectedImage) {
    //   setprofileimage(selectedImage);
    //   const imageUrl = URL.createObjectURL(selectedImage);
    //   setPreviewUrl(imageUrl);
    // }
  };
    const handlechangepassword =async (event)=>{
        event.preventDefault()
        const jsonData={
            username:username,
            password:password
            // memebershiptype:memebershiptype
          }
          try {
            const response = await axios.post(`${backendUrl}/updatepassword`,jsonData,{
              headers: {
                'Content-Type': 'application/json',
              },
            }); // Replace with your API endpoint
            const data = response.data;
            console.log(data)
            if(data.message==="Password Updated Successfully"){
                const message= 'Password Updated Successfully'
                navigate(`/success/${message}`)
            }
        
          } catch (error) {
            console.error('Error fetching data:', error);
          }
    }
    const updateUserProfileDetails = async (event) => {
      event.preventDefault();
      var formData = new FormData();
      formData.append('image', profileimage);
    
      if (!profileimage) {
        console.error('Please select an image');
      } else {
        // If there's a selected image, upload it and then insert the profile information
        await handleUploadPhotos(formData); // Pass the formData
        await handleInsertProfile(); // Insert profile information after the image is uploaded
      }
    };
    
    const handleUploadPhotos = async (formData) => {
      try {
        await axios.post(`${backendUrl}/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log('Image uploaded successfully');
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    };
    
    const handleInsertProfile = async () => {
      const jsonData = {
        username: username,
        fullname: fullname,
        phonenumber: phonenumber,
        email: email,
        address: address,
        profileImage: !profileimage ? null : profileimage.name,
        // memebershiptype: membershiptype // Uncomment this line if needed
      };
    
      try {
        const response = await axios.post(`${backendUrl}/updateprofile`, jsonData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = response.data;
        console.log(data);
        if (data.message === 'Profile Updated Successfully') {
          const message = 'Profile Updated Successfully';
          navigate(`/success/${message}`);
        }
      } catch (error) {
        console.error('Error updating profile:', error);
      }
    };
    
  useEffect(() => {
    const fullURL = window.location.href;
    // Function to fetch data from the backend API
    var authToken = localStorage.getItem('authToken')
    if(authToken==null){
      navigate('/login')
    }
    const fetchUsername = async () => {
        const jsonData=({
          username:username
        })
        try {
          const response = await axios.post(`${backendUrl}/fetchusername`,jsonData,{
            headers: {
              'Content-Type': 'application/json',
            },
          }); // Replace with your API endpoint
          const data = response.data;
          console.log(data.username)
          setuserName(data.username);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    };

    const fetchUserProfileDetails = async () => {
      const jsonData={
        username:userName
      }
      try {
        const response = await axios.post(`${backendUrl}/memberprofile`,jsonData,{
          headers: {
            'Content-Type': 'application/json',
          },
        }); // Replace with your API endpoint
        const data = response.data;
        console.log(data.result[0])
        setfullname(data.result[0].fullname);
        setfullnamefromdatabase(data.result[0].fullname);
        setemail(data.result[0].email)
        setphonenumber(data.result[0].phonenumber)
        setmembershiptype(data.result[0].membershiptype)
        setaddress(data.result[0].address)
        setuserphoto(data.result[0].avatar)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchUsername()
    fetchUserProfileDetails()
  }, [userName, username]);
  return (
    <div>
      <Clientnav username={username} />
      <Clientsidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Dashboard</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
              <li className="breadcrumb-item active">Dashboard</li>
            </ol>
          </nav>
        </div>
        <section class="section profile">
            <div class="row">
                <div class="col-xl-4">
                <div class="card">
                    <div class="card-body profile-card pt-4 d-flex flex-column align-items-center">
                    {userphoto !==null?<img src={`${backendUrl}`+userphoto} alt="Profile"
                    class="rounded-circle"/>:<i class="bi bi-person" style={{fontSize:'120px'}}></i>}
                    <h2>{fullnamefromdatabase}</h2>
                    <h3>{membershiptype}</h3>
                    <div class="social-links mt-2">
                        <a href="#" class="twitter"><i class="bi bi-twitter"></i></a>
                        <a href="#" class="facebook"><i class="bi bi-facebook"></i></a>
                        <a href="#" class="instagram"><i class="bi bi-instagram"></i></a>
                        <a href="#" class="linkedin"><i class="bi bi-linkedin"></i></a>
                    </div>
                    </div>
                </div>
                </div>
                <div class="col-xl-8">
                <div class="card">
                    <div class="card-body pt-3">
                    <ul class="nav nav-tabs nav-tabs-bordered">
                        <li class="nav-item">
                        <button class="nav-link active" data-bs-toggle="tab" data-bs-target="#profile-overview">Overview</button>
                        </li>

                        <li class="nav-item">
                        <button class="nav-link" data-bs-toggle="tab" data-bs-target="#profile-edit">Edit Profile</button>
                        </li>

                        {/* <li class="nav-item">
                        <button class="nav-link" data-bs-toggle="tab" data-bs-target="#profile-settings">Settings</button>
                        </li> */}

                        <li class="nav-item">
                        <button class="nav-link" data-bs-toggle="tab" data-bs-target="#profile-change-password">Change Password</button>
                        </li>

                    </ul>
                    <div class="tab-content pt-2">

                        <div class="tab-pane fade show active profile-overview" id="profile-overview">
                        {/* <h5 class="card-title">About</h5>
                        <p class="small fst-italic">Sunt est soluta temporibus accusantium neque nam maiores cumque temporibus. Tempora libero non est unde veniam est qui dolor. Ut sunt iure rerum quae quisquam autem eveniet perspiciatis odit. Fuga sequi sed ea saepe at unde.</p> */}

                        <h5 class="card-title">Profile Details</h5>

                        <div class="row">
                            <div class="col-lg-3 col-md-4 label ">Full Name</div>
                            <div class="col-lg-9 col-md-8">{fullname}</div>
                        </div>

                        {/* <div class="row">
                            <div class="col-lg-3 col-md-4 label">Company</div>
                            <div class="col-lg-9 col-md-8">Lueilwitz, Wisoky and Leuschke</div>
                        </div> */}

                        <div class="row">
                            <div class="col-lg-3 col-md-4 label">Membership Type</div>
                            <div class="col-lg-9 col-md-8">{membershiptype}</div>
                        </div>

                        {/* <div class="row">
                            <div class="col-lg-3 col-md-4 label">Country</div>
                            <div class="col-lg-9 col-md-8">USA</div>
                        </div> */}

                        <div class="row">
                            <div class="col-lg-3 col-md-4 label">Address</div>
                            <div class="col-lg-9 col-md-8">{address}</div>
                        </div>

                        <div class="row">
                            <div class="col-lg-3 col-md-4 label">Phone</div>
                            <div class="col-lg-9 col-md-8">{phonenumber}</div>
                        </div>

                        <div class="row">
                            <div class="col-lg-3 col-md-4 label">Email</div>
                            <div class="col-lg-9 col-md-8">{email}</div>
                        </div>

                        </div>

                        <div class="tab-pane fade profile-edit pt-3" id="profile-edit">

                        <form>
                            <div class="row mb-3">
                            <label for="profileImage" class="col-md-4 col-lg-3 col-form-label">Profile Image</label>
                            <div class="col-md-8 col-lg-9">
                                <label for='uploadImage'>{previewUrl !== "" ? <img src={previewUrl} alt="Profile Photo" /> : <i class="bi bi-image" style={{fontSize:'70px'}}></i>}
                                
                                </label>
                                <div class="pt-2">
                                <input id='uploadImage' hidden type="file" accept=".jpg,.png,.jpeg" onChange={handleImageChange} />
                                <label class="btn btn-primary btn-sm" for='uploadImage'><i class="bi bi-upload" style={{color:'#fff'}}></i></label>
                                {/* <a href="#" class="btn btn-danger btn-sm" title="Remove my profile image"><i class="bi bi-trash"></i></a> */}
                                </div>
                            </div>
                            </div>

                            <div class="row mb-3">
                            <label for="fullName" class="col-md-4 col-lg-3 col-form-label">Full Name</label>
                            <div class="col-md-8 col-lg-9">
                                <input name="fullName" type="text" class="form-control" id="fullName" value={fullname} onChange={(e)=>{
                                    setfullname(e.target.value)
                                }} />
                            </div>
                            </div>

                            {/* <div class="row mb-3">
                            <label for="about" class="col-md-4 col-lg-3 col-form-label">About</label>
                            <div class="col-md-8 col-lg-9">
                                <textarea name="about" class="form-control" id="about" style={{height: "100px"}}>Sunt est soluta temporibus accusantium neque nam maiores cumque temporibus. Tempora libero non est unde veniam est qui dolor. Ut sunt iure rerum quae quisquam autem eveniet perspiciatis odit. Fuga sequi sed ea saepe at unde.</textarea>
                            </div>
                            </div> */}

                            {/* <div class="row mb-3">
                            <label for="company" class="col-md-4 col-lg-3 col-form-label">Company</label>
                            <div class="col-md-8 col-lg-9">
                                <input name="company" type="text" class="form-control" id="company" value="Lueilwitz, Wisoky and Leuschke" />
                            </div>
                            </div> */}

                            <div class="row mb-3">
                            <label for="Job" class="col-md-4 col-lg-3 col-form-label">Membership Type</label>
                            <div class="col-md-8 col-lg-9">
                                
                            <select className="form-control" id='membershiptype' disabled value={membershiptype} onChange={(e)=>{
                                setmembershiptype(e.target.value)
                            }}>
                                <option>Consultant</option>
                                <option>SAS doctor</option>
                                <option>Trainee</option>
                                <option>Retired Member</option>
                                <option>Affiliate Member</option>
                                <option>FYI/FY2</option>
                                <option>Medical Student</option>
                            </select>
                            </div>
                            </div>

                            {/* <div class="row mb-3">
                            <label for="Country" class="col-md-4 col-lg-3 col-form-label">Country</label>
                            <div class="col-md-8 col-lg-9">
                                <input name="country" type="text" class="form-control" id="Country" value="USA" />
                            </div>
                            </div> */}

                            <div class="row mb-3">
                            <label for="Address" class="col-md-4 col-lg-3 col-form-label">Address</label>
                            <div class="col-md-8 col-lg-9">
                                <input name="address" type="text" class="form-control" id="Address" value={address}  onChange={(e)=>{
                                    setaddress(e.target.value)
                                }}/>
                            </div>
                            </div>

                            <div class="row mb-3">
                            <label for="Phone" class="col-md-4 col-lg-3 col-form-label">Phone</label>
                            <div class="col-md-8 col-lg-9">
                                <input name="phone" type="text" class="form-control" id="Phone" value={phonenumber} onChange={(e)=>{
                                    setphonenumber(e.target.value)
                                }}/>
                            </div>
                            </div>

                            <div class="row mb-3">
                            <label for="Email" class="col-md-4 col-lg-3 col-form-label">Email</label>
                            <div class="col-md-8 col-lg-9">
                                <input name="email" type="email" class="form-control" id="Email" value={email} onChange={(e)=>{
                                    setemail(e.target.value)
                                }} />
                            </div>
                            </div>

                            {/* <div class="row mb-3">
                            <label for="Twitter" class="col-md-4 col-lg-3 col-form-label">Twitter Profile</label>
                            <div class="col-md-8 col-lg-9">
                                <input name="twitter" type="text" class="form-control" id="Twitter" value="https://twitter.com/#" />
                            </div>
                            </div>

                            <div class="row mb-3">
                            <label for="Facebook" class="col-md-4 col-lg-3 col-form-label">Facebook Profile</label>
                            <div class="col-md-8 col-lg-9">
                                <input name="facebook" type="text" class="form-control" id="Facebook" value="https://facebook.com/#" />
                            </div>
                            </div>

                            <div class="row mb-3">
                            <label for="Instagram" class="col-md-4 col-lg-3 col-form-label">Instagram Profile</label>
                            <div class="col-md-8 col-lg-9">
                                <input name="instagram" type="text" class="form-control" id="Instagram" value="https://instagram.com/#" />
                            </div>
                            </div>

                            <div class="row mb-3">
                            <label for="Linkedin" class="col-md-4 col-lg-3 col-form-label">Linkedin Profile</label>
                            <div class="col-md-8 col-lg-9">
                                <input name="linkedin" type="text" class="form-control" id="Linkedin" value="https://linkedin.com/#" />
                            </div>
                            </div> */}

                            <div class="text-center">
                            <button type="submit" onClick={updateUserProfileDetails} class="btn btn-primary">Save Changes</button>
                            </div>
                        </form>

                        </div>

                        <div class="tab-pane fade pt-3" id="profile-settings">

                        <form>

                            <div class="row mb-3">
                            <label for="fullName" class="col-md-4 col-lg-3 col-form-label">Email Notifications</label>
                            <div class="col-md-8 col-lg-9">
                                <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="changesMade" checked />
                                <label class="form-check-label" for="changesMade">
                                    Changes made to your account
                                </label>
                                </div>
                                <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="newProducts" checked />
                                <label class="form-check-label" for="newProducts">
                                    Information on new products and services
                                </label>
                                </div>
                                <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="proOffers" />
                                <label class="form-check-label" for="proOffers">
                                    Marketing and promo offers
                                </label>
                                </div>
                                <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="securityNotify" checked disabled />
                                <label class="form-check-label" for="securityNotify">
                                    Security alerts
                                </label>
                                </div>
                            </div>
                            </div>

                            <div class="text-center">
                            <button type="submit" class="btn btn-primary">Save Changes</button>
                            </div>
                        </form>

                        </div>

                        <div class="tab-pane fade pt-3" id="profile-change-password">
                        <form>

                            {/* <div class="row mb-3">
                            <label for="currentPassword" class="col-md-4 col-lg-3 col-form-label">Current Password</label>
                            <div class="col-md-8 col-lg-9">
                                <input name="password" type="password" class="form-control" id="currentPassword" />
                            </div>
                            </div> */}

                            <div class="row mb-3">
                            <label for="newPassword" class="col-md-4 col-lg-3 col-form-label">New Password</label>
                            <div class="col-md-8 col-lg-9">
                                <input name="newpassword" type="password" class="form-control" id="newPassword" value={password} onChange={(e)=>{
                                    setpassword(e.target.value)
                                }} />
                            </div>
                            </div>

                            <div class="row mb-3">
                            <label for="renewPassword" class="col-md-4 col-lg-3 col-form-label">Confirmpassword</label>
                            <div class="col-md-8 col-lg-9">
                                <input name="renewpassword" type="password" class="form-control" id="renewPassword" value={confirmpassword} onChange={(e)=>{
                                    setconfirmpassword(e.target.value)
                                }} />
                            </div>
                            </div>

                            <div class="text-center">
                            <button type="button" class="btn btn-primary" onClick={handlechangepassword}>Change Password</button>
                            </div>
                        </form>

                        </div>

                    </div>

                    </div>
                </div>

                </div>
            </div>
            </section>
      </main>
    </div>
  );
};

export default Userprofile;
