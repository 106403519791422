import React, { useState ,useEffect} from 'react';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import '../../../../adminstyle/assets/vendor/bootstrap/css/bootstrap.min.css';
import '../../../../adminstyle/assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../../../../adminstyle/assets/vendor/boxicons/css/boxicons.min.css';
import '../../../../adminstyle/assets/vendor/quill/quill.snow.css';
import '../../../../adminstyle/assets/vendor/quill/quill.bubble.css';
import '../../../../adminstyle/assets/vendor/remixicon/remixicon.css';
import '../../../../adminstyle/assets/vendor/simple-datatables/style.css';
import '../../../../adminstyle/assets/css/style.css';

const Forummemberstable = (props) => {
    const [searchText, setSearchText] = useState('');
    const [filteredData, setFilteredData] = useState(props.data);
  
    useEffect(() => {
      setFilteredData(props.data);
    }, [props.data]);
  
    const handleSearch = (value) => {
      setSearchText(value);
      if (value === '') {
        setFilteredData(props.data);
      } else {
        const filteredResults = props.data.filter((item) =>
          Object.values(item)
            .join(' ')
            .toLowerCase()
            .includes(value.toLowerCase())
        );
        setFilteredData(filteredResults);
      }
    };
  
    const columns = [
      {
        name: 'Avatar',
        cell: (row) => (
          <img width={'50px'} height={'50px'} src={`${backendUrl}${row.useravatar}`} style={{ width: '50px',borderRadius:'50px' }} alt="Avatar" />
        ),
      },
      {
        name: 'Fullname',
        selector: 'fullname',
        sortable: true,
      },

      {
        name: 'View',
        cell: (row) => (
          <Link to={`/admindashboard/${row.username}/viewuser/${row.userid}/${'User Details'}`} style={workGrpContentAnchorColor}>
            View
          </Link>
        ),
        ignoreRowClick: true,
        allowOverflow: false,
        button: true,
      },
    ];
  
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
  
    const workGrpContentAnchorColor = {
      textDecoration: 'none',
      color: '#000',
      background: '#E8EBE3',
      padding: '6px 15px',
      margin: '10px',
    };
  
    return (
      <div className="">
        <div className="col-12">
          <div className="card recent-sales overflow-auto">
            <div className="card-body">
              <h5 className="card-title">Forum Member list</h5>
              <input
                type="text"
                placeholder="Search..."
                value={searchText}
                onChange={(e) => handleSearch(e.target.value)}
                style={{ marginBottom: '10px' }}
              />
              <DataTable
                columns={columns}
                data={filteredData}
                pagination
                highlightOnHover
                responsive
                defaultSortField="fullname"
                noHeader
                customStyles={{
                  rows: {
                    style: {
                      fontSize: '14px',
                      borderBottom: '1px solid #ddd',
                      padding: '10px 0',
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

export default Forummemberstable;
