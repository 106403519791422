import React from "react";
import Navbar from './partials/navbar';
import Slider from './partials/sliders'
import Dash from './images/svg/Vector3.png'
import academicswork from './images/academicswork.png'
import mentorship from './images/mentorship.png'
import research from './images/research.png'
import editorial from './images/editorial.png'
import arts from './images/arts.png'
import ReactGA from 'react-ga';
import videoPoster from './images/videoposter.png'
import arrowdown from './images/svg/arrowdown.png'
import Footer from './partials/footer';
import NewsLetter from './partials/newsletter';
import { useEffect,usState } from "react";
import { Link } from "react-router-dom";
function Workgroup(props) {
  const missionright = {
    width:'60px',
    wordWrap: 'break-word',
    fontSize:'20px'
  };
  const workGrpContent = {
    position:'relative',
    top:'150px',
    marginLeft:'40px'
  };
  const workGrpContent2= {
    position:'relative',
    top:'150px',
    marginLeft:'40px',
    color:'#fff',
  };

  const workGrpContentAnchor = {
    textDecoration: 'none',
    color: 'blue',
    fontWeight: 'bold',
    fontSize: '16px',
    color:'#fff',
    background:'#7FA037',
    padding:'10px'
  };
  
  const Percentage = {
    float:'right',
    height:'60px'
  };
  const asterics = {
    float:'right',
    width:'100px',
    height:'100px',    
    marginTop:'0px',
    position:'relative',
    top:'80px'

  };
  
  const executives = {
    fontSize:'20px'
  };
  const videoStyle = {
    width:"100%",
    height:"50%",
    margin:'0 auto',
    marginTop:'30px'
  };
  const trainingProg = {
    color:'#fff',
    fontWeight:'400',
    fontSize:'20px'
  };
  const training = {
    fontFamily:'Space Grotesk',
    fontWeight:600,
  };
  const services={
    margin:'0 auto',
  }
  const serviceSubsection={
    margin:'0 auto',
  }
  useEffect(() => {
    // Track page view for the home page
    ReactGA.pageview(window.location.pathname);
  }, []);
  return (
    <div className="">
      <header>
        <Navbar/>
      </header>
      <div className="container mt-5">
        <h3>
            <span className="custom-font-class-thin">Work Groups<img className="dash" src={Dash}
        alt="" /> </span>
        </h3>
        <br/>
        <div className="row academics">
            <div className="workGrpContent" style={workGrpContent}>
                <h3>CASC Tutorial Group</h3>
                <br/>
                <a style={workGrpContentAnchor}><Link style={workGrpContentAnchor} to='/permissions'>Read More</Link></a>
            </div>
            <img src={academicswork} />
        </div>
        <br/>
        <br/>
        <div className="row arts">
            <div className="workGrpContent" style={workGrpContent2}>
                <h3>Arts & Culture</h3>
                <br/>
                <a style={workGrpContentAnchor}><Link style={workGrpContentAnchor} to='/permissions'>Read More</Link></a>
            </div>
            <img src={arts} />
        </div>
        <br/>
        <br/>
        <div className="row academics">
            <div className="workGrpContent" style={workGrpContent}>
                <h3>Mentorship & Welfare</h3>
                <br/>
                <a style={workGrpContentAnchor}><Link style={workGrpContentAnchor} to='/permissions'>Read More</Link></a>
            </div>
            <img src={mentorship} />
        </div>
        <br/>
        <br/>
        <div className="row academics">
            <div className="workGrpContent" style={workGrpContent2}>
                <h3>Research</h3>
                <br/>
                <a style={workGrpContentAnchor}><Link style={workGrpContentAnchor} to='/permissions'>Read More</Link></a>
            </div>
            <img src={research} />
        </div>
        <br/>
        <br/>
        <div className="row academics">
            <div className="workGrpContent" style={workGrpContent}>
                <h3>Editorial</h3>
                <br/>
                <a style={workGrpContentAnchor}><Link style={workGrpContentAnchor} to='/permissions'>Read More</Link></a>
            </div>
            <img src={editorial} />
        </div>
        <br/>
      </div>
      <NewsLetter/>
      <Footer/>
    </div>
  );
}

export default Workgroup;
