import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Carousel } from 'react-bootstrap';
import Slide1 from '../../images/Eventsimages/Group 258.png';
import Slide2 from '../../images/Eventsimages/event1.png';
import Slide3 from '../../images/Eventsimages/event2.png';
import Slide4 from '../../images/Eventsimages/event3.png';
import arrow2 from '../../images/svg/arrow2.png'
import { Link } from "react-router-dom";

// import Slide2 from '../images/slide2.jpg';
// import Slide3 from '../images/slide3.jpg';

const ClientEventSlider = () => {
  const [loading, setLoading] = useState(true); 
  const [events, setEvents] = useState([]);
  const [userName, setuserName] = useState([]);
  const { username } = useParams();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
    useEffect(() => {
      setInterval(() => {
        axios.get(`${backendUrl}/getevents`)
        .then(response => {
          if (Array.isArray(response.data) && response.data.length > 0) {
            setEvents(response.data);
          } else {
            setEvents([]); // Set events to an empty array when no events are returned
          }
          setLoading(false); // Set loading state to false
        })
        .catch(error => {
          console.error('Error fetching events:', error);
          setLoading(false); // Set loading state to false in case of error
        });
      }, 1000);
    }, []);
    var authToken = localStorage.getItem('authToken')
  if(authToken==null){
    // navigate('/login')
  }
  else{
    const fetchUsername = async () => {
      const jsonData=({
        username:username
      })
      try {
        const response = await axios.post(`${backendUrl}/fetchusername`,jsonData,{
          headers: {
            'Content-Type': 'application/json',
          },
        }); // Replace with your API endpoint
        const data = response.data;
        console.log(data.username)
        setuserName(data.username);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
  };
  fetchUsername()
  }
  const newsLetterButton = {
    color:'#000',
    border:'none',
    marginTop:'10px',
    background:'#F4B907',
    padding:'4px',
    width:'70px'
  };
  return (
    <div>
      <div className='eventSliders' style={{width:'100%'}}>
        {events.length === 0 ?(<Carousel>
          <Carousel.Item>
            <img
              className="w-100 slider-image"
              src={Slide2}
              alt="Slide 1"
            />
            <Carousel.Caption>
              {/* <h3>Slide 1</h3>
              <p>Slide 1 description</p> */}
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="w-100 slider-image"
              src={Slide3}
              alt="Slide 2"
            />
            <Carousel.Caption>
              {/* <h3>Slide 2</h3>
              <p>Slide 2 description</p> */}
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="w-100 slider-image"
              src={Slide4}
              alt="Slide 3"
            />
            <Carousel.Caption>
              {/* <h3>Slide 3</h3>
              <p>Slide 3 description</p> */}
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>):(<Carousel style={{width:'100%', height:'600px'}}>
            {events.map(event => (
              <Carousel.Item key={event.id} style={{width:'100%',height:'600px'}}>
                <img src={`${backendUrl}`+ event.eventbanner} style={{width:'100%', height:'600px'}} alt={`Event ${event.id}`} />
                <Carousel.Caption>
                  {/* Uncomment these lines if you want to display event title and dates */}
                  {/* <h3>{event.eventtitle}</h3>
                  <p>{event.eventstartdate} - {event.eventenddate}</p> */}
                </Carousel.Caption>
              </Carousel.Item>
            ))}
          </Carousel>)}
        
        <div className='row eventRegisterText'>
          <div className='col-11'>
            <p>Register Now</p>
          </div>
          <div className='col-1 text-right button'>
            <button style={newsLetterButton}>
              <Link style={{textDecoration:'none'}} to={authToken==null?`/registerevent`:`/registerevent/${userName}`}><img src={arrow2} width='30' alt='Arrow' /></Link>
            </button>
          </div>
        </div>

      </div>
    </div>
  );
};

export default ClientEventSlider;
