import React from 'react';
import { Carousel } from 'react-bootstrap';
import Slide1 from '../images/slide1.jpg';
import Slide2 from '../images/slide2.jpg';
import Slide3 from '../images/slide3.jpg';

const Slider = () => {
  return (
    <Carousel>
      <Carousel.Item>
        <img
          className="w-100 slider-image"
          src={Slide1}
          alt="Slide 1"
        />
        <Carousel.Caption>
          {/* <h3>Slide 1</h3>
          <p>Slide 1 description</p> */}
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="w-100 slider-image"
          src={Slide2}
          alt="Slide 2"
        />
        <Carousel.Caption>
          {/* <h3>Slide 2</h3>
          <p>Slide 2 description</p> */}
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="w-100 slider-image"
          src={Slide3}
          alt="Slide 3"
        />
        <Carousel.Caption>
          {/* <h3>Slide 3</h3>
          <p>Slide 3 description</p> */}
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default Slider;
