import React from 'react';
import '../../adminstyle/assets/vendor/bootstrap/css/bootstrap.min.css';
import '../../adminstyle/assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../../adminstyle/assets/vendor/boxicons/css/boxicons.min.css';
import '../../adminstyle/assets/vendor/quill/quill.snow.css';
import '../../adminstyle/assets/vendor/quill/quill.bubble.css';
import '../../adminstyle/assets/vendor/remixicon/remixicon.css';
import '../../adminstyle/assets/vendor/simple-datatables/style.css';
import '../../adminstyle/assets/css/style.css';
import SuperAdminnav from './superadminpartials/superadminnav'
import SuperAdminSidebar from './superadminpartials/superadminsidebar';
import Newmemberstable from './superadminpartials/tables/newmembers'
import {useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'

const SuperViewallusers = () => {
  const { username,viewallusers,userid } = useParams();
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [newusers, setnewusers] = useState([]);
  const [allnewusers, setallnewusers] = useState([]);

  useEffect(() => {
    var AdminauthToken = localStorage.getItem('authTokenAdmin')
    if(AdminauthToken==null){
      navigate('/superadminlogin')
    }
    const fullURL = window.location.href;
    // Function to fetch data from the backend API
    const fetchNoOfUser = async () => {
      try {
        const response = await axios.get(`${backendUrl}/allusers`); // Replace with your API endpoint
        const jsonData = response.data;
        console.log(jsonData.totalusers)
        setnewusers(jsonData.totalusers);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    const fetchNewfUser = async () => {
      const jsonData={
        viewAllusers:1
      }
      try {
        const response = await axios.post(`${backendUrl}/viewalluserslist`,jsonData,{
          headers: {
            'Content-Type': 'application/json',
          },
        }); // Replace with your API endpoint
        const data = response.data;
        // console.log(data.totalnewusers)
        setallnewusers(data.totalnewusers);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const interval = setInterval(() => {
      fetchNoOfUser();
      fetchNewfUser()
    }, 1000);

    // Clear the interval when the component unmounts to avoid memory leaks
    return () => clearInterval(interval);
  }, []);
  return (
    <div>
      <SuperAdminnav username={username} />
      <SuperAdminSidebar />
      <main id="main" className="main">

        <div className="pagetitle">
          <h1>All Users</h1>
          {/* <h1>{viewallusers?'All Users':'Dashboard'}</h1> */}
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
              <li className="breadcrumb-item active">All Users</li>
            </ol>
          </nav>
        </div>

        <section className="section dashboard w-100">
          <div className="row w-100">
            <div className="col-lg-12">
              
            </div>
          </div>
          <Newmemberstable data={allnewusers} username={username}/>
        </section>
        {/* <div className="modal fade" id="basicModal" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Create Events</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <p id="eventAlert" style={{ color: "red", fontSize: "18px", display: "none" }}>Hello</p>

              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary" id="saveEvent">Save</button>
              </div>
            </div>
          </div>
        </div> */}
      </main>
    </div>
  );
};

export default SuperViewallusers;