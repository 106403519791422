import React from 'react';
import 'bootstrap/dist/css/bootstrap.css'; // Import Bootstrap CSS
import 'bootstrap/dist/js/bootstrap.bundle'; // Import Bootstrap JavaScript
import logo from '../images/logo.png'
import { Link } from 'react-router-dom';
const Navbar = () => {
  return (
    <div>
        {/* <nav className="navbar navbar-expand-lg navbar-light bg-white shadow">
            <div className="container">
               <div className='topHeader text-right'>
                    <h2 >WE ARE ASSOCIATION OF BLACK PSYCHIATRISTS</h2>
               </div>
            </div>
        </nav> */}
        <nav className="navbar navbar-expand-lg navbar-light bg-white shadow">
            <div className="container">
                <a className="navbar-brand" href="#"><img src={logo} width='200px'/></a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                <ul className="navbar-nav">
                    <li className="nav-item">
                         <Link to="/">Home</Link>  
                    </li>
                    <li className="nav-item">
                         <Link to="/event">Event</Link>  
                    </li>
                    <li className="nav-item">
                        <Link to="/workgroup">Work-Group</Link>  
                    </li>
                    <li className="nav-item">
                        <Link to="/contact">Contact</Link>  
                    </li>
                    <li className="nav-item">
                        <Link to="/login">Log In</Link>  
                    </li>
                    <li className="nav-item">
                        <Link style={{color:'#000',background:'#F4B907',padding:'6px',borderRadius:'7px'}} to="/signup">Sign Up</Link> 
                    </li>
                </ul>
                </div>
            </div>
        </nav>
    </div>
  );
};

export default Navbar;
