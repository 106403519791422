import React from 'react';
import { Carousel } from 'react-bootstrap';
import Slide2 from './images/slide1.jpg';

const PastEventSlider = () => {
  const newsLetterButton = {
    color:'#000',
    border:'none',
    marginTop:'10px',
    background:'#F4B907',
    padding:'4px',
    width:'70px'
  };
  return (
    <div>
      <div className='w-100'>
        <Carousel>
          <Carousel.Item>
            <img
              className="w-100 slider-image"
              src={Slide2}
              alt="Slide 2"
            />
            <Carousel.Caption>
              {/* <h3>Slide 1</h3>
              <p>Slide 1 description</p> */}
            </Carousel.Caption>
          </Carousel.Item>
          
          <Carousel.Item>
            <img
              className="w-100 slider-image"
              src={Slide2}
              alt="Slide 2"
            />
            <Carousel.Caption>
              {/* <h3>Slide 3</h3>
              <p>Slide 3 description</p> */}
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
};

export default PastEventSlider;
