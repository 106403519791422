import React from 'react';
import '../../../adminstyle/assets/vendor/bootstrap/css/bootstrap.min.css';
import '../../../adminstyle/assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../../../adminstyle/assets/vendor/boxicons/css/boxicons.min.css';
import '../../../adminstyle/assets/vendor/quill/quill.snow.css';
import '../../../adminstyle/assets/vendor/quill/quill.bubble.css';
import '../../../adminstyle/assets/vendor/remixicon/remixicon.css';
import '../../../adminstyle/assets/vendor/simple-datatables/style.css';
import '../../../adminstyle/assets/css/style.css';
import logo from '../../images/logo.png';
import { useParams } from 'react-router-dom';
import { useEffect,useState } from "react";
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";

const AdminSidebar = () => {
  const [logoutError, setLogoutError] = useState(null);
  const [sidebarHidden, setSidebarHidden] = useState(false);
  const { username } = useParams();
  const navigate = useNavigate();
  const contactform = {
    margin: '0 auto',
    width: '60%'
  };
  const logout = ()=>{
    localStorage.removeItem('authTokenAdmin')
    navigate('/superadminlogin')
  }
  useEffect(() => {
    // Use jQuery to attach a click event handler to the button
    $('.toggle-sidebar-btn').on('click', function () {
      if (sidebarHidden) {
        $('.sidebar').css('left', '0');
        $('#main').css('margin-left', '300px');
      } else {
        $('.sidebar').css('left', '-300px');
        $('#main').css('margin-left', '0');
        
      }
      // Toggle the sidebar state
      setSidebarHidden(!sidebarHidden);
    });

    // Make sure to return a cleanup function
    return () => {
      // Clean up any resources or event listeners if needed
      $('.toggle-sidebar-btn').off('click');
    };
  }, [sidebarHidden]);
  // const handleLogout = async (event) => {
  //   event.preventDefault()
  //   try {
  //     const response = await fetch('/logout', {
  //       method: 'GET',
  //       credentials: 'include', // Send cookies with the request
  //     });

  //     if (response.ok) {
  //       // Logout successful
  //       navigate(`/memberlogin`) // Redirect to login page
  //     } else {
  //       // Handle error
  //       setLogoutError('Logout failed');
  //     }
  //   } catch (error) {
  //     console.error('Error during logout:', error);
  //     setLogoutError('Logout failed');
  //   }
  // };
  return (
    <div>
      <aside id="sidebar" className="sidebar" style={{display:''}}>
        <ul className="sidebar-nav" id="sidebar-nav">
          <li className="nav-item">
              <Link className="nav-link" to={`/superadmindashboard/${username}`}>
              <i className="bi bi-grid"></i><span>Dashboard</span>
              </Link>
          </li>
          <li className="nav-item">
            <a className="nav-link collapsed" data-bs-target="#charts-nav" data-bs-toggle="collapse" href="#">
              <i className="bi bi-layout-text-window-reverse"></i><span>Administration</span>
              <i className="bi bi-chevron-down ms-auto"></i>
            </a>
            <ul id="charts-nav" className="nav-content collapse" data-bs-parent="#sidebar-nav">
              <li>
                <Link  to={`/superadmindashboard/${username}/viewalluser/`}>
                <i className="bi bi-circle"></i><span>Users</span>
                </Link>
              </li>
              <li>
                <Link  to={`https://analytics.google.com/analytics/web/#/p410472616/reports/intelligenthome`}>
                <i className="bi bi-circle"></i><span>Site Viewers</span>
                </Link>
              </li>
            </ul>
            <ul id="charts-nav" className="nav-content collapse" data-bs-parent="#sidebar-nav">
              <li>
                <Link  to={`/superadmindashboard/${username}/uploadevent`}>
                <i className="bi bi-upload"></i><span>Upload Event</span>
                </Link>
                
              </li>
              <li>
                <Link  to={`/superadmindashboard/${username}/allevents`}>
                <i className="bi bi-calendar4-event"></i><span>Events</span>
                </Link>
              </li>
              <li>
             
                <Link  to={`/superadmindashboard/${username}/registeredevents`}>
                <i className="bi bi-calendar4-event"></i><span>Registered Events</span>
                </Link>
              </li>
            </ul>
          </li>
          <li className="nav-item">
            <a className="nav-link collapsed" data-bs-target="#cons-nav" data-bs-toggle="collapse" href="#">
            <i className="bi bi-person-workspace"></i><span>Workgroups</span>
            <i className="bi bi-chevron-down ms-auto"></i>
              
            </a>
            <ul id="cons-nav" className="nav-content collapse" data-bs-parent="#sidebar-nav">
              <li>
                <Link  to={`/superadmindashboard/forum/${username}/CASC Tutorial`}>
                <span>CASC Tutorial</span>
                </Link>
              </li>
              <li>
                <Link  to={`/superadmindashboard/forum/${username}/Mentorship & Welfare`}>
                <span>Mentorship & Welfare</span>
                </Link>
              </li>
              <li>
                <Link  to={`/superadmindashboard/forum/${username}/Editorial`}>
                <span>Editorial</span>
                </Link>
              </li>
              <li>
                <Link  to={`/superadmindashboard/forum/${username}/Research`}>
                <span>Research</span>
                </Link>
              </li>
              <li>
                <Link  to={`/superadmindashboard/forum/${username}/Arts & Culture`}>
                <span>Arts & Culture</span>
                </Link>
              </li>
        
       
            </ul>
          </li>
          {/* <li className="nav-item">
            <a className="nav-link collapsed" href="users-profile.html">
              <i className="bi bi-person"></i>
              <span>Profile</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link collapsed" href="pages-faq.html">
              <i className="bi bi-question-circle"></i>
              <span>F.A.Q</span>
            </a>
          </li> */}
          <li className="nav-item">
            {/* <a className="nav-link collapsed" href="pages-contact.html">
              <i className="bi bi-envelope"></i>
              <span>Contact</span>
            </a> */}
            <Link onClick={logout} className="nav-link"><i className="bi bi-box-arrow-right"></i> Logout</Link>
          </li>
        </ul>
      </aside>
    </div>
  );
};

export default AdminSidebar;
