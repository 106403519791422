import React from 'react';
import Navbar from '../partials/navbar';
import Footer from '../partials/footer';
import { Link } from "react-router-dom";
import { useEffect,useState } from 'react';
import ReactGA from 'react-ga';
const Constitution = () => {
  const contactform={
    margin:'0 auto',
    width:'60%'
  }
  useEffect(() => {
    // Track page view for the home page
    ReactGA.pageview(window.location.pathname);
  }, []);
  return (
    <div className="">
       <header>
        <Navbar/>
      </header>
      <div className="container w-50 mt-5 constitution" style={{background:'#F6F6F6;'}}>
        <h2 style={{fontWeight:'bolder'}}>Constitution</h2>
        <br/>
        <div className='constitution'>
            <p>Association of Black Psychiatrist (ABP) United Kingdom
                Herewith is the Constitution for Association of Black Psychiatrists – United Kingdom adopted October 2020 and informed by the Bye-laws which are updated statements and subject to ratification by the membership during Executive Committee Meetings.
                <br/>
                <br/>
                1.	The name of the Association is Association of Black Psychiatrists – United Kingdom (ABP-UK).
                <br/>

                2.	The Association is established to meet the following goals/objectives: 
                (a)	To support the professional development of Black Psychiatrists through education, training, mentoring and networking.
                (b)	To harness the diverse ideas, experiences and perspective to promote innovation and excellence in the delivery of high-quality care for patients with mental illness.
                <br/>
                3.	In furtherance of its objectives, the Association will
                (a)	Organize conferences, training, webinars and meetings.
                <br/> 
                (b)	Establish links with a range of related and relevant organizations including the Royal College of Psychiatrists.
                <br/> 
                (c)	Recognise  initiatives and work of psychiatrists and mental health professionals towards the cause of mental health problems amongst people who have Black African and Caribbean descent.
                <br/>
                (d)	Promote relevant Continuing Professional Development programmes and Exchange programmes with related/ “sister” organisations or any other Associations approved by the Executive committe.
                <br/>
                (e)	Publish information / provide regular updates to keep the membership informed of its activities.
                <br/>
                4.    The Executive Committee (the Trustees) will consist of the following officers:
                (a)	President who will provide vision, direction and thrust for the Association in keeping with the objects of the Association.  The tenure of the President will be for three years.  He/She will not be eligible for re-election. The President will however be eligible to become  an ex-officio member of the Executive Committee for three years after completing his/her tenure.
                (b)	Vice-Chairman/Vice President who will assist the President and act in his/her absence as and when necessary.  The tenure of the Vice-Chairman/Vice-President will be for three years and he/she will not be eligible for re-election.  
                (c)	General Secretary who will keep the membership list, administer arrangements for the relevant meetings including the general body and executive committee meetings, take meeting minutes and arrange election of officers as warranted.  He/she will co-ordinate the Annual report with the help of the membership at large and the Executive Committee in particular. The tenure of this post will be for three years, subject to extension at the AGM for up to four years maximum.
                (d)	Financial Secretary who will be responsible for preparing a financial report which will be a section of the annual report.  He/She will act in the absence of the General secretary when required. The tenure of this post will be for three years. 
                (e)	Treasurer who will manage the financial accounts of the Association, help in budgeting and maintaining the Association’s accounts.  The tenure of this post will be for three years subject to extension at AGM for up to four years maximum.
                (f)	Public Relations Officer who will deal with the matters concerning the Organization’s wider relationship with relevant stakeholders / organisations, media and the public in consultation with the President.  The tenure of this post will be for three years.
                (g)	Welfare Secretary who will lead in setting up support structures for members. The tenure of this post will be for three years.
                (h)	International Link Officer who will link person with diaspora organisations in the African continent. The tenure of this post will be for three years.
                (i)	The Committee will be responsible for general organizational matters, policy decisions and operational aspects of the Association.  It will have power to co-opt up to four members into the Executive Committee for a maximum of three years each.
                (j)	Any collective majority decision on any matter duly made at a quorate meeting of the executive committee supersedes the individual decision(s) of any of the Executive Committee officers listed above.
                <br/>
                5.	Membership:  The Membership will consist of the following:
                (a)	The General Member: Any black psychiatrist on application and payment of membership fees will become a General Member. 
                (b)	The Affiliate Member: On discretion of the Executive Committee, any psychiatrist or mental health professional who supports the ABP–UK objectives will be accepted as an Affiliate member of the Association. He/She will be subject to membership fees and will not have voting rights for election purposes.
                (c)	The Honorary Life Member: Any outstanding doctor, health professional  or member of the public whose contribution in furthering the Association’s objectives is of exceptional eminence will be invited to become an Honorary Life Member on election by the Executive Committee.  He/She will not be subject to membership fees and will not have voting rights for election purposes.
                (d)	Non-Executive Members: The Executive Committee may invite any mental health professional or member of the public with affinity to the Association’s objects to join the committee as a Non- executive member of the committee. The numbers to be limited to maximum three at a time, appointed for three years, extendable to up to four years.
                <br/>
                6.	Membership Fees:  The following fees will apply:

                ▪	Consultants 	      £100 

                ▪	SAS doctors              £70

                ▪	Trainees                    £50

                ▪	Retired members    £50

                ▪	Affiliate members    £50
                ▪	FYI/FY2.                      £20

                ▪	Medical students    Free 

                The Membership year will begin from 22 October every year.  Fees paid any time during the year will become due on 22 October of the year
                <br/>
                7.	Executive Committee Meetings: The Executive Committee will meet at least four times a year. Minutes of the meetings will be disseminated to all the members of the executive committee and co-opted members. There shall be a quorum comprising of at least three members of the Executive Committee at these meetings. The president shall act as Chairman at meetings of the Executive Committee. If the president is absent from any meeting, another member of the Executive Committee will be chosen to chair the meeting in the President’s stead.
                <br/>
                8.	General Meetings:  An Annual General Meeting of all the membership will be called once a year in October.  These meetings will be presented with reports from the President, General Secretary and the financial secretary.  When required, an election of officers will also take place and any other business conducted. Virtual conferences and AGM may be agreed if indicated. The meeting shall include scientific presentation, research and serve as a venue for social interaction and professional networking. ABP-UK “Terms of Reference” shall be reviewed annually at the AGM and amendments to the Terms of Reference shall require the agreements of at least 20% of registered members.
                <br/>
                9.	Accounts: Annual financial report will be presented by the Financial secretary at the Annual General Meeting and ratified by the AGM.  The accounts will be  also be audited.
                <br/>
                10.	Election shall be organised by nominated electoral committee. All members shall have the right to vote and the election will be held at the AGM of the Association. The elections shall be designated on the official agenda of the annual general meeting (AGM). A simple majority of the votes cast is necessary to elect. No candidate shall be elected to any office for more than two consecutive terms.
                <br/>
                11.	Exclusion of Membership of Executive committee. A member of the Executive shall cease to hold office following ratification at the annual general meeting if he or she;
                (a)	Is incapable by reason of illness or injury of managing and administering his or her own affairs.
                (b)	Is absent without permission of the Executive Committee from all their meetings held within a period of six months and the Executive Committee resolve that his or her office be vacated.
                (c)	Notifies the Executive Committee of wish to resign (but only if at least three members of the Executive Committee will remain in office when the notice of resignation is to take effect).
                (d)	Gives three months’ notice of intention to resign the post.
                <br/>
                12.	As part of its organisational framework, ABP-UK will operate committees (or workgroups) to support the attainment of its aims and objectives. See “Organisational Framework for the Association of Black Psychiatrists (ABP) UK” for the objectives of the committees. ABP-UK committees include;
                (a)	Education committee 
                (b)	Leadership and Management committee 
                (c)	Mentoring and Coaching Committee
                (d)	Research Committee 
                (e)	Trainees Support Committee
                (f)	Welfare committee 
                (g)	International Liaison Committee
                (h)	Public Relations Committee 
                <br/>
                13.	Specialist Advisers may attend Executive Committee meetings as appropriate to assist its deliberation and provide advice.
                <br/>
                14.	ABP-UK constitution may be altered by a resolution passed by not less than two thirds of the members voting at a general meeting. The notice of the general meeting must include notice of the resolution setting out the terms of the alteration proposed.
                <br/>
                15.	This constitution was adopted on October 2020 shall be effective until the first Annual General Meeting.
                <br/>
                <br/>

                Names / Signature(s) / Date
                </p>
        </div>
        <button type="submit" className="btn btn-primary w-100 rounded-0" style={{background:'#7FA037',color:'#ffffff',border:'none',borderRadius:'none'}}><Link to='/signup' style={{color:'#ffffff'}}>Become a Member</Link></button>
      </div>
      <Footer/>
    </div>
  );
};

export default Constitution;
