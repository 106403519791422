import React from 'react';
import '../../adminstyle/assets/vendor/bootstrap/css/bootstrap.min.css';
import '../../adminstyle/assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../../adminstyle/assets/vendor/boxicons/css/boxicons.min.css';
import '../../adminstyle/assets/vendor/quill/quill.snow.css';
import '../../adminstyle/assets/vendor/quill/quill.bubble.css';
import '../../adminstyle/assets/vendor/remixicon/remixicon.css';
import '../../adminstyle/assets/vendor/simple-datatables/style.css';
import '../../adminstyle/assets/css/style.css';
import Clientnav from './clientpartials/clientnav';
import Clientsidebar from './clientpartials/clientsidebar';
import {useState, useEffect } from 'react';
import { Link,useLocation } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import ReactGA from "react-ga";

const Notifications = () => {
  const { username, userid } = useParams();
  const [message, setmessage] = useState("");

  const [forummessage, setforummessage] = useState([]);
  const [displaynone, setdisplaynone] = useState("none");
  const [isapproved, setisapproved] = useState('');
  const [avatar, setavatar] = useState('');
  const [apiResponse, setapiResponse] = useState([]);
  const [unreadnotification, setunreadnotification] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
    useEffect(()=>{
      const readnotification = async () => {
        const jsonData=({
          readnotification:1
        })
        try {
          const response = await axios.post(`${backendUrl}/readnotification`,jsonData,{
            headers: {
              'Content-Type': 'application/json',
            },
          }); // Replace with your API endpoint
          const data = response.data;
          // setreadnotification(data.message);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      const unreadnotification = async () => {
        const jsonData=({
          unreadnotification:1
        })
        try {
          const response = await axios.post(`${backendUrl}/unreadnotification`,jsonData,{
            headers: {
              'Content-Type': 'application/json',
            },
          }); // Replace with your API endpoint
          const data = response.data;
          setunreadnotification(data.message);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      
  
     
      
    const fetchMessages = async () => {
      try {
        const response = await axios.post(
          `${backendUrl}/adminfetchGeneralMessages`,
          {
            generalmessage: 1,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
  
        const data = response.data;
  
        if (Array.isArray(data.message)) {
          setforummessage(data.message);
        } else {
          // Handle the case when there are no messages
          setforummessage([]); // Set an empty array to clear previous messages
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    // Set up an interval to fetch messages periodically
    const intervalId = setInterval(() => {
      fetchMessages();
      readnotification()
      unreadnotification()
    }, 1000);
  
    // Don't forget to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
        
    },[])
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmitMessage(e);
    }
  };
    const handleSubmitMessage = async ()=>{
        
        if(message===""){
            
        }
        else{
            const jsonData={
                message:message,
                sender:'Admin'
            }
            try {
                const response = await axios.post(`${backendUrl}/sendgeneralnotice`,jsonData,{
                headers: {
                    'Content-Type': 'application/json',
                },
                }); // Replace with your API endpoint
                const data = response.data;
                // console.log(data)
                if (data && data.message) {
                // Update the state with the message or messages you want to render.
                setapiResponse(data.message); // Assuming data.message is a string or array of React elements.
                setdisplaynone('block');
                setmessage("")
                setTimeout(() => {
                    setdisplaynone('none');
                }, 5000);
                } else {
                console.error('Response data does not contain a message field:', data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    }

  var chatBlock = {
    maxHeight: "280px",
    paddingBottom: "500px",
    background: "#EBF9FF",
    border: "#CDF1FF solid 1px",
    overflow: "auto",
    // background: '#FCFCFC'
  };
  var chatDropsUser = {
    padding: "5px",
    maxWidth: "800px",
    borderRadius: "6px",
    background: "#FFFFFF",
    border: "none",
    color: "#3D3C3E",
    textAlign: "left",
    wordWrap: "break-word",
  };
  var chatDropsUserAdmin = {
    padding: "5px",
    maxWidth: "500px",
    borderRadius: "6px",
    background: "#7FA037",
    border: "none",
    color: "#ffffff",
    textAlign: "left",
    wordWrap: "break-word",
  };

  return (
    <div>
      <Clientnav username={username} unreadnotification={unreadnotification}/>
      <Clientsidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Notifications</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active">Notifications</li>
            </ol>
          </nav>
        </div>

        <section className="section dashboard w-100">
        
          <div className="row chatBlock" style={chatBlock}>
            {forummessage.length === 0 ? (
              <div
                className="text-center d-flex justify-content-center align-items-center"
                style={{ marginTop: "150px" }}
              >
                <h3>No Notifications Sent</h3>
              </div>
            ) : (
              forummessage.map((element, index) => (
                <div key={index}>
                  {element.length === 0 ? (
                    <div
                      className="container d-flex justify-content-center align-items-center"
                      style={{ minHeight: "80vh" }}
                    >
                      <div className="text-center">
                        <h3>No Notifications Sent</h3>
                      </div>
                    </div>
                  ) : (<div
                    className="chatDropsUserCover"
                    style={{ padding: "20px" }}
                  >
                    <div className="row">
                      <div className="col-md-1">
                        <img
                          src={`${backendUrl}`+`/images/adminavatar.jpeg`}
                          style={{
                            borderRadius: "50%",
                            width: "50px",
                            height: "50px",
                          }}
                        />
                      </div>
                      <div className="col-md-11 text-left">
                        <h6>
                          {'Blackpsych'} ,{" "}
                          <span style={{ fontSize: "10px" }}>
                            {element.time} {element.date}
                          </span>
                        </h6>
                          <p style={chatDropsUser}>
                            {String(element.message)}
                          </p>
                      </div>
                    </div>
                  </div>)}
                </div>
              ))
            )}
          </div>
          {/* <div
            className="row attachment"
            style={{
              border: "1px solid #7FA037",
              maxHeight: "40px",
              paddingBottom: "40px",
            }}
          >
            <div className="col-md-11">
              <input
                className="form-control col-md-12 rounded-0 border-0"
                type={"text"}
                placeholder={"Type your message here..."}
                style={{
                  background: "none",
                  wordWrap: "break-word",
                  width: "100%",
                  outline: isFocused ? "none" : "",
                }}
                value={message}
                onChange={(e) => {
                  setmessage(e.target.value);
                }}
                onKeyDown={handleKeyPress}
              />
            </div>
            <div className="col-md-1 text-center" style={{width:'30px'}}>
              <button
               onClick={handleSubmitMessage}
                className="rounded-0"
                style={{
                  background: "none",
                  border: "none",
                  color: "#7FA037",
                  fontSize: "20px",
                  padding: "5px",
                  textAlign:'center',
                  borderLeft: "#7FA037 solid 1px",
                }}
              >
                <i className="bi bi-send"></i>
              </button>
            </div>
          </div> */}
        </section>
        {/* <div className="modal fade" id="basicModal" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Create Events</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <p id="eventAlert" style={{ color: "red", fontSize: "18px", display: "none" }}>Hello</p>

              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary" id="saveEvent">Save</button>
              </div>
            </div>
          </div>
        </div> */}
      </main>
    </div>
  );
};

export default Notifications;
