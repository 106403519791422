import React from 'react';
import Navbar from '../partials/navbar';
import Footer from '../partials/footer';
import { Link } from "react-router-dom";
import { useEffect,useState } from 'react';
import ReactGA from 'react-ga';
import { color } from 'echarts';
const Termsofservice = () => {
  const contactform={
    margin:'0 auto',
    width:'60%'
  }
  useEffect(() => {
    // Track page view for the home page
    ReactGA.pageview(window.location.pathname);
  }, []);
  return (
    <div className="">
       <header>
        <Navbar/>
      </header>
      <div className="container w-50 mt-5 constitution" style={{background:'#F6F6F6;'}}>
        <h2 style={{fontWeight:'bolder'}}>Terms Of Service</h2>
        <br/>
        <div className='constitution'>
        <p>
        Welcome to the Association of Black Psychiatrists United Kingdom (ABP-UK). By using our website and services, you agree to comply with and be bound by the following terms and conditions.
        </p>
        <h3>1. Acceptance of Terms</h3>
        <p>
            By using our services, you agree to these terms. If you do not agree with any part of these terms, please do not use our services.
        </p>
        <h3>2. Use of Services</h3>
        <p>
            You agree to use our services only for their intended purposes and not for any unlawful activities.
        </p>
        <h3>3. User Conduct</h3>
        <p>
            You are responsible for your conduct while using our services. Any violation of our terms may result in the termination of your account.
        </p>
        <p>
            {/* Add your organization's specific terms here */}
        </p>
        </div>
        <button type="submit" className="btn btn-primary w-100 rounded-0" style={{background:'#7FA037',color:'#ffffff',border:'none',borderRadius:'none'}}><Link to='/signup' style={{color:'#ffffff'}}>Become a Member</Link></button>
      </div>
      <Footer/>
    </div>
  );
};

export default Termsofservice;
