import React from 'react';
import Navbar from '../partials/navbar';
import Footer from '../partials/footer';
import { Link } from "react-router-dom";
import { useEffect,useState } from 'react';
import ReactGA from 'react-ga';
import { color } from 'echarts';
const Privacy = () => {
  const contactform={
    margin:'0 auto',
    width:'60%'
  }
  useEffect(() => {
    // Track page view for the home page
    ReactGA.pageview(window.location.pathname);
  }, []);
  return (
    <div className="">
       <header>
        <Navbar/>
      </header>
      <div className="container w-50 mt-5 constitution" style={{background:'#F6F6F6;'}}>
        <h2 style={{fontWeight:'bolder'}}>Privacy Policy</h2>
        <br/>
        <div className='constitution'>
        <p>
        At ABP-UK, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and safeguard your personal information.
      </p>
      <h3>1. Information Collection</h3>
      <p>
        We collect information you provide to us, such as your name, email, and other contact details, to provide our services.
      </p>
      <h3>2. Information Usage</h3>
      <p>
        We use your information for internal purposes and to improve our services. We do not sell your information to third parties.
      </p>
      <h3>3. Data Security</h3>
      <p>
        We take reasonable measures to protect your data, but no data transmission over the internet is completely secure.
      </p>
      <p>
        {/* Add your organization's specific privacy policy details here */}
      </p>
        </div>
        <button type="submit" className="btn btn-primary w-100 rounded-0" style={{background:'#7FA037',color:'#ffffff',border:'none',borderRadius:'none'}}><Link to='/signup' style={{color:'#ffffff'}}>Become a Member</Link></button>
      </div>
      <Footer/>
    </div>
  );
};

export default Privacy;
