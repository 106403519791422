import React, { useState,useEffect } from 'react';
import Navbar from '../partials/navbar';
import Footer from '../partials/footer';
import { Link } from "react-router-dom";
import logo from '../images/logo.png';
import axios from 'axios';
import ReactGA from 'react-ga';
import { useNavigate } from 'react-router-dom';

const Contact = () => {
  const [name, setname] = useState('');
  const [organisationname, setorganisationname] = useState('');
  const [phonenumber, setphonenumber] = useState('');
  const [message, setmessage] = useState('');
  const [email, setemail] = useState('');
  const [apiResponse, setapiResponse] = useState([]);
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const contactform = {
    margin: '0 auto',
    width: '60%'
  };
  useEffect(() => {
    // Track page view for the home page
    ReactGA.pageview(window.location.pathname);
  }, []);
  const handleContactSubmit = async (event) => {
    event.preventDefault();

    try {
      const jsonData = {
        name: name,
        phonenumber: phonenumber,
        organisationname: organisationname,
        message: message,
        email: email
      };
      const response = await axios.post(`${backendUrl}/submitcontact`, jsonData, {
        headers: {
          'Content-Type': 'application/json', // Correct content type
        },
      });
      if(response.data === "Thanks for contacting us. We will get back to you soon"){
        const message = 'Thanks for contacting us. We will get back to you soon'
        navigate(`/mail/${message}`)
      }
      else{
        setapiResponse(response.data)
      }
      console.log(response.data)

    } catch (error) {
      console.error('Error submitting contact:', error);
      // Handle error, maybe show an error message to the user
    }
  };

  return (
    <div className="">
      <header>
        <Navbar />
      </header>
      <div className="container w-100 mt-5">
        <form className='contactForm' style={contactform}>
          <div className='text-center'>
            <img className='mt-4 mb-5' src={logo} alt="Logo" width='200' />
            <h2 className='text-left' style={{ fontWeight: 'bolder', textAlign: 'left' }}>Hello!</h2>
          </div>
          <br />
          <p style={{ color: apiResponse !== 'You are Logged In' ? 'red' : '' }}>
          {apiResponse}
        </p>
          <div className="row mb-3">
            <div className="col">
              <input
                type="text"
                className="form-control border-top-0 border-start-0 border-end-0 rounded-0"
                style={{ borderColor: 'black' }}
                id="name"
                placeholder="e.g Adaobi Mensah"
                value={name}
                onChange={(e) => setname(e.target.value)}
              />
              <label htmlFor="name" className="form-label">Name</label>
            </div>
            <div className="col">
              <input
                type="text"
                className="form-control border-top-0 border-start-0 border-end-0 rounded-0"
                style={{ borderColor: 'black' }}
                id="organisationname"
                placeholder="e.g Your Company Inc"
                value={organisationname}
                onChange={(e) => setorganisationname(e.target.value)}
              />
              <label htmlFor="organisationname" className="form-label">Organization Name</label>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <input
                type="text"
                className="form-control border-top-0 border-start-0 border-end-0 rounded-0"
                style={{ borderColor: 'black' }}
                id="phonenumber"
                placeholder="e.g +44 903389403"
                value={phonenumber}
                onChange={(e) => setphonenumber(e.target.value)}
              />
              <label htmlFor="phonenumber" className="form-label">Phone Number</label>
            </div>
            <div className="col">
              <input
                type="email"
                className="form-control border-top-0 border-start-0 border-end-0 rounded-0"
                style={{ borderColor: 'black' }}
                id="email"
                placeholder="e.g email@company.com"
                value={email}
                onChange={(e) => setemail(e.target.value)}
              />
              <label htmlFor="email" className="form-label">Your work email</label>
            </div>
          </div>
          <div className="mb-3">
            <textarea
              className="form-control border-top-0 border-start-0 border-end-0 rounded-0"
              style={{ borderColor: 'black' }}
              id="message"
              placeholder="What will you like to tell us?"
              value={message}
              onChange={(e) => setmessage(e.target.value)}
            />
            <label htmlFor="message" className="form-label">Your Message</label>
          </div>
          <button
            type="submit"
            className="btn btn-primary w-100 rounded-0"
            onClick={handleContactSubmit}
            style={{ background: '#7FA037', border: 'none', borderRadius: 'none' }}
          >
            Send
          </button>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
